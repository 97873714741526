import { actionCompletedToast } from 'components/ui/toast';
import { useEffect, useState } from 'react';
import secureApiFetch from 'services/api';

function ProjectCategoryForm({ setCategories }) {
    const [addname, setAddName] = useState('')
    const [adddescription, setAddDescription] = useState('')
    const [product, setProduct] = useState('')
    const [type, setType] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        const userData = {
            name: addname,
            description: adddescription,
            product_code: product,
            status: type
        }
        secureApiFetch(`/project/categories`, {
            method: 'POST',
            body: JSON.stringify(userData)
        }).then(() => {
            return secureApiFetch(`/project/categories?all=true`)
        }).then((res) => {
            setAddName('')
            setAddDescription('')
            setProduct('')
            setType('')
            setCategories(res)
            if (res.status === 200) {
                actionCompletedToast(`The Project Category has been created.`);
            }
        }).catch((error) => {
            console.log('Error Deleting Categories', error)
        })
    }

    useEffect(() => {
        const fetchUpdatedData = async () => {
            try {
                const updatedCategories = await secureApiFetch('/project/categories?all=true');
                setCategories(updatedCategories);

            } catch (error) {
                console.error('Error fetching updated data:', error);
            }
        };

        fetchUpdatedData();

    }, [setCategories]);
    const [activeAccordion, setActiveAccordion] = useState(0);

    const handleAccordionClick = (accordionIndex) => {
        setActiveAccordion(accordionIndex === activeAccordion ? 0 : accordionIndex);
    };
    return (
        <div id="accordion-collapse" className=' mb-2 rounded-xl' data-accordion="collapse">
            <h2 id="accordion-collapse-heading-1">
                <button
                    type="button"
                    className={`flex items-center justify-between w-full text-lg py-2 px-5 font-medium text-gray-500 border border-gray-200 rounded-t-xl hover:bg-gray-100 `}
                    data-accordion-target="#accordion-collapse-body-1"
                    aria-expanded={activeAccordion === 1}
                    aria-controls="accordion-collapse-body-1"
                    onClick={() => handleAccordionClick(1)}
                >
                    <span className="font-medium text-gray-700 text-base">Add Project Category</span>
                    <svg data-accordion-icon className={`w-3 h-3 rotate-180 shrink-0`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                    </svg>
                </button>
            </h2>
            <div
                id="accordion-collapse-body-1"
                className={`p-2 border border-t-0 border-gray-200  ${activeAccordion === 1 ? '' : 'hidden'}`}
                aria-labelledby="accordion-collapse-heading-1"
            >
                <form>
                    <label>
                        <span className="font-medium text-xs">Name</span>
                        <input type="text" id="name" className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' placeholder="Add Name" value={addname} onChange={(e) => setAddName(e.target.value)} required />
                    </label>

                    <label>
                        <span className="font-medium text-xs">Description</span>
                        <input type="text" id="description" className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' placeholder="Add Description" value={adddescription} onChange={(e) => setAddDescription(e.target.value)} />
                    </label>

                    <label>
                        <span className="font-medium text-xs">Product Code</span>
                        <input type="text" id="product_code" className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' placeholder="Add Product Code" value={product} onChange={(e) => setProduct(e.target.value)} />
                    </label>

                    <label>
                        <span className="font-medium text-xs">Select Type</span>
                        <select id="countries" value={type} onChange={(e) => setType(e.target.value)} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'>
                            <option value='false'>False</option>
                            <option value='true'>True</option>
                        </select>
                    </label>

                    <button onClick={handleSubmit} disabled={!addname} className='bg-[#3A3D89] px-4 w-28 py-2 rounded text-white text-sm'>
                        Add
                    </button>
                </form>
            </div>
        </div>

    )
}

export default ProjectCategoryForm