import { Link, useNavigate, useParams } from "react-router-dom";
import { ButtonGroup, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import PageTitle from "components/logic/PageTitle";
import RestrictedComponent from "components/logic/RestrictedComponent";
import BooleanText from "components/ui/BooleanText";
import EmptyField from "components/ui/EmptyField";
import TimestampsSection from "components/ui/TimestampsSection";

import AuditLogsTable from '../auditlog/AuditLogsTable';
import UserRoleBadge from '../badges/UserRoleBadge';
import useDelete from "../../hooks/useDelete";
import UserAvatar from '../badges/UserAvatar';
import useFetch from '../../hooks/useFetch';
import Breadcrumb from '../ui/Breadcrumb';
import Loading from '../ui/Loading';
import Title from '../ui/Title';
import OrganizationTable from "./table";

const UserProfile = () => {
    const navigate = useNavigate();

    const { userId } = useParams();
    const [user] = useFetch(`/users/${userId}`)
    const [auditLog] = useFetch(`/users/${userId}/activity`)
    const deleteUser = useDelete('/users/');

    const onDeleteButtonClick = ev => {
        ev.preventDefault();

        deleteUser(userId).then(() => {
            navigate('/users');
        })
    }
    return (
        <div className="">
            <div className='heading'>
                <Breadcrumb>
                    <Link to="/users">Users</Link>
                    <span>User Details</span>
                </Breadcrumb>
                {user && (
                    <ButtonGroup>
                        <RestrictedComponent roles={['administrator', 'superuser', 'user']}>
                            <Link to={`/users/${user.id}/edit`}>
                                <button className='bg-[#3A3D89] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                                    Edit
                                </button>
                            </Link>
                            <button onClick={onDeleteButtonClick} className='bg-[#EB1F36] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                                <DeleteIcon fontSize='small' /> Delete
                            </button>

                        </RestrictedComponent>
                    </ButtonGroup>
                )}
            </div>
            {!user && (
                    <h1 className='text-gray-600  font-medium text-center w-full mt-16'>Restricted Access. Please check your permissions.</h1>
            )}
            {user && (
                <div>
                    <PageTitle value={`${user.full_name} user`} />

                    <Title type='User profile' title={user.full_name}
                        icon={<UserAvatar email={user.email} />} />
                    <Tabs varient={'enclosed'} isLazy>
                        <TabList>
                            <Tab><span className="font-medium text-xs">Details</span></Tab>
                            <Tab><span className="font-medium text-xs">Activity</span></Tab>
                            {/* <Tab><span className="font-medium text-xs">Clients</span></Tab> */}
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <div className="grid grid-two">
                                    <div>
                                        <h4> <ArticleOutlinedIcon className='text-[#3A3D89]' /> Properties</h4>
                                        <dl>
                                            <dt>Short bio</dt>
                                            <dd>{user.short_bio ? user.short_bio : <EmptyField />}</dd>

                                            <dt>Role</dt>
                                            <dd><UserRoleBadge role={user.role} /><br /></dd>

                                            <dt>Timezone</dt>
                                            <dd>{user.timezone}</dd>

                                            <dt>Active?</dt>
                                            <dd><BooleanText value={user.active} /></dd>

                                            <dt>2FA enabled?</dt>
                                            <dd><BooleanText value={user.mfa_enabled} /></dd>
                                        </dl>
                                    </div>

                                    <div>
                                        <TimestampsSection entity={user} />

                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <h4 className="font-medium text-gray-800">Activity (<Link to="/auditlog">view full audit log</Link>)</h4>
                                {auditLog ? <AuditLogsTable auditLog={auditLog} hideUserColumns="true" /> : <Loading />}
                            </TabPanel>
                            <TabPanel>
                                <h4 className="font-medium text-gray-800">
                                    <CorporateFareIcon className='text-[#3A3D89]' fontSize='small' /> Connected Clients
                                </h4>
                                <OrganizationTable />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </div>
            )}
        </div>
    )
}

export default UserProfile;
