import SearchIcon from '@mui/icons-material/Search';
import eventFilters from './EventFilters';
import { List, ListItem, Text } from "@chakra-ui/react";
import { useEffect, useRef, useState } from 'react';
import AuditUserFilter from './AuditUserFilter';

function AuditFilter({ onFilterChange }) {
    const [query, setQuery] = useState("");
    const [filteredOptions, setFilteredOptions] = useState([]);
    const dropdownRef = useRef(null);
    
    const handleFilterSearch = (ev) => {
        const { name, value } = ev.target;
        if (value.length >= 3 || value === "") {
            onFilterChange((prevFilters) => {
                const newFilters = { ...prevFilters };
                if (value === "") {
                    delete newFilters[name];
                } else {
                    newFilters[name] = value;
                }
                return newFilters;
            });
        }
    };


    const handleInputChange = (event) => {
        const value = event.target.value;
        setQuery(value);
        const filtered = eventFilters.filter((option) =>
            option.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredOptions(filtered);
    };

    const handleOptionSelect = (option) => {
        setQuery(option);
        onFilterChange((prevFilters) => {
            const newFilters = { ...prevFilters };
            if (option === '') {
                delete newFilters['event'];
            } else {
                newFilters['event'] = option;
            }
            return newFilters;
        });
        setFilteredOptions([]);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setFilteredOptions([]); 
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside); 
        };
    }, []);


    return (
        <div className="flex w-full space-x-3 px-3 pb-2 rounded-lg justify-end">
            <div className='relative min-w-52' ref={dropdownRef}>
            <input
                type="text"
                className="px-2 py-1 border rounded text-sm w-full"
                placeholder="Search Event"
                value={query}
                onChange={handleInputChange}
            />
            {filteredOptions.length > 0 && (
                <List
                    position="absolute"
                    top="40px"
                    zIndex="1000"
                    bg="white"
                    border="1px solid #e2e8f0"
                    width="100%"
                    borderRadius="md"
                    maxHeight="150px"
                    overflowY="auto"
                    boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
                >
                    {filteredOptions.map((option, index) => (
                        <ListItem
                            key={index}
                            padding="8px"
                            cursor="pointer"
                            borderBottom="1px solid #e2e8f0"
                            _hover={{ backgroundColor: "#edf2f7" }}
                            onClick={() => handleOptionSelect(option)}
                        >
                            <Text fontSize="sm" paddingLeft="4px">
                                {option}
                            </Text>
                        </ListItem>
                    ))}
                </List>
            )}
        </div>
            <div>
              <AuditUserFilter onFilterChange={onFilterChange} />
            </div>
            <div className="relative px-2 py-1 border rounded text-sm w-48">
                <input name="keyword" type="text" onChange={handleFilterSearch} placeholder="Search Keyword..."
                    className="pl-2 pr-1 border-none outline-none rounded text-sm" />
                <SearchIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
            </div>
            <div className="relative px-2 py-1 border rounded text-sm w-48">
                <input name="client_ip" type="number" onChange={handleFilterSearch} placeholder="Search Client Ip..."
                    className="pl-2 pr-1 border-none outline-none rounded text-sm" />
                <SearchIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
            </div>
        </div>
    );
}


export default AuditFilter