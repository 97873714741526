import AddIcon from '@mui/icons-material/Add';

const CreateButton = ({ onClick, children }) => {
    return (
        <button onClick={onClick} className='bg-[#3A3D89] pl-1 pr-2 py-1 rounded text-white space-x-1 flex items-center text-xs'>
            <AddIcon fontSize='small' />
            <span >
                {children || "Create"}
            </span>
        </button>
    )
}


export default CreateButton;
