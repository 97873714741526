import PageTitle from "components/logic/PageTitle";
import KeyboardShortcuts from "components/support/KeyboardShortcuts";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import Sidebar from "../sidebar";
import './Dashboard.scss'
import { actionCompletedToast, errorToast } from "components/ui/toast";

const DashboardLayout = () => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        const handleOnline = () => {
            actionCompletedToast("You are now online!");
        };
        const handleOffline = () => {
            errorToast("You are now offline. Please check your internet connection.");
        };
    
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
    
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    return (
        <>
            <PageTitle value="Dashboard" />

            <div className="flex">
                <main role="main" className={sidebarCollapsed ? 'collapsed' : ''}>
                    <Sidebar sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
                </main>
                <div style={{ display: 'flex', flexDirection: 'column', zIndex: '10', width: '100%', overflow: 'hidden', backgroundColor: '#e9e9ea' }}>
                    <div style={{ width: '100%' }}>
                        <Header sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
                    </div>
                    <div>
                        <div id='content' className="dashboard">
                            <Outlet />
                        </div>
                    </div>
                    {/* <footer className="bg-white mt-2 shadow text-center fixed bottom-0 right-0 left-[15.7rem]"> */}
                    <footer className="bg-white mt-2 shadow text-center">
                        <div className="layout-footer py-2 text-sm">
                            <span>Copyright © {currentYear}</span> <span className="font-semibold"> | </span>
                            <a href="https://www.securityium.com" className='underline text-[#eb1f36]' target="_blank" rel="noopener noreferrer">
                                Powered By Securityium™
                            </a>
                            <span className="font-semibold"> | </span>
                            <span>All rights reserved</span>
                        </div>
                    </footer>
                </div>
            </div>
            <KeyboardShortcuts />

        </>
    )
}

export default DashboardLayout;
