import ActiveProjectsWidget from "components/layout/dashboard/widgets/ActiveProjectsWidget";
import ApiHealthWidget from "components/layout/dashboard/widgets/ApiHealthWidget";
import MyTasksWidget from "components/layout/dashboard/widgets/MyTasksWidget";
import PopularCommandsWidget from "components/layout/dashboard/widgets/PopularCommandsWidget";
import RecentActivityWidget from "components/layout/dashboard/widgets/RecentActivityWidget";
import RecentDocumentsWidget from "components/layout/dashboard/widgets/RecentDocumentsWidget";
import RecentReport from "components/layout/dashboard/widgets/RecentReport";
import RecentVulnerabilitiesWidget from "components/layout/dashboard/widgets/RecentVulnerabilitiesWidget";
import UserActivityStatsWidget from "components/layout/dashboard/widgets/UserActivityStatsWidget";
import VulnerabilitiesByCategoryStatsWidget from "components/layout/dashboard/widgets/VulnerabilitiesByCategoryStatsWidget";
import VulnerabilitiesByRiskStatsWidget from "components/layout/dashboard/widgets/VulnerabilitiesByRiskStatsWidget";

const Widgets = {
    'vulnerability-by-risk-stats': {
        title: 'Vulnerability by risk',
        visible: true,
        component: <VulnerabilitiesByRiskStatsWidget />
    },
    'vulnerability-by-category-stats': {
        title: 'Vulnerability by category',
        visible: true,
        component: <VulnerabilitiesByCategoryStatsWidget />
    },
    'user-activity-stats': {
        title: 'User activity over time',
        visible: true,
        component: <UserActivityStatsWidget />,
        permissions: 'system.*'
    },
    'my-tasks': {
        title: 'My tasks',
        description: 'It shows a list of all open tasks assigned to you.',
        visible: true,
        component: <MyTasksWidget />
    },
    'active-projects': {
        title: 'Active projects',
        visible: true,
        component: <ActiveProjectsWidget />,
        description: 'It shows a list of all non-archived projects.'
    },
    'recent-report': {
        title: 'Recent Report',
        visible: true,
        component: <RecentReport />,
        description: 'It shows last 5 Reports.'
    },
    'recent-activity': {
        title: 'Recent activity',
        visible: true,
        component: <RecentActivityWidget />,
        permissions: 'system.*'
    },
    'recent-vulnerabilities': {
        title: 'Recent vulnerabilities',
        visible: true,
        component: <RecentVulnerabilitiesWidget />,
        description: 'It shows the most recently reported vulnerabilities.'
    },
    'Recent-commands': {
        title: 'Recent commands',
        visible: true,
        component: <PopularCommandsWidget />,
        permissions: 'commands.'
    },
    'recent-documents': {
        title: 'Recent documents',
        visible: true,
        component: <RecentDocumentsWidget />,
        description: 'It shows a list of the most recent documents.',
        permissions: 'documents.*'
    },
    'api-health': {
        title: 'API health',
        visible: true,
        component: <ApiHealthWidget />,
        description: 'It presents information about the health of the API server.',
        permissions: 'system.*'
    },
};

export default Widgets;
