import RelativeDateFormatter from "./RelativeDateFormatter";

const TimestampsSection = ({ entity }) => {
    return <>
        <h4 className='text-base font-medium mt-2'>Timestamps</h4>
        <hr />
        <dl>
            {(entity.engagement_start_date || entity.engagement_end_date) &&
                <dl>
                    {entity.engagement_start_date && <>
                        <dt>Engagement Start & End date</dt>
                        <dd>{entity.engagement_start_date || 'NA'} &nbsp; to &nbsp; {entity.engagement_end_date || 'NA'}</dd>
                    </>}

                </dl>
            }

            <dt>Created</dt>
            <dd><RelativeDateFormatter date={entity.insert_ts} /></dd>

            {entity.update_ts && <>
                <dt>Updated</dt>
                <dd><RelativeDateFormatter date={entity.update_ts} /></dd>
            </>}
        </dl>
    </>
}

export default TimestampsSection;
