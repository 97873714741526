import DocumentBadge from "components/documents/Badge";
import Loading from "components/ui/Loading";
import RelativeDateFormatter from "components/ui/RelativeDateFormatter";
import useFetch from "hooks/useFetch";
import DashboardWidget from "./Widget";

const RecentDocumentsWidget = () => {
    const [documents] = useFetch(`/documents?limit=5`)

    if (!documents) return <Loading />

    return <DashboardWidget title="Recent Documents (Last 5)">

        {documents.length === 0 ?
            <p>No documents to show.</p>
            :
            <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
                <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                    <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                        <tr>
                            <th scope="col" className="px-4 py-2">Title</th>
                            <th scope="col" className="px-4 py-2">Created</th>
                        </tr>
                    </thead>
                    <tbody>
                        {documents.map(doc => (
                            <tr key={doc.id} className="bg-white border-b border-gray-300 text-black font-medium">
                                <td scope="row" className="px-2 py-2 "><DocumentBadge key={doc.id} document={doc} /></td>
                                <td scope="row" className="px-2 py-2 "><RelativeDateFormatter date={doc.insert_ts} /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        }
    </DashboardWidget>
}

export default RecentDocumentsWidget;
