import { useEffect, useState } from "react";
import { actionCompletedToast } from "components/ui/toast";

import Loading from "components/ui/Loading";
import useFetch from "hooks/useFetch";
import secureApiFetch from "services/api";

const ReportConfigurationForm = ({ projectId }) => {

    const [serverConfiguration] = useFetch(`/reports/${projectId}/configuration`);
    const [clientConfiguration, updateClientConfiguration] = useState(null);

    const onConfigurationFormSubmit = (ev) => {
        ev.preventDefault();
        secureApiFetch(`/reports/${projectId}/configuration`, {
            method: 'PUT',
            body: JSON.stringify(clientConfiguration)
        })
        actionCompletedToast(`Configuration updated.`);
    }

    const onFormChange = (ev) => {
        ev.preventDefault();
        let value = ev.target.value;
        if (value === "true" || value === "false") {
            value = JSON.parse(value);
        }
        updateClientConfiguration({ ...clientConfiguration, [ev.target.name]: value });
    }

    useEffect(() => {
        if (serverConfiguration) {
            updateClientConfiguration(serverConfiguration);
        }
    }, [serverConfiguration]);
    if (!clientConfiguration) return <Loading />

    return <>
        <form onSubmit={onConfigurationFormSubmit} className="crud">
            <fieldset>
                <legend>Optional sections</legend>

                <label>
                    Include table of contents
                    <select name="include_toc" value={clientConfiguration.include_toc} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select >
                </label>
                <label>
                    Include revisions table
                    <select name="include_revisions_table" value={clientConfiguration.include_revisions_table} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select >
                </label>
                <label>
                    Include team bios
                    <select name="include_team_bios" value={clientConfiguration.include_team_bios} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select >
                </label>
                <label>
                    Include findings overview
                    <select name="include_findings_overview" value={clientConfiguration.include_findings_overview} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select >
                </label>
                <label>
                    Include cover
                    <select name="include_cover" value={clientConfiguration.include_cover} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select >
                </label>
                <label>
                    Include header
                    <select name="include_header" value={clientConfiguration.include_header} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select >
                </label>
                <label>
                    Include footer
                    <select name="include_footer" value={clientConfiguration.include_footer} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select >
                </label>
                <label>
                    Custom cover
                    <select name="custom_cover" value={clientConfiguration.custom_cover} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select >
                </label>
                <label>
                    Custom header
                    <select name="custom_header" value={clientConfiguration.custom_header} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select >
                </label>
                <label>
                    Custom footer
                    <select name="custom_footer" value={clientConfiguration.custom_footer} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select >
                </label>
            </fieldset>

            <button className='bg-[#3A3D89] px-4 py-2 rounded text-white text-sm' type="submit">Save configuration</button>
        </form>
    </>
}

export default ReportConfigurationForm;
