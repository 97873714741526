import { Tooltip } from '@chakra-ui/react'
import { Link } from 'react-router-dom';

import Loading from "components/ui/Loading";
import RelativeDateFormatter from "components/ui/RelativeDateFormatter";
import useFetch from "hooks/useFetch";
import DashboardWidget from "./Widget";
import RiskBadge from "components/badges/RiskBadge";

const RecentVulnerabilitiesWidget = () => {
    const [vulnerabilities] = useFetch(`/vulnerabilities?limit=5&orderColumn=insert_ts&orderDirection=desc&isTemplate=false`)

    if (!vulnerabilities) return <Loading />

    return <DashboardWidget title="Recent vulnerabilities (Last 5)">

        {vulnerabilities.length === 0 ?
            <p>No vulnerabilities to show.</p>
            :
            <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
                <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                    <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                        <tr>
                            <th scope="col" className="px-2 py-2">Summary</th>
                            <th scope="col" className="px-2 py-2">Risk</th>
                            <th scope="col" className="px-2 py-2">Created</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vulnerabilities.map(vulnerability => (
                            <tr key={vulnerability.id} className="bg-white border-b border-gray-300 text-black font-medium">
                                <td scope="row" className="px-2 py-2">
                                    <Link to={`/vulnerabilities/${vulnerability.id}`}>
                                        <Tooltip label={vulnerability.status}>
                                            <span className="text-black hover:underline">
                                                {vulnerability.summary}
                                            </span>
                                        </Tooltip>
                                    </Link>
                                </td>
                                <td scope="row" className="px-2 py-2">
                                    <RiskBadge risk={vulnerability.risk} score={vulnerability.cvss_score} />
                                </td>
                                <td scope="row" className="px-2 py-2">
                                    <RelativeDateFormatter date={vulnerability.insert_ts} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        }
    </DashboardWidget>
}

export default RecentVulnerabilitiesWidget;
