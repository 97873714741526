import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Alert, AlertIcon } from '@chakra-ui/react';

import SearchIcon from '@mui/icons-material/Search';
import PageTitle from 'components/logic/PageTitle';
import useQuery from 'hooks/useQuery';

import Breadcrumb from '../ui/Breadcrumb';
import Title from '../ui/Title';
import CommandsSearchResults from './CommandsSearchResults';
import ProjectsSearchResults from './ProjectsSearchResults';
import ProjectTemplatesSearchResults from './ProjectTemplatesSearchResults';
import TasksSearchResults from './TasksSearchResults';
import VulnerabilitiesSearchResults from './VulnerabilitiesSearchResults';
import VulnerabilityTemplatesSearchResults from './VulnerabilityTemplatesSearchResults';

const SearchResults = React.memo(() => {
    const params = useParams();
    const query = useQuery();
    const keywords = decodeURIComponent(params.keywords);

    const entitiesParam = query.has('entities') ? query.get('entities') : 'commands,tasks,vulnerabilities,projects';
    const entities = useMemo(() => entitiesParam.split(','), [entitiesParam]);

    const [emptyResults, setEmptyResults] = useState([]);

    return <>
        <PageTitle value={`${keywords} search results`} />
        <div className='heading'>
            <Breadcrumb />
            <div>
                <Link to="/advanced-search" className='bg-[#3A3D89] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                    <SearchIcon fontSize='small'/> Advanced search
                </Link>
            </div>
        </div>
        <Title type='Search results' title={`For ${keywords}`} icon={<SearchIcon />} />

        {emptyResults.length > 0 &&
            <Alert status="error">
                <AlertIcon />
                No results were found for: {[...new Set([...emptyResults])].join(', ')}
            </Alert>
        }

        {entities.includes('commands') &&
            <>
                <CommandsSearchResults keywords={keywords} emptyResultsSetter={setEmptyResults} />
            </>
        }
        {entities.includes('tasks') &&
            <>
                <TasksSearchResults keywords={keywords} emptyResultsSetter={setEmptyResults} />
            </>}
        {entities.includes('vulnerabilities') &&
            <>
                <VulnerabilitiesSearchResults keywords={keywords} emptyResultsSetter={setEmptyResults} />
            </>}
        {entities.includes('vulnerability_templates') &&
            <>
                <VulnerabilityTemplatesSearchResults keywords={keywords} emptyResultsSetter={setEmptyResults} />
            </>}
        {entities.includes('projects') &&
            <>
                <ProjectsSearchResults keywords={keywords} emptyResultsSetter={setEmptyResults} />
            </>}
        {entities.includes('project_templates') &&
            <>
                <ProjectTemplatesSearchResults keywords={keywords} emptyResultsSetter={setEmptyResults} />
            </>}
    </>
})

export default SearchResults;
