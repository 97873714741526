import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';

import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import RestrictedComponent from 'components/logic/RestrictedComponent';
import CreateButton from 'components/ui/buttons/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import PageTitle from 'components/logic/PageTitle';
import Breadcrumb from 'components/ui/Breadcrumb';
import NoResults from 'components/ui/NoResults';
import EditIcon from '@mui/icons-material/Edit';
import Loading from 'components/ui/Loading';
import Title from 'components/ui/Title';
import useDelete from 'hooks/useDelete';
import useFetch from 'hooks/useFetch';

import VulnerabilityCategoryEditModalDialog from './EditModalDialog';
import VulnerabilityCategoryAddModalDialog from './AddModalDialog';

const VulnerabilityCategoriesPage = () => {
    const [categories, fetchParentCategories] = useFetch('/vulnerabilities/categories?parentsOnly=0')

    const destroy = useDelete('/vulnerabilities/categories/', fetchParentCategories);

    const [editCategory, setEditCategory] = useState({});

    const { isOpen: isAddCategoryDialogOpen, onOpen: openAddCategoryDialog, onClose: closeAddCategoryDialog } = useDisclosure();
    const { isOpen: isEditCategoryDialogOpen, onOpen: openEditCategoryDialog, onClose: closeEditCategoryDialog } = useDisclosure();

    const onCategoryDialogClosed = () => {
        fetchParentCategories();

        closeAddCategoryDialog();
        closeEditCategoryDialog();
    }

    const onAddClick = ev => {
        ev.preventDefault();

        openAddCategoryDialog();
    }

    const onEditClick = (ev, ccategory) => {
        ev.preventDefault();

        setEditCategory(ccategory);
        openEditCategoryDialog();
    }

    const onDeleteClick = (ev, templateId) => {
        ev.stopPropagation();

        destroy(templateId);
    }

    return <>
        <PageTitle value="Vulnerability categories" />
        <div className='heading'>
            <Breadcrumb>
                <Link to="/vulnerabilities">Vulnerabilities</Link>
                <span>Categories</span>
            </Breadcrumb>

            <RestrictedComponent roles={['administrator', 'superuser', 'client']}>
                <VulnerabilityCategoryAddModalDialog isOpen={isAddCategoryDialogOpen} onClose={onCategoryDialogClosed} onCancel={closeAddCategoryDialog} />
                {isEditCategoryDialogOpen && <VulnerabilityCategoryEditModalDialog category={editCategory} isOpen={isEditCategoryDialogOpen} onClose={onCategoryDialogClosed} onCancel={closeEditCategoryDialog} />}
                <CreateButton onClick={onAddClick}>Add vulnerability category...</CreateButton>
            </RestrictedComponent>
        </div>
        <Title title='Vulnerability categories' icon={<CategoryOutlinedIcon />} />
        {!categories ? <Loading /> :
            <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
                <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                    <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                        <tr>
                            <th scope="col" className="px-4 py-2">Name</th>
                            <th scope="col" className="px-2 py-2">Parent category</th>
                            <th scope="col" className="px-2 py-2">Description</th>
                            <RestrictedComponent roles={['administrator', 'superuser']}>
                                <th scope="col" className="px-2 py-2">Actions</th>
                            </RestrictedComponent>
                        </tr>
                    </thead>
                    <tbody>
                        {categories.length === 0 ?
                            <tr><th><NoResults /></th></tr>
                            :
                            categories.map((category, index) => {
                                return (
                                    <tr key={index} className="bg-white border-b border-gray-300 text-black font-light">

                                        <th scope="row" className="px-2 py-2 ">
                                            <strong>{category.name}</strong>
                                        </th>

                                        <th scope="row" className="px-2 py-1 font-medium">{category.parent_name ?? '-'}</th>

                                        <th scope="row" className="px-2 py-1 font-medium">{category.description || '-'}</th>
                                        <RestrictedComponent roles={['administrator', 'superuser']}>
                                            <th scope="row" className="px-2 py-1 font-medium">
                                                <span className="flex items-center space-x-2">
                                                    <button onClick={ev => onEditClick(ev, category)} >
                                                        <EditIcon className="text-[#3A3D89]" fontSize="small" />
                                                    </button>
                                                    <button onClick={ev => onDeleteClick(ev, category.id)}>
                                                        <DeleteIcon className="text-[#EB1F36]" fontSize="small" />
                                                    </button>
                                                </span>
                                            </th>
                                        </RestrictedComponent>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        }
    </>
}

export default VulnerabilityCategoriesPage;
