
interface Props {
    pageNumber: number;
    selection: string[];
    documents?: object[];
    filters: object;
    sortBy: object;
}

class documentTableModel implements Props {
    pageNumber: number = 0;
    selection: string[] = [];
    documents?: object[] = [];
    filters: object = {
        projectId: null,
        categoryId: null,
        risk: null,
        status: null,
    };
    sortBy: object = { column: 'insert_ts', order: 'DESC' };
}

export default documentTableModel;
