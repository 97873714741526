import React from 'react'

const ProcessingLogo = ({ textColor }) => {
    return (
        <svg className={`animate-spin h-5 w-5 mx-1 ${textColor}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z" />
        </svg>
    )
}

export default ProcessingLogo;