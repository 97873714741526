import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { actionCompletedToast } from "../ui/toast";

import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

import useFetch from "../../hooks/useFetch";
import secureApiFetch from '../../services/api';
import Breadcrumb from '../ui/Breadcrumb';
import Loading from "../ui/Loading";
import Title from '../ui/Title';
import CommandForm from './Form';

const EditCommandPage = () => {
    const navigate = useNavigate();
    const { commandId } = useParams();

    const [serverCommand] = useFetch(`/commands/${commandId}`);
    const [clientCommand, setClientCommand] = useState(null);

    const onFormSubmit = async (ev) => {
        ev.preventDefault();

        await secureApiFetch(`/commands/${commandId}`, {
            method: 'PUT',
            body: JSON.stringify(clientCommand)
        })

        actionCompletedToast(`The command "${clientCommand.name}" has been updated.`);

        navigate(`/commands/${commandId}`)
    }

    useEffect(() => {
        if (serverCommand)
            setClientCommand(serverCommand);
    }, [serverCommand]);

    return (
        <div>
            <div className='heading'>
                <Breadcrumb>
                    <Link to="/commands">Commands</Link>
                </Breadcrumb>
            </div>

            <Title title="Command details" icon={<BorderColorOutlinedIcon />} />

            {!clientCommand ? <Loading /> :
                <CommandForm isEditForm={true} onFormSubmit={onFormSubmit} command={clientCommand}
                    commandSetter={setClientCommand} />
            }
        </div>
    )
}

export default EditCommandPage;
