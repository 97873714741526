import { DeleteIcon } from "@chakra-ui/icons";

const DeleteButton = (props) => {
    return (
        <button className="bg-red-500 hover:bg-red-700 text-white py-2 px-3 rounded" onClick={props.onClick} {...props} >
          <DeleteIcon />  {props.children || "Delete"}
        </button>
    )
}

export default DeleteButton;
