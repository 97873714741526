import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { actionCompletedToast } from "../ui/toast";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AttachmentsTable from 'components/attachments/AttachmentsTable';
import AttachmentsDropzone from 'components/attachments/Dropzone';
import CommandBadge from 'components/commands/Badge';
import CommandInstructions from 'components/commands/Instructions';
import PageTitle from 'components/logic/PageTitle';
import RestrictedComponent from 'components/logic/RestrictedComponent';
import EmptyField from 'components/ui/EmptyField';
import RelativeDateFormatter from 'components/ui/RelativeDateFormatter';
import TimestampsSection from 'components/ui/TimestampsSection';
import UserLink from 'components/users/Link';
import ReactMarkdown from 'react-markdown';
import Auth from 'services/auth';
import useDelete from '../../hooks/useDelete';
import TaskStatuses from "../../models/TaskStatuses";
import secureApiFetch from '../../services/api';
import Breadcrumb from "../ui/Breadcrumb";
import Loading from '../ui/Loading';
import useFetch from './../../hooks/useFetch';
import Title from './../ui/Title';
import TaskStatusFormatter from "./TaskStatusFormatter";
import TaskDropZone from './TaskDropZone';


const TaskDetails = () => {
    const loggedInUser = Auth.getLoggedInUser();
    const navigate = useNavigate();
    const { taskId } = useParams();
    const [task, fetchTask] = useFetch(`/tasks/${taskId}`)
    const [users] = useFetch(`/users`)
    const [project, setProject] = useState(null);
    const [command, setCommand] = useState(null);

    const parentType = 'task';
    const parentId = taskId;
    const [attachments, reloadAttachments] = useFetch(`/attachments?parentType=${parentType}&parentId=${parentId}`)
    const destroy = useDelete('/tasks/', fetchTask);

    const handleDelete = () => {
        destroy(task.id);
        navigate('/tasks');
    }

    const onAssigneeChange = ev => {
        const assigneeUid = ev.target.value;
        secureApiFetch(`/tasks/${task.id}`, {
            method: 'PATCH',
            body: JSON.stringify({ assignee_uid: '' === assigneeUid ? null : assigneeUid })
        })
            .then(() => {
                actionCompletedToast("The assignee has been updated.");
                fetchTask()
            })
            .catch(err => console.error(err))
    }




    const onStatusChange = ev => {
        const status = ev.target.value;
        secureApiFetch(`/tasks/${task.id}`, {
            method: 'PATCH',
            body: JSON.stringify({ status: status })
        })
            .then(() => {
                actionCompletedToast("The status has been transitioned.");
                fetchTask()
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        if (task) {
            if (task.command_id) {
                secureApiFetch(`/commands/${task.command_id}`, { method: 'GET' })
                    .then(resp => resp.json())
                    .then(command => setCommand(command))
                    .catch(err => console.error(err))
            }

            secureApiFetch(`/projects/${task.project_id}`, { method: 'GET' })
                .then(resp => resp.json())
                .then(project => setProject(project))
                .catch(err => console.error(err))
        }
    }, [task])

    return (
        <div>
            <div className="heading">
                <Breadcrumb>
                    <Link to="/tasks">Tasks</Link>
                    {project && <Link to={`/projects/${project.id}`}>{project.name}</Link>}
                </Breadcrumb>
                {task && users &&
                    <RestrictedComponent roles={['administrator', 'superuser', 'user']}>
                        <div className='flex items-center space-x-2'>
                            <Link to={`/tasks/${task.id}/edit`}>
                                <button className='bg-[#3A3D89] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                                    Edit
                                </button>
                            </Link>
                            <select onChange={onStatusChange} value={task.status} className='px-2 py-1.5 border rounded text-sm font-medium'>
                                {TaskStatuses.map((status, index) =>
                                    <option key={index} value={status.id}>{status.name}</option>
                                )}
                            </select>
                            <button onClick={() => handleDelete(task.id)} className='bg-[#EB1F36] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                                <DeleteIcon className='text-white' fontSize='small' /> Delete

                            </button>
                        </div>
                    </RestrictedComponent>
                }
            </div>
            {!task ? <Loading /> :
                <article>
                    <PageTitle value={`${task.summary} task`} />
                    <Title title={task.summary} type='Task' icon={<AssignmentOutlinedIcon />} />
                    <Tabs variant='enclosed'>
                        <TabList>
                            <Tab><span className='text-xs font-medium'>Details</span></Tab>
                            {null !== command &&
                                <Tab><span className='text-xs font-medium'>Command instructions</span></Tab>
                            }
                            <Tab><span className='text-xs font-medium'>Attachments</span></Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <div className="grid grid-two">
                                    <div>
                                        <h4 className='text-sm'> <InfoOutlinedIcon sx={{ color: '#3A3D89' }} /> Task Details</h4>
                                        <dl>
                                            <dt className='text-xs'>Description</dt>
                                            <dd className='text-xs'> {task.description ? <ReactMarkdown>{task.description}</ReactMarkdown> : <EmptyField />}</dd>
                                            <dt className='text-xs'>Priority</dt>
                                            <dd className='text-xs'>{task.priority}</dd>
                                            <dt className='text-xs'>Status</dt>
                                            <dd className='text-xs'><TaskStatusFormatter task={task} /></dd>
                                            {task.command_id && <>
                                                <dt className='text-xs'>Command</dt>
                                                <dd className='text-xs'><CommandBadge command={{ id: task.command_id, name: task.command_name }} /></dd>
                                            </>}
                                            <dt className='text-xs'>
                                                {task.output_parser === null ? 'not present' :
                                                    <TaskDropZone commandId={task.command_id} taskId={task.id} />
                                                }
                                            </dt>

                                        </dl>
                                        <div> </div>
                                    </div>

                                    <div>
                                        <h4 className='text-sm'>  <PermIdentityOutlinedIcon sx={{ color: '#3A3D89' }} /> People</h4>
                                        <dl>
                                            <dt className='text-xs'>Created by</dt>
                                            <dd className='text-xs'><UserLink userId={task.creator_uid}>{task.creator_full_name}</UserLink></dd>

                                            <dt className='text-xs'>Main Task ID</dt>
                                            <dd className='text-xs'>
                                                <Link to={`/tasks/${task.maintask}`}>
                                                    <button className='px-1.5 py-1 border rounded text-xs font-medium'>
                                                        {`${task.maintask ? task.maintask : 'Not Assigned'}`}
                                                    </button>
                                                </Link>
                                            </dd>

                                            <dt className='text-xs'>Assigned to</dt>
                                            <dd className='text-xs'>
                                                {users &&
                                                    <select onChange={onAssigneeChange} defaultValue={task.assignee_uid} className='px-2 py-1.5 border rounded text-sm font-medium'>
                                                        <option value="">(nobody)</option>
                                                        {users.map((user, index) =>
                                                            <option key={index} value={user.id}>{user.full_name}{user.id === loggedInUser.id ? " (You)" : ""}</option>
                                                        )}
                                                    </select>}
                                            </dd>
                                        </dl>
                                        <TimestampsSection entity={task} />
                                        {task.due_date &&
                                            <dl>
                                                <dt className='text-xs'>Due date</dt>
                                                <dd className='text-xs'><RelativeDateFormatter date={task.due_date} /></dd>
                                            </dl>
                                        }
                                    </div>
                                </div>
                            </TabPanel>
                            {null !== command && <TabPanel>
                                <CommandInstructions command={command} task={task} />
                            </TabPanel>}
                            <TabPanel>
                                <AttachmentsDropzone parentType={parentType} parentId={parentId} onUploadFinished={reloadAttachments} />
                                <h4 className='mt-2'> <AttachmentOutlinedIcon className='text-[#3A3D89]' />  Attachment list</h4>
                                <AttachmentsTable attachments={attachments} reloadAttachments={reloadAttachments} />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </article>
            }
        </div>
    )
}

export default TaskDetails;
