import useFetch from "hooks/useFetch";
import { useState, useEffect } from "react";
import DashboardWidget from "./Widget";
import Chart from "react-apexcharts";

const UserActivityStatsWidget = () => {
    const [auditLogStats] = useFetch('/auditlog/stats')
    const [chartData, setChartData] = useState({ series: [], options: {} });
    useEffect(() => {
        if (auditLogStats) {
            const formattedData = auditLogStats.map(item => {
                return { x: new Date(item.log_date).toLocaleDateString(), y: item.total };
            });

            const categories = auditLogStats.map(item => new Date(item.log_date).toLocaleDateString());
            const options = {
                chart: {
                    type: 'area',
                    height: 350,
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    type: 'category',
                    categories: categories,
                    labels: {
                        show:false,
                    }
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy'
                    }
                },
                // colors: ['#3A3D89'], 
                // fill: {
                //     type: 'gradient',
                //     gradient: {
                //         shadeIntensity: 1,
                //         opacityFrom: 0.7,
                //         opacityTo: 0.9,
                //         stops: [0, 90, 100]
                //     }
                // }
            };

            const series = [
                {
                    name: 'Total',
                    data: formattedData
                }
            ];

            setChartData({ series, options });
        }
    }, [auditLogStats]);

    return (
        <DashboardWidget title="User activity over time">
            {chartData.series.length > 0 ? (
                <Chart options={chartData.options} series={chartData.series} type="area" height={300} />
            ) : (
                <p>Loading...</p>
            )}
        </DashboardWidget>
    )
}

export default UserActivityStatsWidget
