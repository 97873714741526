import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { ButtonGroup, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { IconFlag } from 'components/ui/Icons';

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import PageTitle from 'components/logic/PageTitle';
import RestrictedComponent from 'components/logic/RestrictedComponent';
import Breadcrumb from 'components/ui/Breadcrumb';
import Loading from 'components/ui/Loading';
import Title from 'components/ui/Title';
import useDelete from 'hooks/useDelete';
import useFetch from 'hooks/useFetch';
import secureApiFetch from 'services/api';

import VulnerabilityDescriptionPanel from '../VulnerabilityDescriptionPanel';
import VulnerabilityRemediationPanel from '../VulnerabilityRemediationPanel';

const VulnerabilityTemplateDetails = () => {
    const navigate = useNavigate();
    const { templateId } = useParams();
    const [vulnerability] = useFetch(`/vulnerabilities/${templateId}`)

    const cloneProject = async (templateId) => {
        secureApiFetch(`/vulnerabilities/${templateId}/clone`, { method: 'POST' })
            .then(resp => resp.json())
            .then(data => {
                navigate(`/vulnerabilities/${data.vulnerabilityId}/edit`);
            });
    }

    const destroy = useDelete('/vulnerabilities/', () => {
        navigate('/vulnerabilities/templates');
    });

    if (!vulnerability) return <Loading />

    if (vulnerability && !vulnerability.is_template) {
        return <Navigate to={`/vulnerabilities/${vulnerability.id}`} />
    }

    return (
        <>
            <div>
                <div className='heading'>
                    <Breadcrumb>
                        <Link to="/vulnerabilities">Vulnerabilities</Link>
                        <Link to="/vulnerabilities/templates">Templates</Link>
                    </Breadcrumb>
                    <ButtonGroup>
                        <button onClick={() => cloneProject(vulnerability.id)} className='bg-[#3A3D89] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                           <FileCopyOutlinedIcon fontSize='small'/> Clone and edit
                        </button>
                        <RestrictedComponent roles={['administrator', 'superuser', 'user']}>
                            <Link to={`/vulnerabilities/${vulnerability.id}/edit`} className='bg-[#3A3D89] px-3 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                                Edit
                            </Link>
                            <button onClick={() => destroy(vulnerability.id)} className='bg-[#EB1F36] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                                <DeleteIcon fontSize='small' /> Delete
                            </button>
                        </RestrictedComponent>
                    </ButtonGroup>
                </div>
                <article>
                    <PageTitle value={`${vulnerability.summary} vulnerability template`} />

                    <Title type='Vulnerability template' title={vulnerability.summary} icon={<IconFlag />} />

                    <Tabs>
                        <TabList>
                            <Tab><span className="font-medium text-xs">Description</span></Tab>
                            <Tab><span className="font-medium text-xs">Remediation</span></Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <VulnerabilityDescriptionPanel vulnerability={vulnerability} />
                            </TabPanel>
                            <TabPanel>
                                <VulnerabilityRemediationPanel vulnerability={vulnerability} />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </article>
            </div>
        </>
    )
}

export default VulnerabilityTemplateDetails;
