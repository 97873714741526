import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Flex, HStack, useDisclosure } from '@chakra-ui/react';

import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import PageTitle from 'components/logic/PageTitle';
import Breadcrumb from 'components/ui/Breadcrumb';
import Title from 'components/ui/Title';
import secureApiFetch from '../../services/api';
import CreateButton from '../ui/buttons/Create';
import VulnerabilityFilters from './Filters';
import VulnerabilitiesTable from './VulnerabilitiesTable';
import VulnerabilityTableModel from './VulnerabilityTableModel';
import VulnerabilityModalDialog from './templates/VulnerabilityModalDialog';
import VulnerabilityPagination from 'components/layout/VulnerabilityPagination';

const VulnerabilitiesList = () => {
    const [tableModel, setTableModel] = useState(new VulnerabilityTableModel());
    const [totalCount, setTotalCount] = useState('?');
    const [numberPages, setNumberPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);  

    const { isOpen: isAddDialogOpen, onOpen: openAddDialog, onClose: closeAddDialog } = useDisclosure();

    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1); 
        }
    };

    const handleNext = () => {
        if (currentPage < numberPages) {
            setCurrentPage(currentPage + 1);  
        }
    };

    const reloadVulnerabilities = useCallback(() => {
        setTableModel(tableModel => ({ ...tableModel, vulnerabilities: null }));

        const queryParams = new URLSearchParams();
        queryParams.set('isTemplate', 'false');
        queryParams.set('page', currentPage - 1); 
        queryParams.set('orderColumn', tableModel.sortBy.column);
        queryParams.set('orderDirection', tableModel.sortBy.order);
        Object.keys(tableModel.filters).forEach(key => {
            if (tableModel.filters[key] !== null && tableModel.filters[key].length !== 0) {
                queryParams.append(key, tableModel.filters[key]);
            }
        });
        const url = `/vulnerabilities?${queryParams.toString()}`;

        secureApiFetch(url, { method: 'GET' })
            .then(resp => {
                if (resp.headers.has('X-Page-Count')) {
                    setNumberPages(parseInt(resp.headers.get('X-Page-Count')));
                }
                if (resp.headers.has('X-Total-Count')) {
                    setTotalCount(resp.headers.get('X-Total-Count'));
                }
                return resp.json();
            })
            .then(data => {
                setTableModel(tableModel => ({ ...tableModel, vulnerabilities: data }));
            });
    }, [currentPage, tableModel.filters, tableModel.sortBy]);

    useEffect(() => {
        reloadVulnerabilities();
    }, [reloadVulnerabilities, currentPage, tableModel.filters]);

    const onAddVulnerabilityClick = () => {
    };

    return (
        <div className='-mt-2'>
            <PageTitle value={`Vulnerabilities - Page `} />
            <div className='heading'>
                <Breadcrumb>
                    <Link to="/vulnerabilities">Vulnerabilities</Link>
                </Breadcrumb>
                <VulnerabilityPagination page={currentPage} total={numberPages} handlePrev={handlePrev} handleNext={handleNext} />
                <HStack>
                    <CreateButton onClick={onAddVulnerabilityClick}>Add vulnerability</CreateButton>

                    <div className="flex justify-end">
                        <VulnerabilityModalDialog
                            isOpen={isAddDialogOpen}
                            onCancel={closeAddDialog}
                            tableModel={tableModel}
                            reloadVulnerabilities={reloadVulnerabilities}
                            setTableModel={setTableModel}
                        />
                        <button onClick={openAddDialog} className='bg-[#3A3D89] px-2 py-1 rounded text-white space-x-1 flex items-center text-sm'>
                            Bulk-Action
                        </button>
                    </div>

                </HStack>
            </div>
            <Title title={`Vulnerabilities (${totalCount})`} icon={<BugReportOutlinedIcon />} />
            <Flex>
                <VulnerabilityFilters tableModel={tableModel} tableModelSetter={setTableModel} />
            </Flex>
            <VulnerabilitiesTable tableModel={tableModel} tableModelSetter={setTableModel} reloadCallback={reloadVulnerabilities} />
        </div>
    )
}

export default VulnerabilitiesList;
