import { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import secureApiFetch from 'services/api';
import PageTitle from 'components/logic/PageTitle';
import Breadcrumb from 'components/ui/Breadcrumb';
import Title from 'components/ui/Title';
import ActivityBadge from 'components/projects/ActivityBadge';
import ApprovalBadge from 'components/projects/ApprovalBadge';
import UserLink from 'components/users/Link'
import useQuery from 'hooks/useQuery';
import PaginationV2 from 'components/layout/PaginationV2';
import LoadingTableRow from 'components/ui/tables/LoadingTableRow';
import NoResultsTableRow from 'components/ui/tables/NoResultsTableRow';

import ClientLink from "../../clients/Link";
import 'react-toastify/dist/ReactToastify.css';
import useDelete from 'hooks/useDelete';
import RestrictedComponent from 'components/logic/RestrictedComponent';

const ApprovalsList = () => {

    const numColumns = 6;
    const navigate = useNavigate();
    const query = useQuery();
    let pageNumber = query.get('page');
    pageNumber = pageNumber !== null ? parseInt(pageNumber) : 1;
    const apiPageNumber = pageNumber - 1;

    const [numberPages, setNumberPages] = useState(1);
    const [totalCount, setTotalCount] = useState('?');

    const [approvals, setProjectsApprovals] = useState([]);

    const onPageChange = pageNumber => {
        const queryParams = new URLSearchParams();
        queryParams.set('page', pageNumber + 1);
        const url = `/projects/approvals?${queryParams.toString()}`;
        navigate(url);
    }


    const reloadProjectsApprovals = useCallback(() => {
        setProjectsApprovals(null);

        const queryParams = new URLSearchParams();
        queryParams.set('page', apiPageNumber);
        const url = `/projectapprovals?orderDirection=desc&${queryParams.toString()}`;

        secureApiFetch(url)
            .then(resp => {
                if (resp.headers.has('X-Page-Count')) {
                    setNumberPages(resp.headers.get('X-Page-Count'))
                }
                if (resp.headers.has('X-Total-Count')) {
                    setTotalCount(resp.headers.get('X-Total-Count'))
                }
                return resp.json()
            })
            .then(approvals => {
                setProjectsApprovals(approvals);
            });
    }, [apiPageNumber])

    const destroy = useDelete('/projectapproval/', reloadProjectsApprovals);

    useEffect(() => {
        reloadProjectsApprovals();
    }, [reloadProjectsApprovals]);

    return (
        <> <PageTitle value="Activity Aprrovals" />
            <div className='heading'>
                <Breadcrumb>
                    <Link to="/projects">Projects</Link>
                    <Link to="/projects/approvals">Upcoming Projects</Link>
                </Breadcrumb>
                <PaginationV2 page={apiPageNumber} total={numberPages} onPageChange={onPageChange} />
                <div></div>
            </div>
            <Title title={`Activity Aprrovals List (${totalCount})`} icon={<FolderOpenOutlinedIcon />} />

            <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
                <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                    <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                        <tr>
                            <th scope="col" className="px-4 py-2 ">Activity Name</th>
                            <th scope="col" className="px-2 py-2 text-center">Client</th>
                            <th scope="col" className="px-2 py-2 text-center">Type</th>
                            <th scope="col" className="px-2 py-3 text-center">Start Date</th>
                            <th scope="col" className="px-2 py-2 text-center">End Date</th>
                            <th scope="col" className="px-2 py-2 text-center">Apporval Status</th>
                            <th scope="col" className="px-2 py-2 text-center">Creator Name</th>
                            <RestrictedComponent roles={['administrator']}>
                                <th scope="col" className="px-2 py-2 text-center">Action</th>
                            </RestrictedComponent>
                        </tr>
                    </thead>
                    <tbody>
                        {approvals === null && <LoadingTableRow numColumns={numColumns} />}
                        {approvals !== null && approvals.length === 0 && <NoResultsTableRow numColumns={numColumns} />}
                        {approvals !== null && approvals.length !== 0 && approvals.map((approval, index) => {
                            return (
                                <tr key={index} className="bg-white border-b border-gray-300 text-gray-800 ">

                                    <th scope="row" className="px-2 py-1 "> <ActivityBadge activity={approval} /> </th>

                                    <th scope="row" className="px-2 py-1 text-center"><ClientLink clientId={approval.client_id}>{approval.client_name}</ClientLink></th>
                                    <th scope="row" className="px-2 py-1 text-center"> {approval.activity_type} </th>

                                    <th scope="row" className="px-2 py-1 text-center"> {approval.start_date} </th>

                                    <th scope="row" className="px-2 py-1 text-center"> {approval.end_date} </th>

                                    <th scope="row" className="px-2 py-1 text-center">
                                        <ApprovalBadge activity={approval} />
                                    </th>

                                    <th scope="row" className="px-2 py-1 text-center">
                                        <UserLink userId={approval.user_id}>{approval.user_full_name}</UserLink>
                                    </th>
                                    <RestrictedComponent roles={['administrator']}>
                                        <th scope="row" className="px-2 py-1 text-center">
                                            <button onClick={() => destroy(approval.id)}>
                                                <DeleteIcon fontSize='small' className='text-red-600 mx-1' />
                                            </button>
                                        </th>
                                    </RestrictedComponent>

                                </tr>
                            )
                        })
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ApprovalsList;
