import { useEffect, useState } from 'react';
import { Breadcrumb, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { actionCompletedToast } from 'components/ui/toast';

import AttachmentsImageDropzone from 'components/attachments/ImageDropzone';
import RestrictedComponent from 'components/logic/RestrictedComponent';
import TuneIcon from '@mui/icons-material/Tune';
import Organisation from 'models/Organisation';
import Loading from 'components/ui/Loading';
import secureApiFetch from 'services/api';
import Title from 'components/ui/Title';
import useFetch from 'hooks/useFetch';
import PageTitle from 'components/logic/PageTitle';

const OrganisationForm = () => {
    const [organisation, setOrganisation] = useState(Organisation);

    const [rootOrganisation, refetchOrganisation] = useFetch('/organisations/root');
    const [loading, setLoading] = useState(false);
    const parentType = 'organisation';
    const parentId = organisation.id;
    const [logo, setLogo] = useState(null);
    const [smallLogo, setSmallLogo] = useState(null);

    const onFormSubmit = async (ev) => {
        ev.preventDefault();

        setLoading(true);
        await secureApiFetch(`/organisations/root`, { method: 'PUT', body: JSON.stringify(organisation) });
        actionCompletedToast('The changes to the organisation has been saved.');
        setLoading(false);
    }

    const handleFormChange = ev => {
        const target = ev.target;

        const name = target.name;
        const value = target.value;

        setOrganisation({ ...organisation, [name]: value });
    };

    useEffect(() => {
        if (rootOrganisation) {
            setOrganisation(rootOrganisation);
            if (rootOrganisation.small_logo_attachment_id !== null) {
                downloadAndDisplayLogo(rootOrganisation.small_logo_attachment_id, 'small_logo');
            } else {
                setSmallLogo(null);
            }

            if (rootOrganisation.logo_attachment_id !== null) {
                downloadAndDisplayLogo(rootOrganisation.logo_attachment_id, 'logo');
            } else {
                setLogo(null);
            }
        }
    }, [rootOrganisation]);

    const downloadAndDisplayLogo = (logoId, type) => {
        secureApiFetch(`/attachments/${logoId}`, { method: 'GET' })
            .then(resp => {
                if (resp.status === 404) {
                    return Promise.reject("Logo not found");
                }
                const contentDispositionHeader = resp.headers.get('Content-Disposition');
                const filenameRe = new RegExp(/filename="(.*)";/)
                const filename = filenameRe.exec(contentDispositionHeader)[1]
                return Promise.all([resp.blob(), filename]);
            })
            .then((values) => {
                const blob = values[0];
                const url = URL.createObjectURL(blob);
                if (type === 'small_logo') {
                    setSmallLogo(url);
                } else {
                    setLogo(url);
                }
            })
    }

    const onUploadFinished = (type, id) => {
        if (id) {
            setOrganisation({ ...organisation, [type]: id });
            organisation[type] = id;
            setLoading(true);
            secureApiFetch(`/organisations/root`, { method: 'PUT', body: JSON.stringify(organisation) })
                .then(ev => {
                    // has to be done after PUT is completed. Therefore it cannot be merged with the case when ID is null
                    refetchOrganisation();
                })
            setLoading(false);
        } else {
            refetchOrganisation();
        }
    };

    const removeAttachment = (ev, id) => {
        secureApiFetch(`/attachments/${id}`, { method: 'DELETE' })
            .then(resp => {
                if (resp.status === 204) {
                    actionCompletedToast("Logo removed");
                    refetchOrganisation();
                }
            })
    }

    if (!organisation) {
        return <Loading />
    }

    return (
        <div>
            <PageTitle value="Settings" />
            <div className='heading'>
                <Breadcrumb>
                    <Link to="/settings">Settings</Link>
                </Breadcrumb>
            </div>
            <form onSubmit={onFormSubmit}>
                <Title title="Settings" type="Organisation" icon={<TuneIcon />} />
                <hr className='h-px bg-[#3A3D89]' />
                <label>
                    <span className="font-medium text-xs">Name</span>
                    <input type="text" name="name" value={organisation.name} onChange={handleFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
                </label>
                <label>
                    <span className="font-medium text-xs">URL</span>
                    <input type="text" name="url" value={organisation.url} onChange={handleFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                </label>
                <label>
                    <span className="font-medium text-xs">Contact name</span>
                    <input type="text" name="contact_name" value={organisation.contact_name || ""} onChange={handleFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                </label>
                <label>
                    <span className="font-medium text-xs">Contact email</span>
                    <input type="email" name="contact_email" value={organisation.contact_email || ""} onChange={handleFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                </label>
                <label>
                    <span className="font-medium text-xs">Contact phone</span>
                    <input type="tel" name="contact_phone" value={organisation.contact_phone || ""} onChange={handleFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                </label>

                <button type='submit' disabled={loading} className='bg-[#3A3D89] w-44 px-4 py-2 rounded text-white text-sm'>
                    Save Organisation
                </button>
                
                <div className="flex space-x-4">
                    <div className="flex-1">
                        <label className="font-medium text-xs">Main logo </label>
                        <div>
                            {logo && <div><img src={logo} alt="The main organisation logo" /><br /><Button onClick={ev => removeAttachment(ev, organisation.logo_attachment_id)}>Remove</Button></div>}
                            {logo === null && <RestrictedComponent roles={['administrator', 'superuser', 'user']} message="(access restricted)">
                                <AttachmentsImageDropzone parentType={parentType} parentId={parentId} onUploadFinished={onUploadFinished} uploadFinishedParameter="logo_attachment_id" maxFileCount={1} />
                            </RestrictedComponent>}
                        </div>
                    </div>
                    <div className="flex-1">
                        <label className="font-medium text-xs">Small logo</label>
                        <div>
                            {smallLogo && <div><img src={smallLogo} alt="The smaller version of the logo" /><br /><Button onClick={ev => removeAttachment(ev, organisation.small_logo_attachment_id)}>Remove</Button></div>}
                            {smallLogo === null && <RestrictedComponent roles={['administrator', 'superuser', 'user']} message="(access restricted)">
                                <AttachmentsImageDropzone parentType={parentType} parentId={parentId} onUploadFinished={onUploadFinished} uploadFinishedParameter="small_logo_attachment_id" maxFileCount={1} />
                            </RestrictedComponent>}
                        </div>
                    </div>
                </div>

            </form>
        </div>
    )
}

export default OrganisationForm;
