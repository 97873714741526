import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FormControl, FormHelperText, FormLabel, Textarea } from '@chakra-ui/react';

import PageTitle from 'components/logic/PageTitle';
import Loading from 'components/ui/Loading';
import useFetch from 'hooks/useFetch';

import secureApiFetch from "../../services/api";
import Breadcrumb from '../ui/Breadcrumb';
import Title from '../ui/Title';

const SendReport = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const [project] = useFetch(`/projects/${projectId}`)
    const [revisions] = useFetch(`/reports?projectId=${projectId}`)
    const [subjectData, setSubjectData] = useState('')
    const [bodyData, setBodyData] = useState('')

    const [deliverySettings, setDeliverySettings] = useState({
        report_id: null,
        recipients: null,
        subject: "[CONFIDENTIAL] Security report attached",
        body: ""
    })

    const handleSend = async (ev) => {
        ev.preventDefault();

        secureApiFetch(`/reports/${deliverySettings.report_id}/send`, { method: 'POST', body: JSON.stringify(deliverySettings) })
            .then(() => {
                navigate(`/projects/${project.id}/report`);
            })
            .catch(err => {
                console.error(err);
            })
    }

    const handleFormChange = ev => {
        const target = ev.target;
        const name = target.name;
        const value = target.value;
        setDeliverySettings({ ...deliverySettings, [name]: value });
    };

    const handleSubjectChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setDeliverySettings({ ...deliverySettings, [name]: value });
        setSubjectData(value)
    }
    const handleBodyChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setDeliverySettings({ ...deliverySettings, [name]: value });
        setBodyData(value)
    }

    useEffect(() => {
        if (revisions && deliverySettings.report_id === null)
            setDeliverySettings({ ...deliverySettings, report_id: revisions[0].id })
    }, [revisions, deliverySettings]);

    useEffect(() => {
        if (revisions) {
            setSubjectData(`[CONFIDENTIAL] ${revisions[0].project_name} | ${revisions[0].client_name} | Valid till: ${revisions[0].valid_till.substring(0, 10)} `)
        }
    }, [revisions])
    useEffect(() => {
        setBodyData(`Please review attachment containing a security report. Security report please visit our pentesting portal to download`)
    }, [])

    if (!project) return <Loading />

    return <div>
        <PageTitle value="Send report" />

        <div className='heading'>
            <Breadcrumb>
                <Link to="/projects">Projects</Link>
                {project && <Link to={`/projects/${project.id}`}>{project.name}</Link>}
                {project && <Link to={`/projects/${project.id}/report`}>Report</Link>}
            </Breadcrumb>
        </div>
        <form onSubmit={handleSend}>
            {revisions && <Title title={`Send report - ${revisions[0].project_name}`} />}

            <FormControl isRequired>
                <FormLabel for="reportId">Revision</FormLabel>
                <select id="reportId" name="report_id" onChange={handleFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'>
                    {revisions && revisions.map(revision => <option value={revision.id}>{revision.version_name}</option>)}
                </select>
            </FormControl>
            <FormControl isRequired>
                <FormLabel>Recipients</FormLabel>
                <input type="text" name="recipients" onChange={handleFormChange}
                    placeholder="foo@prosecurity.work" className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                <FormHelperText>Comma separated list of email addresses.</FormHelperText>
            </FormControl>
            <FormControl isRequired>
                <FormLabel>Subject</FormLabel>
                {revisions &&
                    <input type="text" name="subject" onChange={handleSubjectChange}
                        value={subjectData} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                }
            </FormControl>
            <FormControl isRequired>
                <FormLabel>Body</FormLabel>
                <Textarea name="body" onChange={handleBodyChange} value={bodyData} />
            </FormControl>
            <div className="fixed bottom-4 right-4">
                <button type='submit' className='bg-[#3A3D89] px-4 py-2 rounded text-white text-sm'>
                    Send
                </button>
            </div>
        </form>
    </div>
}

export default SendReport;
