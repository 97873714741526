import { useEffect, useState } from 'react';

import AttachmentsImageDropzone from 'components/attachments/ImageDropzone';
import RestrictedComponent from 'components/logic/RestrictedComponent';

import secureApiFetch from '../../services/api';

const ClientForm = ({ isEditForm = false, onFormSubmit, client, clientSetter: setClient }) => {
    const parentType = 'client';
    const parentId = client.id;
    const [logo, setLogo] = useState(null);
    const [smallLogo, setSmallLogo] = useState(null);
    const [clientCode, setClientCode] = useState('');

    const onFormChange = ev => {
        const target = ev.target;
        const name = target.name;
        const value = target.value;

        if (name === 'name') { getAbbreviation(value) }

        if (isEditForm) {
            setClient({ ...client, [name]: value });
        } else {
            setClient({ ...client, [name]: value, 'code': clientCode });
        }
        if (name === 'name' && !value) { setClientCode('') }

    };


    useEffect(() => {
        if (client.small_logo_attachment_id) {
            downloadAndDisplayLogo(client.small_logo_attachment_id, 'small_logo');
        }

        if (client.logo_attachment_id) {
            downloadAndDisplayLogo(client.logo_attachment_id, 'logo');
        }
    }, [client]);


    const downloadAndDisplayLogo = (logoId, type) => {
        secureApiFetch(`/image/${logoId}`, { method: 'GET', headers: {} })
            .then(resp => {
                const contentDispositionHeader = resp.headers.get('Content-Disposition');
                const filenameRe = new RegExp(/filename="(.*)";/)
                const filename = filenameRe.exec(contentDispositionHeader)[1]
                return Promise.all([resp.blob(), filename]);
            })
            .then((values) => {
                const blob = values[0];
                const url = URL.createObjectURL(blob);
                if (type === 'small_logo') {
                    setSmallLogo(url);
                } else {
                    setLogo(url);
                }
            })
    }

    const onUploadFinished = (type, id) => {
        if (id) {
            setClient({ ...client, [type]: id });
        }
    };

    const getAbbreviation = (text) => {
        if (typeof text != 'string' || !text) {
            return '';
        }
        let acronym = text
            .match(/[\p{Alpha}\p{Nd}]+/gu)
            .reduce((previous, next) => previous + ((+next === 0 || parseInt(next)) ? parseInt(next) : next[0] || ''), '')
            .toUpperCase();
        if (acronym.length < 4) {
            const padLength = 4 - acronym.length;
            const padding = text.replace(/[\W_]+/g, "").substring(0, padLength).toUpperCase();
            acronym += padding;
        }
        acronym = acronym.substring(0, 4);
        setClientCode(acronym);
    }
    return (
        <form onSubmit={onFormSubmit} className="crud">
            <fieldset>

                <label className="text-lg font-medium text-black">Basic information</label>
                <hr className='h-px bg-[#3A3D89]' />

                <label className="text-sm font-medium text-gray-700">
                    <span className="font-medium text-xs">Name</span>
                    <input type="text" name="name" onChange={onFormChange} value={client.name || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
                </label>

                <label className="text-sm font-medium text-gray-700">
                    <span className="font-medium text-xs">Client Code</span>
                    <input type="text" name="code" onChange={onFormChange} value={client.code || clientCode} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                </label>

                <label className="text-sm font-medium text-gray-700">
                    <span className="font-medium text-xs">Address</span>
                    <input type="text" name="address" onChange={onFormChange} value={client.address || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                </label>

                <label className="text-sm font-medium text-gray-700">
                    <span className="font-medium text-xs">URL</span>
                    <input type="text" name="url" onChange={onFormChange} value={client.url || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                </label>

            </fieldset>

            <fieldset>
                <label className="text-lg font-medium text-black">Branding</label>
                <hr className='h-[0.5px] bg-[#3A3D89]' />

                <div className="flex">
                    <div className="flex-1 mx-2">
                        <label className="text-lg font-medium text-black">Main logo</label>
                        <div className=''>
                            {logo && <img src={logo} alt="The main logo of client" />}
                            <RestrictedComponent roles={['administrator', 'superuser', 'user']} message="(access restricted)">
                                <AttachmentsImageDropzone parentType={parentType} parentId={parentId} onUploadFinished={onUploadFinished} uploadFinishedParameter="logo_attachment_id" attachmentId={client.logo_attachment_id} maxFileCount={1} />
                            </RestrictedComponent>
                        </div>
                    </div>

                    <div className="flex-1 mx-2">
                        <label className="text-lg font-medium text-black">Small logo</label>
                        <div>
                            {smallLogo && <img src={smallLogo} alt="The smaller version of the logo" />}
                            <RestrictedComponent roles={['administrator', 'superuser', 'user']} message="(access restricted)">
                                <AttachmentsImageDropzone parentType={parentType} parentId={parentId} onUploadFinished={onUploadFinished} uploadFinishedParameter="small_logo_attachment_id" attachmentId={client.small_logo_attachment_id} maxFileCount={1} />
                            </RestrictedComponent>
                        </div>
                    </div>
                </div>

                <div className="fixed bottom-4 right-4">
                    <button type='submit' className='bg-[#3A3D89] px-5 py-2 rounded text-white text-sm'>
                        {isEditForm ? "Save Client" : "Add Client"}
                    </button>
                </div>
            </fieldset>
        </form>
    )
}

export default ClientForm;
