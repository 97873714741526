import { Route } from "react-router-dom";
import SupportForm from "./Form";
import HelpIndexPage from "./HelpIndex";
// import LicensesPage from "./Licenses";
import UserManual from "./UserManual";

const SupportRoutes = [
    <Route path={`/help`} element={<HelpIndexPage />} />,
    <Route path={`/support`} element={<SupportForm />} />,
    // <Route path={`/licenses`} element={<LicensesPage />} />,
    <Route path={`/userManual`} element={<UserManual/>} />
]

export default SupportRoutes;
