
const Client = {
    name: null,
    address: null,
    url: null,
    code:null, 
    contact_name: null,
    contact_email: null,
    contact_phone: null,
    contact_kind: null
};

export default Client;
