import './Loading.scss'

function LoadingPage() {
    return (
        <figure className='flex justify-center'>
            <span className="loader"></span>
        </figure>
    )
}

export default LoadingPage