import { useEffect } from "react";

import MarkdownEditor from "components/ui/forms/MarkdownEditor";

import useFetch from "../../hooks/useFetch";
import Loading from "../ui/Loading";

const ProjectApprovalForm = ({ isEdit = true, activity, activitySetter: setActivity, onFormSubmit }) => {
    const [clients] = useFetch('/clients');
    const [categories] = useFetch('/project/categories');

    const handleFormChange = async (ev) => {
        const { name, value } = ev.target;
        const updatedActivity = { ...activity };
        updatedActivity.activity[0][name] = value;
        setActivity(updatedActivity);
    };

    const handleQuestionChange = (ev, questionKey) => {
        const { value, type, checked } = ev.target;
        const updatedActivity = { ...activity };
        if (!updatedActivity.activity[0].questions) {
            updatedActivity.activity[0].questions = {};
        }
        const questions = JSON.parse(updatedActivity.activity[0].questions);

        if (!questions[questionKey]) {
            questions[questionKey] = {};
        }

        if (type === "checkbox") {
            const answers = questions[questionKey].answer || [];
            if (checked) {
                answers.push(value);
            } else {
                const index = answers.indexOf(value);
                if (index > -1) {
                    answers.splice(index, 1);
                }
            }
            questions[questionKey].answer = answers;
        } else {
            questions[questionKey].answer = value;
        }

        updatedActivity.activity[0].questions = JSON.stringify(questions);
        setActivity(updatedActivity);
    };


    useEffect(() => {
        if (clients && clients.length && (activity.activity[0].id === null || activity.activity[0].id === 0)) {
            setActivity({ ...activity, id: clients.activity[0].id });
        }
    }, [activity, clients, setActivity]);

    const activityDetails = activity.activity[0] || {};
    const questionparse = JSON.parse(activityDetails.questions)

    if (!activity && !clients) return <Loading />;
    return (
        <form onSubmit={onFormSubmit} className="crud">

            <fieldset>
                <div className='flex justify-between'>
                    <label className="text-lg font-medium text-black">Basic information</label>
                </div>
                <hr className='h-px bg-[#3A3D89]' />

                <label>
                    <span className="font-medium text-xs">Name</span>
                    <input type="text" name="activity_name" onChange={handleFormChange} value={activityDetails.activity_name || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
                </label>


                <label>
                    <span className="font-medium text-xs">Start Date</span>
                    <input type="date" name="start_date" onChange={handleFormChange} value={activityDetails.start_date || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
                </label>

                <label>
                    <span className="font-medium text-xs">End Date</span>
                    <input type="date" name="end_date" onChange={handleFormChange} value={activityDetails.end_date || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
                </label>

                <label>
                    <span className="font-medium text-xs">Assessment Type</span>
                    <select name="assessment_type" onChange={handleFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'>
                        {categories && <>
                            {categories.map((category, index) => (
                                <option key={`category_${category.id}`} value={category.id} selected={index === 0 && category.name === activityDetails.activity_name}
                                >{category.name} - {category.product_code} - {category.id}
                                </option>
                            ))}
                        </>}
                    </select>
                </label>
                <div>
                    {Object.entries(questionparse).map(([title, data], index) => (
                        <div className="flex justify-start" key={index}>
                            <span className="font-medium text-xs my-2 min-w-56"> {title} </span>
                            <div style={{ display: 'flex' }}>
                                {data.type === 'checkbox' ? (
                                    data.options.map((option, optionIndex) => (
                                        <div key={optionIndex} className="flex items-center mx-2">
                                            <input
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                                                type="checkbox"
                                                name={option}
                                                value={option}
                                                checked={data.answer.includes(option)}
                                                onChange={(e) => handleQuestionChange(e, title)}
                                            />
                                            <p className="mx-1">{option}</p>
                                        </div>
                                    ))
                                ) : data.type === 'radio' ? (
                                    data.options.map((option, optionIndex) => (
                                        <div className="flex items-center mx-2" key={optionIndex}>
                                            <input
                                                type="radio"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                                                name={title}
                                                value={option}
                                                checked={data.answer.includes(option)}
                                                onChange={(e) => handleQuestionChange(e, title)}
                                            />
                                            <p className="mx-1">{option}</p>
                                        </div>
                                    ))
                                ) : data.type === 'textarea' ? (
                                    <div>
                                        <textarea
                                            name={title}
                                            rows="4"
                                            cols="100"
                                            value={data.answer}
                                            onChange={(e) => handleQuestionChange(e, title)}
                                            className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'
                                        />
                                    </div>
                                ) : (
                                    <input
                                        name={title}
                                        type="text"
                                        className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'
                                        value={data.answer}
                                        onChange={(e) => handleQuestionChange(e, title)}
                                    />
                                )}
                            </div>
                            <hr />
                        </div>
                    ))}
                </div>

                <label>
                    <span className="font-medium text-xs">Note to Assessor</span>
                    <MarkdownEditor name="note_to_assessor" onChange={handleFormChange} value={activityDetails.note_to_assessor || ""} required />
                </label>

                <label>
                    <span className="font-medium text-xs">Exception or Limitations</span>
                    <MarkdownEditor name="exception_or_limitations" onChange={handleFormChange} value={activityDetails.exception_or_limitations || ""} required />
                </label>


                <label>
                    <span className="font-medium text-xs">Activity Comment</span>
                    <MarkdownEditor name="activity_comment" onChange={handleFormChange} value={activity.comment || ""} required />
                </label>

            </fieldset>
            <div className="fixed bottom-4 right-4">
                <button type='submit' className='bg-[#3A3D89] px-4 py-2 rounded text-white text-sm'>
                    {isEdit ? "Update Project" : "Create Project"}
                </button>
            </div>

        </form>
    )
}

export default ProjectApprovalForm;
