import { useContext } from 'react';
import { WebsocketContext } from 'contexts/WebsocketContext';

import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import PageTitle from 'components/logic/PageTitle';
import convertReadyStateToText from 'utilities/WebsocketState';

import useFetch from '../../hooks/useFetch';
import Breadcrumb from '../ui/Breadcrumb';
import Title from "../ui/Title";
import { Link } from 'react-router-dom';

const GreenYes = ({ label = 'Yes' }) => <span style={{ color: 'green' }}>{label}</span>;
const RedNo = () => <span style={{ color: 'red' }}>No</span>;

const SystemHealthPage = () => {
    const wsContextData = useContext(WebsocketContext);
    const [apiHealth] = useFetch('/system/health');

    return (
        <div>
            <PageTitle value="System health" />
            <div className='heading'>
                <Breadcrumb>
                    <Link to='/system'> System </Link>
                    <div>System Health</div>
                </Breadcrumb>
            </div>
            <Title title="System health" icon={<HealthAndSafetyOutlinedIcon />} />

            {apiHealth && <>
                <div className="p-6 bg-gray-100 rounded-lg space-y-4 mt-2 mr-3">
                    <div>
                        <h2 className="text-lg font-semibold text-gray-800">API health</h2>
                        <div className="mt-4 space-y-4">
                            <div className="flex justify-between items-center">
                                <span className="text-gray-700">Response</span>
                                <span className="w-20 flex justify-center">
                                    <GreenYes label="Ok" />
                                </span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-gray-700">Database connection</span>
                                <span className="w-20 flex justify-center">
                                    {apiHealth.dbConnection.ping ? <GreenYes label="Ok" /> : <RedNo />}
                                </span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-gray-700">Attachments directory exists</span>
                                <span className="w-20 flex justify-center">
                                    {apiHealth.attachmentsDirectory.exists ? <GreenYes /> : <RedNo />}
                                </span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-gray-700">Backend API Version</span>
                                <span className=" flex justify-center">
                                    {localStorage.getItem('APP_VERSION')}
                                </span>
                            </div>
                            {/* 
                        <div className="flex justify-between items-center">
                            <span className="text-gray-700">Attachments directory is writeable</span>
                            <span className="w-20 flex justify-center">
                                {apiHealth.attachmentsDirectory.writeable ? <GreenYes /> : <RedNo />}
                            </span>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-gray-700">Logs directory exists</span>
                            <span className="w-20 flex justify-center">
                                {apiHealth.logsDirectory.exists ? <GreenYes /> : <RedNo />}
                            </span>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-gray-700">Logs directory is writeable</span>
                            <span className="w-20 flex justify-center">
                                {apiHealth.logsDirectory.writeable ? <GreenYes /> : <RedNo />}
                            </span>
                        </div> 
                        */}
                        </div>
                        <h2 className="text-lg font-semibold text-gray-800 mt-4">Websocket health</h2>
                        <div className="mt-4 space-y-4">
                            <div className="flex justify-between items-center">
                                <span className="text-gray-700">Connection status</span>
                                <span className="w-20 flex justify-center">
                                    {convertReadyStateToText(wsContextData.connection)}
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </>}
        </div>
    )
}

export default SystemHealthPage;
