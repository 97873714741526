import ProjectsTable from 'components/projects/Table';
import { useEffect, useState } from 'react';
import secureApiFetch from '../../services/api';
import ProjectTableModel from 'components/projects/ProjectTableModel';

const ProjectTemplatesSearchResults = ({ keywords, emptyResultsSetter: setEmptyResults }) => {

    const [tableModel, setTableModel] = useState(new ProjectTableModel());

    useEffect(() => {
        const reloadData = () => {
            secureApiFetch(`/projects?isTemplate=1&keywords=${keywords}`, { method: 'GET' })
                .then(resp => resp.json())
                .then(projectTemplates => {
                    setTableModel(tableModel => ({ ...tableModel, projects: projectTemplates }));
                    setEmptyResults(emptyResults => 0 === projectTemplates.length ? emptyResults.concat('project_templates') : emptyResults.filter(value => value !== 'project_templates'));
                })
        }

        reloadData()
    }, [keywords, setEmptyResults])

    if (tableModel.length === 0) return <></>

    return <>
        <h3>{tableModel.length} Matching Project Templates</h3>
        <ProjectsTable tableModel={tableModel} tableModelSetter={setTableModel} showClientColumn={false} />
        <hr className='mt-2 mb-5' />
    </>
}

export default ProjectTemplatesSearchResults;
