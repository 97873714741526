import useFetch from "hooks/useFetch";
import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/button";
import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger, PopoverFooter } from "@chakra-ui/popover";
import { CloseButton } from "@chakra-ui/react";
import { Tag } from "@chakra-ui/tag";
import { useWebsocketMessage } from "contexts/WebsocketContext";

import NotificationsIcon from '@mui/icons-material/Notifications';
import secureApiFetch from "services/api";
import './notification.css'

const NotificationsBadge = () => {
    const [notifications, fetchNotifications] = useFetch('/notifications?status=unread');

    const onMessageHandler = () => {
        fetchNotifications();
    }
    useWebsocketMessage(onMessageHandler);
    const markAsRead = notification => {
        secureApiFetch(`/notifications/${notification.id}`, {
            method: 'PUT',
            body: JSON.stringify({ status: 'read' })
        }).then(() => {
            fetchNotifications();
        })
        const notificationElement = document.getElementById(`notification-${notification}`);
        if (notificationElement) {
            notificationElement.classList.add('swipe-out-right');
            setTimeout(() => {
                notificationElement.remove();
            }, 500);
        }
    }

    const markAllAsRead = () => {
        const notificationIds = notifications.map((id) => id.id)
        secureApiFetch(`/notifications`, {
            method: 'PATCH',
            headers: {
                'Bulk-Operation': 'READ',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ notificationIds })
        }).then(() => {
            fetchNotifications();
        })
    }

    return (
        <Popover placement="bottom-end" closeOnBlur={true}>
            <PopoverTrigger>
                <Button pr={null !== notifications && notifications.length > 0 ? 1 : 2} variant="link" aria-label="Notifications" >
                    <NotificationsIcon sx={{ color: '#3A3D89', fontSize: '1.2rem' }} />
                    {null !== notifications && notifications.length > 0 && (
                        <Tag colorScheme='red'>{notifications.length}</Tag>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverHeader px="2" pb="1" color="gray.500">
                    <div className="flex justify-between items-center">
                        <div className="flex justify-between items-center w-full">
                            <label className="font-medium text-sm">Notifications</label>
                            <Link to="/notifications">
                                <label className="text-xs bg-blue-50 px-2 py-1 rounded cursor-pointer">See All</label>
                            </Link>
                        </div>
                    </div>
                </PopoverHeader>
                <PopoverBody>
                    {null !== notifications && notifications.length > 0 ? (
                        <ul className="notification-list list-none p-0 m-0">
                            {notifications.map((notification, index) =>
                                <li key={index} id={`notification-${notification.id}`} className="notification-item bg-gray-50 my-1 p-2 ">
                                    <div className='flex'>
                                        <div className="flex-1">
                                            <div className="text-700 font-semibold text-sm ">{notification.title}</div>
                                            <div className="text-xs">{notification.content}</div>
                                        </div>
                                    </div>
                                    <CloseButton position='absolute' right='8px' top='8px' onClick={() => markAsRead(notification)} />
                                </li>
                            )}
                        </ul>
                    ) : <span className="">No New Notifications.</span>}
                </PopoverBody>
                <PopoverFooter height={5} px="2" py="1">
                    <div className="flex justify-end">
                        {null !== notifications && notifications.length > 0 ? (
                            <button onClick={markAllAsRead} className="py-1 px-2 text-xs font-medium text-gray-600 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700" >
                                Mark All As Read
                            </button>
                        ) : <div></div>}
                    </div>
                </PopoverFooter>
            </PopoverContent>
        </Popover>
    )
}

export default NotificationsBadge;
