import TaskBadge from "components/tasks/TaskBadge";
import TaskStatusFormatter from "components/tasks/TaskStatusFormatter";
import Loading from "components/ui/Loading";
import useFetch from "hooks/useFetch";
import Auth from "services/auth";
import DashboardWidget from "./Widget";

const MyTasksWidget = () => {
    const user = Auth.getLoggedInUser();
    const [tasks] = useFetch(`/tasks?assigneeUid=${user.id}&limit=5`)

    if (!tasks) return <Loading />

    return (
        <DashboardWidget title="My tasks">

        {tasks.length === 0 ?
            <p>You don't have any assigned tasks.</p>
            :
            <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
                <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                    <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                        <tr>
                            <th scope="col" className="px-4 py-2">Summary</th>
                            <th scope="col" className="px-4 py-2">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tasks.map(task => (
                            <tr key={task.id} className="bg-white border-b border-gray-300 text-black font-light">
                                <td scope="row" className="px-2 py-2 "><TaskBadge task={task} /></td>
                                <td scope="row" className="px-2 py-2 "><TaskStatusFormatter task={task} /></td>
                            </tr>
                        )
                        )}
                    </tbody>
                </table>
            </div>
        }
    </DashboardWidget>
    )
}

export default MyTasksWidget;
