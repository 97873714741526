import React from "react"
import { Link } from "react-router-dom";

function BadgeOutline({ children, color = 'text-color', fontSize = 'fontSizeXsmall', icon, id = null }) {

    return (
        <>
            {id !== null ?
                <Link to={`/commands/${id}`}>
                    <button type="button" class="py-1.5 px-2 inline-flex items-center gap-x-2 text-xs font-semibold rounded-lg border  bg-gray-100 text-gray-800 hover:bg-gray-200 ">
                        {icon} {children}
                    </button>
                </Link> :
                <button type="button" class="py-1.5 px-2 inline-flex items-center gap-x-2 text-xs font-semibold rounded-lg border  bg-gray-100 text-gray-800 hover:bg-gray-200 ">
                    {icon} {children}
                </button>
            }


        </>
    )
}

export default BadgeOutline;

