
const TaskPriority = {
    Highest: 'Highest',
    High: 'High',
    Medium: 'Medium',
    Low: 'Low',
    Lowest: 'Lowest'
};

export const TaskPriorityList = Object.entries(TaskPriority)
    .map(kv => ({ name: kv[0], value: kv[1] }));

export default TaskPriority;
