import React, { useState } from "react";

import useFetch from "hooks/useFetch";
import secureApiFetch from "../../services/api";

const ExportForm = () => {
    const [entities] = useFetch('/system/exportables');

    const [exportButtonDisabled, setExportButtonDisabled] = useState(true);

    const [entitiesToExport, setEntitiesToExport] = useState([]);

    const onEntitiesSelectionChange = ev => {
        const selectedEntities = Array.from(ev.target.selectedOptions, option => option.value);
        setExportButtonDisabled(selectedEntities.length === 0);
        setEntitiesToExport(selectedEntities);
    };

    const onExportButtonClick = ev => {
        ev.preventDefault();

        const url = `/system/data?` + new URLSearchParams({ entities: entitiesToExport }).toString();
        secureApiFetch(url, { method: 'GET' })
            .then(resp => {
                const contentDispositionHeader = resp.headers.get('Content-Disposition');
                const filenameRe = new RegExp(/filename="(.*)";/)
                const filename = filenameRe.exec(contentDispositionHeader)[1]
                return Promise.all([resp.blob(), filename]);
            })
            .then((values) => {
                const blob = values[0];
                const filename = values[1];
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
            })
    };

    return (
        <div>
            <h3 className="font-medium text-lg text-gray-700">Export system data</h3>
            <div className="p-6 bg-gray-100 rounded-lg my-2">
                Notes:
                <ul className="list-disc list-inside text-gray-800">
                    <li className="mb-2">Select one or more entities to export.</li>
                    <li className="mb-2">The data will be returned in JSON format.</li>
                    <li className="mb-2">This operation can take up to one minute to complete depending on the size of your database.</li>
                </ul>
            </div>

            <div className="flex space-x-5">
                <select className='px-2 py-1.5 border rounded min-w-40 text-sm font-medium focus:outline-none' onChange={onEntitiesSelectionChange}>
                    {entities && entities.map(entity => <option key={entity.key} value={entity.key}>{entity.description}</option>)}
                </select>
                <button disabled={exportButtonDisabled} onClick={onExportButtonClick} className='bg-[#3A3D89] px-4 py-1.5 rounded text-white text-sm'>
                    Export
                </button>
            </div>

        </div>
    )
};

export default ExportForm;
