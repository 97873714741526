import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './ActivityBadge.scss';

const ActivityBadge = ({ activity }) => {
    return (
        <Link to={`/projects/approvals/${activity.id}`} className='px-1 py-1 rounded shadow text-xs'>
            {activity.activity_name}
        </Link>
    )
}

ActivityBadge.propTypes = {
    activity: PropTypes.object.isRequired
};

export default ActivityBadge;
