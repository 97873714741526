import { useState, useEffect, useCallback } from 'react';
import { Checkbox, useDisclosure } from '@chakra-ui/react';
import { actionCompletedToast, errorToast } from 'components/ui/toast';

import PreviewIcon from '@mui/icons-material/Preview';
import ViewDayOutlinedIcon from '@mui/icons-material/ViewDayOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import RestrictedComponent from 'components/logic/RestrictedComponent';
import NoResultsTableRow from 'components/ui/tables/NoResultsTableRow';
import Vault from 'models/Vault';
import secureApiFetch from 'services/api';

import VaultModal from './VaultModal';

const ProjectVaultTab = ({ project }) => {
    const [newVault, setNewVault] = useState()
    const [vaultItem, setVaultItem] = useState(new Vault());
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isEdit, setIsEdit] = useState(false);
    const [vaultId, setVaultId] = useState(0);

    const getVault = useCallback(() => {
        secureApiFetch(`/vault/${project.id}`, { method: 'GET' })
            .then((resp) => {
                if (resp.status === 403) {
                    return resp.json().then(data => {
                        if (!data.success) {
                            errorToast("user have limited access.");
                        }
                        throw new Error(data.msg);
                    });
                }
                return resp.json()
            })
            .then(data => {
                if (Array.isArray(data)) {
                    setNewVault(data);
                } else {
                    throw new Error('Invalid data format');
                }
            });
    }, [project]);

    useEffect(() => {
        getVault()
    }, [getVault])

    const handleOpen = (editMode, id) => {
        setIsEdit(editMode);
        setVaultId(id)
        onOpen();
    };

    const onVaultItemFormChange = ev => {
        const value = ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value;
        setVaultItem({ ...vaultItem, [ev.target.name]: value });
    }

    const onVaultItemDelete = vaultItemId => {
        const confirmed = window.confirm("Do you really want to delete this vault item? This action cannot be undone.");
        if (confirmed) {
            secureApiFetch(`/vault/${project.id}/${vaultItemId}`, { method: 'DELETE' })
                .then(() => {
                    getVault()
                    actionCompletedToast("The vault item has been deleted.");
                })
                .catch(err => console.error(err))
        }
    }

    const onFormSubmit = ev => {
        ev.preventDefault();

        secureApiFetch(`/vault/${project.id}`, { method: 'POST', body: JSON.stringify(vaultItem) })
            .then(resp => {
                if (resp.status === 201) {
                    setVaultItem(new Vault());
                    getVault()
                    actionCompletedToast(`The vault item has been added.`);
                } else {
                    errorToast("The vault item could not be saved. Review the form data or check the application logs.")
                }
            })
    }

    return <section className='-mt-1'>
        <h4 className='text-sm'>
            <ViewDayOutlinedIcon sx={{ color: '#3A3D89' }} />Vault

        </h4>
        <RestrictedComponent roles={['administrator', 'superuser', 'user']} message="(access restricted)">
            {newVault && <>
                <div className="border border-gray-200 relative overflow-x-auto rounded mt-2">
                    <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                        <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                            <tr>
                                <th scope="col" className="px-4 py-2">Title</th>
                                <th scope="col" className="px-4 py-2">Created by</th>
                                <th scope="col" className="px-4 py-2 ">Comment</th>
                                <th scope="col" className="px-4 py-2">Type</th>
                                <th scope="col" className="px-4 py-2">View</th>
                                <th scope="col" className="px-4 py-2">Created Date</th>
                                <th scope="col" className="px-4 py-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {0 === newVault.length && <NoResultsTableRow numColumns={6} />}
                            {newVault.map((item, index) => {
                                return (
                                    <tr key={index} className="bg-white border-b even:bg-gray-50 border-gray-300 text-black font-light">

                                        <th scope="row" className="px-4 py-1 ">
                                            {item.name}
                                        </th>
                                        <th scope="row" className="px-4 py-1 font-medium">
                                            {item.full_name}
                                        </th>
                                        <th scope="row" className="px-4 py-1 font-medium">
                                            {item.note}
                                        </th>
                                        <th scope="row" className="px-4 py-1 font-medium">
                                            {item.type}
                                        </th>
                                        <th scope="row" className="px-4 py-1 font-medium">
                                            <button onClick={() => handleOpen(false,item.id)}>
                                                <PreviewIcon className='text-[#3A3D89]' />
                                            </button>
                                        </th>
                                        <th scope="row" className="px-4 py-1 font-medium">
                                            {item.insert_ts}
                                        </th>
                                        <th scope="row" className="px-4 py-1 font-medium">
                                            <button onClick={() => handleOpen(true, item.id)}>
                                                <EditIcon className='text-[#3A3D89]' />
                                            </button>
                                            <button onClick={onVaultItemDelete.bind(this, item.id)} >
                                                <DeleteIcon className='text-red-700' />
                                            </button>
                                        </th>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <VaultModal
                        isEdit={isEdit}
                        onClose={onClose}
                        isOpen={isOpen}
                        onOpen={onOpen}
                        projectId={project.id}
                        vaultItemId={vaultId}
                        refreshVault={getVault}
                    />
                </div>

                <hr className='h-px  my-3' />

                <form onSubmit={onFormSubmit} className='mt-2'>
                    <h4 className='text-sm'><LockOutlinedIcon sx={{ color: '#3A3D89' }} />Create New Vault Item</h4>
                    <div className="border border-gray-200 relative overflow-x-auto rounded mt-2">
                        <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                            <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                                <tr>
                                    <th scope="col" className="px-4 py-2">Type</th>
                                    <th scope="col" className="px-4 py-2">Title</th>
                                    <th scope="col" className="px-4 py-2 ">Comment</th>
                                    <th scope="col" className="px-4 py-2">Secret </th>
                                    <th scope="col" className="px-4 py-2">Encryption Key</th>
                                    <th scope="col">Reportable</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="bg-white border-b even:bg-gray-50 border-gray-300 text-black font-light">

                                    <th scope="row" className="px-4 py-1 ">
                                        <select name="type" onChange={onVaultItemFormChange} value={vaultItem.type || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none min-w-28' required>
                                            <option value="password">Password</option>
                                            <option value="note">Note</option>
                                            <option value="token">Token</option>
                                            <option value="key">Key</option>
                                        </select>
                                    </th>
                                    <th scope="row" className="px-4 py-1 font-medium">
                                        <input type="text" name="name" onChange={onVaultItemFormChange} value={vaultItem.name || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
                                    </th>
                                    <th scope="row" className="px-4 py-1 font-medium">
                                        <input type="text" name="note" onChange={onVaultItemFormChange} value={vaultItem.note || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                                    </th>
                                    <th scope="row" className="px-4 py-1 font-medium">
                                        <input type="text" name="value" onChange={onVaultItemFormChange} value={vaultItem.value || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
                                    </th>
                                    <th scope="row" className="px-4 py-1 font-medium">
                                        <input type="password" name="password" onChange={onVaultItemFormChange} value={vaultItem.password || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
                                    </th>
                                    <th scope="row" className="px-4 py-1 font-medium">
                                        <Checkbox name="reportable" onChange={onVaultItemFormChange} isChecked={vaultItem.reportable} />
                                    </th>
                                    <th scope="row" className="px-4 py-1 font-medium">
                                        <button type="submit" className='bg-[#3A3D89] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                                            Add
                                        </button>
                                    </th>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </>}
        </RestrictedComponent>
    </section>;
}

export default ProjectVaultTab;
