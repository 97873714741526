import { Route } from "react-router-dom";
import ApprovalDetails from "./Details";
import ApprovalsList from "./List";

const ProjectApprovalsRoutes = [
    <Route path={`/projects/approvals`} element={<ApprovalsList />} />,
    <Route path={`/projects/approvals/:approvalId`} element={<ApprovalDetails />} />
]

export default ProjectApprovalsRoutes;
