import { FormControl, FormLabel } from '@chakra-ui/react';
import TargetKinds from '../../models/TargetKinds';
import React, { useState } from "react";
import { Stack, RadioGroup, Radio } from "@chakra-ui/react";


const TargetForm = ({ newTarget, targets, onFormSubmit, targetSetter: setTarget }) => {

    const [radioValue, setRadioValue] = React.useState('1')
    const [parentId, setParentId] = useState('');

    const handleParentId = (e) => {
        const target = e.target.value;
        setParentId(target)
    }

    const onFormChange = (ev) => {
        const target = ev.target;
        const name = target.name;
        let value = target.value;
        if ('tags' === name) {
            value = JSON.stringify(value.split(','));
        }
        if (radioValue !== '1') {
            setTarget({ ...newTarget, [name]: value, 'parent_id': parentId });
        } else {
            setTarget({ ...newTarget, [name]: value });
        }
    };

    return <div>
        <form onSubmit={onFormSubmit}>

            <RadioGroup onChange={setRadioValue} value={radioValue}>
                <Stack direction='row'>
                    <Radio value='1'>Target</Radio>
                    <Radio value='2'>Sub-Target</Radio>
                </Stack>
            </RadioGroup>
            {radioValue === '1' ? (
                <div></div>
            ) : (
                <div>
                    <FormControl id="parent_id" isRequired>
                        <FormLabel>Main Target</FormLabel>
                        <select name="parent_id" onChange={handleParentId} placeholder='Please Select the Target' className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'>
                            {targets.filter((name) => name.parent_id === null).map((name, index) =>
                                <option key={index} value={name.id}>{name.name}</option>
                            )}
                        </select>
                    </FormControl>
                </div>
            )
            }
            <FormControl id="name" isRequired>
                <FormLabel>Name</FormLabel>
                <input name="name" placeholder="e.g. 127.0.0.1" onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
            </FormControl>
            <FormControl id="tags">
                <FormLabel>Tags</FormLabel>
                <input name="tags" placeholder="e.g. linux,production" onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
            </FormControl>
            <FormControl id="kind" isRequired>
                <FormLabel>Kind</FormLabel>
                <select name="kind" onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'>
                    {TargetKinds.map((targetKind, index) =>
                        <option key={index} value={targetKind.value}>{targetKind.description}</option>
                    )}
                </select>
            </FormControl>
        </form>
    </div>
}

export default TargetForm;
