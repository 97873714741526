import Risks from "models/Risks";
import { useEffect, useState } from "react";
import secureApiFetch from "services/api";

const VulnerabilityProjectFilter = ({ tableModel, tableModelSetter: setTableModel }) => {
    const [filterCategories,setFilterCategories] = useState([])

    const handleCategories = ()=>{
        secureApiFetch('/vulnerabilities/categories?parentsOnly=0',{
            method:'GET'
        })
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            const filteredData = data.filter((data)=>data.parent_id !== null)
            setFilterCategories(filteredData)
        })
    }

    const onFilterChange = ev => {
        const { name, value } = ev.target;

        setTableModel(prevModel => ({
            ...prevModel,
            filters: {
                ...prevModel.filters,
                [name]: value || null
            }
        }));
    }

    useEffect(() => {
        handleCategories();
    }, []);
    return (
        <div className="flex w-full space-x-3 px-3 pb-2 rounded-lg justify-end">
            <select name="status" onChange={onFilterChange} className="px-2 py-1 border rounded text-sm">
                <option value=''>All Status</option>
                <option value='Open'>Open</option>
                <option value='Closed'>Closed</option>
            </select>

            <select name="risk" onChange={onFilterChange} className="px-2 py-1 border rounded text-sm">
                <option value=''>All Risk</option>
                {Risks.map(risk => <option key={risk.id} value={risk.id}>{risk.name}</option>)}
            </select>

            <select name="categoryId" onChange={onFilterChange} className="px-2 py-1 border rounded text-sm max-w-32">
                <option value=''>All Subcategory</option>
                {filterCategories !== null && filterCategories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)}
            </select>
        </div>
    )
}

export default VulnerabilityProjectFilter