import { Link } from 'react-router-dom'
import AssignmentIcon from '@mui/icons-material/Assignment';

const DocumentBadge = ({ document }) => {
    const styles = {
        badge: {
            color: `var(--primeBlack-color)`,
            alignItems: 'center',
            display: `inline-flex`,
            borderRadius: 'var(--borderRadius, 3px)',
            fontWeight: 'var(--fontBold)',
        }
    }

    return (
        <Link to={"/documents/" + document.id} style={styles.badge} className='hover:underline'>
            <AssignmentIcon />
            {document.title}
        </Link>
    )
}

export default DocumentBadge;
