import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './ProjectBadge.scss';

const ProjectBadge = ({ project }) => {
    return (
        <Link to={`/projects/${project.id}`} className="px-2 py-1 inline-flex bg-[#EFEFFF] text-gray-900 rounded border  text-xs">
            {project.name}
        </Link>
    )
}

ProjectBadge.propTypes = {
    project: PropTypes.object.isRequired
};

export default ProjectBadge;
