import PropTypes from 'prop-types';
import './ApprovalBadge.scss';

const ApprovalBadge = ({ activity }) => {
    return (
        <span className={`px-5 py-1 font-medium text-sm approval-${activity.approval_status}`}>
            {activity.approval_status}
   
        </span>
    )
}

ApprovalBadge.propTypes = {
    activity: PropTypes.object.isRequired
};

export default ApprovalBadge;
