import React from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from '@chakra-ui/react'

import DashboardWidget from './Widget'
import useFetch from 'hooks/useFetch'
import Loading from 'components/ui/Loading'
import RelativeDateFormatter from 'components/ui/RelativeDateFormatter'

const RecentReport = () => {
    const [report] = useFetch(`/reports?limit=5&orderColumn=insert_ts&orderDirection=desc`)

    if (!report) return <Loading />
    return (
        <DashboardWidget title="Recent Report (Last 5)">
            {report.length === 0 ?
                <p>No vulnerabilities to show.</p>
                :
                <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
                    <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                        <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                            <tr>
                                <th scope="col" className="px-2 py-2">Project</th>
                                <th scope="col" className="px-2 py-2">Report Type</th>
                                <th scope="col" className="px-2 py-2">Created</th>
                            </tr>
                        </thead>
                        <tbody>
                            {report.map((report, index) => (
                                <tr key={index} className="bg-white border-b border-gray-300 text-black font-medium">
                                    <td scope="row" className="px-2 py-2">
                                        <Link to={`/projects/${report.project_id}`}>
                                            <Tooltip label=''>
                                                <span className="text-black hover:underline">
                                                    {report.project_name}
                                                </span>
                                            </Tooltip>
                                        </Link>
                                    </td>
                                    <td scope="row" className="px-2 py-2">
                                        <button className='bg-gray-100 rounded px-2 py-1'>
                                            {report.file_mimetype === 'application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ?
                                                'Technical'
                                                : 'Executive'
                                            }
                                        </button>
                                    </td>
                                    <td scope="row" className="px-2 py-2">
                                        <RelativeDateFormatter date={report.insert_ts} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
        </DashboardWidget>
    )
}

export default RecentReport