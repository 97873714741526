import { useState } from 'react';
import { Badge } from '@chakra-ui/react';
import { actionCompletedToast } from 'components/ui/toast';

import DateManager from 'components/projects/chat/DateManager';
import ReactQuill from 'react-quill';

import UserAvatar from 'components/badges/UserAvatar';
import secureApiFetch from 'services/api';
import 'react-quill/dist/quill.snow.css';
import useFetch from 'hooks/useFetch';


const VulnerabilityChat = ({ parentType, parent, notes, reloadNotes, vulnerability }) => {
    const noteData = {
        'content': "",
        'parent_id': parent.id,
        'parent_type': parentType,
        'visibility': "public"
    }
    const [formData, setFormData] = useState(noteData)
    const [value, setValue] = useState('');

    const userData = JSON.parse(localStorage.getItem('user'))
    const user = parseInt(userData.id)

    const [members, updateMembers] = useFetch(`/projects/${vulnerability.project_id}/users`)

    const handleDescription = (value) => {
        setValue(value)
        setFormData({ ...formData, "content": value })
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        if (value.trim() === '') {
            return;
        }
        await secureApiFetch(`/notes`, {
            method: 'POST',
            body: JSON.stringify(formData)
        }).then(() => {
            actionCompletedToast(`The Message has been posted..`);
            reloadNotes()
            updateMembers()
        })
            .finally(() => {
                setFormData(noteData)
                setValue('')
            })
    }

    return (
        <div className="container w-full">
            <button onClick={() => reloadNotes()} className='bg-[#3A3D89] pr-2 pl-3 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>Reload Chat </button>

            <div className="flex  border mt-2 w-full">
                <div className="w-2/3 border-r p-2">
                    <label className='font-medium'>Discussion</label>

                    <div style={{ display: 'felx', flexDirection: 'column' }} className="flex flex-col justify-start mt-2 w-full  rounded p-2 overflow-auto container px-5 my-2 min-h-72 max-h-[30rem] ">
                        {notes.length > 0 && notes.slice().reverse().map((cmt, index) => (
                            <div key={index}>
                                {userData && user === cmt.user_id ?
                                    <div className="flex justify-end mb-4">
                                        <div className="mr-2 py-1 px-2 min-w-72 max-w-[30rem] bg-blue-100 rounded-bl rounded-tr rounded-tl text-white text-xs">
                                            <div className='flex justify-between items-center space-x-4'>
                                                <label className='text-[#3A3D89] text-xs font-medium'>
                                                    me
                                                </label>
                                                <span className='text-[10px] font-medium'>
                                                    <DateManager date={cmt.insert_ts} />
                                                </span>
                                            </div>
                                            <p className='text-black font-medium'>
                                                {cmt.content && (
                                                    <ReactQuill
                                                        theme="snow"
                                                        className="custom-quill"
                                                        value={cmt.content || 'NA'}
                                                        modules={{ toolbar: null }}
                                                        readOnly={true}
                                                    />
                                                )}

                                            </p>
                                        </div>
                                    </div>
                                    :
                                    <div className="flex justify-start mb-4">
                                        <div className="ml-2 py-1 px-2 bg-[#edeeff] min-w-72 max-w-[30rem] rounded-br rounded-tr rounded-tl text-white">
                                            <div className='flex justify-between items-center space-x-4'>
                                                <label className='text-[#3A3D89] text-xs font-medium '>
                                                    {cmt.user_name}
                                                </label>
                                                <span className='text-gray-600 text-[10px] font-medium'>
                                                    <DateManager date={cmt.insert_ts} />
                                                </span>
                                            </div>
                                            <p className='text-xs text-black font-medium'>
                                                {cmt.content && (
                                                    <ReactQuill
                                                        theme="snow"
                                                        className="custom-quill"
                                                        value={cmt.content || 'NA'}
                                                        modules={{ toolbar: null }}
                                                        readOnly={true}
                                                    />
                                                )}

                                            </p>
                                        </div>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>

                    <form onSubmit={handleSubmit} className='w-full'>
                        <div className='w-full'>
                            <ReactQuill
                                theme="snow"
                                name="description"
                                value={value}
                                modules={{ toolbar: null }}
                                onChange={handleDescription}
                                placeholder="Write something here..."
                            />
                        </div>
                        <div>
                            <button type='submit' className='bg-[#3A3D89] pr-2 pl-3 py-1.5 rounded text-white space-x-1 flex items-center justify-center text-sm'>
                                Send
                            </button>
                        </div>
                    </form>
                </div>
                <div className="w-1/3 p-2 ">
                    <label className='font-medium'>Members</label>
                    <div style={{ display: 'felx', flexDirection: 'column' }} className="flex flex-col justify-start mt-2 rounded p-2 overflow-auto container px-5 my-2 min-h-44 max-h-[30rem] ">
                        {members && members.map((user, index) => (
                            <ul key={index}>
                                <li className='my-2 flex space-x-2 items-center'>
                                    <UserAvatar email={user.email} />
                                    <label className='font-medium mx-1'> {user.full_name} </label>
                                    <label><Badge colorScheme="red">{user.role}</Badge>  </label>
                                </li>
                            </ul>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VulnerabilityChat;