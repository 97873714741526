import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import MailLink from 'components/ui/MailLink';
import NoResultsTableRow from 'components/ui/tables/NoResultsTableRow';
import TelephoneLink from 'components/ui/TelephoneLink';
import secureApiFetch from 'services/api';
import { actionCompletedToast, errorToast } from 'components/ui/toast';

function ContactsClient({ contacts, fetchContacts, onFormSubmit, contact, onContactFormChange, }) {
    
    const handleDelete = (event, contactId) => {
        event.preventDefault()
        secureApiFetch(`/contacts/${contactId}`, {
            method: 'DELETE'
        })
            .then((res) => {
                if (res.ok) {
                    if (res.status === 204) {
                        actionCompletedToast("The contact has been deleted.");
                    } else {
                        errorToast("Unexpected response status: " + res.status);
                    }
                    fetchContacts();
                } else {
                    errorToast("The contact could not be deleted. Status: " + res.status);
                }
            })
            .catch(err => {
                console.error("Error during deletion:", err);
                errorToast("An error occurred while deleting the contact.");
            });
    }
    return (
        <div>
            {contacts && <>
                <form onSubmit={onFormSubmit}>
                    <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
                        <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                            <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                                <tr>
                                    <th scope="col" className="px-4 py-2">Kind</th>
                                    <th scope="col" className="px-4 py-2">Name</th>
                                    <th scope="col" className="px-4 py-2">Role</th>
                                    <th scope="col" className="px-4 py-2">Email</th>
                                    <th scope="col" className="px-4 py-2">Phone</th>
                                    <th scope="col" className="px-4 py-2">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="bg-white border-b border-gray-300 text-black font-medium">
                                    <td className='px-2 py-1'>
                                        <select name="kind" onChange={onContactFormChange} value={contact.kind || ""} isRequired>
                                            <option value="general">General</option>
                                            <option value="technical">Technical</option>
                                            <option value="billing">Billing</option>
                                        </select>
                                    </td>
                                    <td className='px-2 py-1'>
                                        <input type="text" name="name" onChange={onContactFormChange} value={contact.name || ""} className='px-2 py-1 border rounded text-sm font-medium focus:outline-none' isRequired />
                                    </td>

                                    <td className='px-2 py-2'>
                                        <input type="text" name="role" onChange={onContactFormChange} value={contact.role || ""} className='px-2 py-1 border rounded text-sm font-medium focus:outline-none' />
                                    </td>

                                    <td className='px-2 py-1'>
                                        <input type="email" name="email" onChange={onContactFormChange} value={contact.email || ""} className='px-2 py-1 border rounded text-sm font-medium focus:outline-none' isRequired />
                                    </td>

                                    <td className='px-2 py-1'>
                                        <input type="tel" name="phone" onChange={onContactFormChange} value={contact.phone || ""} className='px-2 py-1 border rounded text-sm font-medium focus:outline-none' />
                                    </td>

                                    <td className='px-2 py-1'>
                                        <button type="submit" className='bg-[#3A3D89] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                                            Add
                                        </button>
                                    </td>
                                </tr>
                                {0 === contacts.length && <NoResultsTableRow numColumns={6} />}
                                {contacts.map(contact => <>
                                    <tr key={contact.id} className="bg-white border-b border-gray-300 text-black font-medium">
                                        <td className='px-2 py-1'>
                                            {contact.kind}
                                        </td>
                                        <td className='px-2 py-1'>
                                            {contact.name}
                                        </td>
                                        <td className='px-2 py-1'>
                                            {contact.role}
                                        </td>
                                        <td className='px-2 py-1'>
                                            <MailLink email={contact.email} />
                                        </td>
                                        <td className='px-2 py-1'>
                                            <TelephoneLink number={contact.phone} />
                                        </td>
                                        <td className='px-2 py-1'>
                                            <button onClick={(event) => handleDelete(event, contact.id)}>
                                                <DeleteIcon className='text-[#EB1F36]' />
                                            </button>
                                        </td>
                                    </tr>
                                </>)}
                            </tbody>
                        </table>
                    </div>
                </form>
            </>}
        </div>
    )
}

export default ContactsClient