import { Link } from "react-router-dom";
import { Stack } from "@chakra-ui/react";
import { Tooltip } from '@chakra-ui/react'

import Loading from "components/ui/Loading";
import useFetch from "hooks/useFetch";
import DashboardWidget from "./Widget";

const ActiveProjectsWidget = () => {
    const [projects] = useFetch(`/projects?status=active&page=0&limit=5&orderDirection=DESC`)

    if (!projects) return <Loading />

    return <DashboardWidget title="Recent Projects (Last 5)">

        {projects.length === 0 ?
            <p>No projects to show.</p>
            :
            <Stack>
                <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
                    <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                        <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                            <tr>
                                <th scope="col" className="px-4 py-2">Name</th>
                                <th scope="col" className="px-2 py-2">Client</th>
                            </tr>
                        </thead>
                        <tbody>
                            {projects.map((project, index) => {
                                return (
                                    <tr key={index} className="bg-white border-b border-gray-300 text-black font-light">
                                        <th scope="row" className="px-2 py-2 ">
                                            <Link to={`/projects/${project.id}`}>
                                                <Tooltip label={project.project_status}>
                                                    <span className="hover:text-[#3A3D89] hover:underline">
                                                        {project.name}
                                                    </span>
                                                </Tooltip>
                                            </Link>
                                        </th>
                                        <th scope="row" className="px-2 py-2 ">
                                            <Link to={`/clients/${project.client_id}`}>
                                                <span className="hover:text-[#3A3D89] hover:underline">
                                                    {project.client_name}
                                                </span>
                                            </Link>

                                        </th>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </Stack>

        }
    </DashboardWidget>
}

export default ActiveProjectsWidget;
