import React, { useState } from 'react';
import secureApiFetch from '../../services/api';
import ImportSampleData from './ImportData';

const ImportForm = () => {

    const [importResponse, setImportResponse] = useState(null);
    const [importButtonDisabled, setImportButtonDisabled] = useState(true);
    const [downloadUrl, setDownloadUrl] = useState(null);

    const decodeBase64 = (base64) => {
        const binaryString = atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    };
    const handleDownload = () => {
        const bytes = decodeBase64(ImportSampleData);
        const blob = new Blob([bytes], { type: 'application/zip' });
        const url = URL.createObjectURL(blob);
        setDownloadUrl(url);
    };

    const handleUploadClick = ev => {
        ev.preventDefault();

        const resultFileInput = document.getElementById('importFile');
        const formData = new FormData();
        formData.append('importFile', resultFileInput.files[0]);
        secureApiFetch('/system/data', {
            method: 'POST',
            body: formData
        })
            .then(resp => resp.json())
            .then(resp => {
                setImportResponse(resp);
            })
            .catch(err => console.error(err));
    }

    const onImportFileChange = ev => {
        ev.preventDefault();
        const selectedFiles = ev.target.files;

        setImportButtonDisabled(selectedFiles.length === 0);
    }

    return (
        <div>
            <h3 className="font-medium text-lg text-gray-700">Import system data</h3>
            <form>
                <div className="p-6 bg-gray-100 rounded-lg">
                    Notes:
                    <ul className="list-disc list-inside text-gray-800">
                        <li className="mb-2">Everything on the file will be attempted to be imported.</li>
                        <li className="mb-2">If there is an error the import process will continue resulting in a partial import.</li>
                        <li className="mb-2">If there are missing attributes, it will attempt to use defaults instead.</li>
                    </ul>
                </div>

                <div className='flex items-center justify-between'>
                    <h3>Import file</h3>
                    <div>
                        <button onClick={handleDownload}>
                            <a href={downloadUrl} download="sample-import-data.zip"
                                className='bg-[#3A3D89] px-2 py-0.5 mt-1 rounded text-white text-xs'>
                                Download ZIP Sample File
                            </a>
                        </button>
                    </div>
                </div>
                <input type="file" id='importFile' onChange={onImportFileChange} accept=".json,.js,application/json,text/json" className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
                <p className="mt-1 text-sm text-gray-500 " id="file_input_help">Supported File: JSON</p>

                <button disabled={importButtonDisabled} onClick={handleUploadClick} className='bg-[#3A3D89] px-4 py-2 w-20 rounded text-white text-sm'>
                    Import
                </button>
            </form>

            {importResponse &&
                <div>
                    <h4>Import completed</h4>

                    {importResponse.errors.length > 0 && <ul>
                        {importResponse.errors.map(error => <li style={{ color: 'orange' }}>
                            {error}
                        </li>)}
                    </ul>}

                    {importResponse.results.length > 0 && <>
                        <p>The number of imports per category are:</p>
                        <ul>
                            {importResponse.results.map(entityResult => {
                                return <li>{entityResult.count} {entityResult.name} ({entityResult.errors.length} errors)</li>
                            })}
                        </ul>
                    </>}
                </div>
            }
        </div>
    )
}

export default ImportForm;
