import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './Pagination.scss';

const PaginationVuln = ({ page, total, handleNext, handlePrev }) => {

    return (
        <div className='pagination'>
            <button className='text-gray-500' onClick={handlePrev}>
                <KeyboardArrowLeftIcon />
            </button>
            <label>{page} <span className='opacity-25'>|</span> {total} </label>
            <button className='text-gray-500' onClick={handleNext}>
                <KeyboardArrowRightIcon />
            </button>
        </div>
    )
}

export default PaginationVuln