import { Link } from "react-router-dom";
import { Tooltip } from '@chakra-ui/react'
import Badge from "components/badges/Badge";
import useFetch from "hooks/useFetch";
import DashboardWidget from "./Widget";

const RecentActivityWidget = () => {
    const [auditLog] = useFetch('/auditlog?limit=5');

    return <DashboardWidget title="Recent activity (Last 5)">

        {auditLog && auditLog.length > 0 ?
            <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
                <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                    <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                        <tr>
                            <th scope="col" className="px-2 py-2">Action</th>
                            <th scope="col" className="px-2 py-2">User</th>
                            <th scope="col" className="px-2 py-2">Date/Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {auditLog.map(log => (
                            <tr key={log.id} className="bg-white border-b border-gray-300 text-black font-medium">
                                <td scope="row" className="px-2 py-2 "><Badge>{log.action}</Badge></td>
                                <td scope="row" className="px-2 py-2">
                                <Tooltip label={log.user_name}>
                                    {log.user_name
                                        ? <Link to={`/users/${log.user_id}`} className="hover:underline font-medium text-black">
                                            {log.user_name.substring(0, 15)}...
                                        </Link>
                                        : '-'}

                                </Tooltip>
                                </td>
                                <td scope="row" className="px-2 py-2 ">{log.insert_ts}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            :
            <p>No activity to show.</p>
        }
    </DashboardWidget>
}

export default RecentActivityWidget;
