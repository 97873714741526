import { KeycloakConfig } from "keycloak-js";
let domain = '';
if (typeof window !== 'undefined') {
    const hostname = window.location.hostname.toLowerCase();
    domain = hostname.split('.')[0];
}
let Configuration = {
    isSecureTransportEnabled: (): string => window.env.REACT_APP_SECURE_TRANSPORT_ENABLED || 'true',
    getClientUrl:():string=>window.env.REACT_APP_CLIENT_URL,
    getDefaultApiUrl: (): string => window.env.REACT_APP_DEFAULT_API_URL || 'https://api.prosecurity.work',
    getNotificationsServiceHostPort: (): string => window.env.REACT_APP_NOTIFICATIONS_SERVICE_HOST_PORT || 'agent-projects.prosecurity.work',
    getAgentServiceHostPort: (): number => window.env.REACT_APP_AGENT_SERVICE_HOST_PORT || 'agent-projects.prosecurity.work',
    getContextPath: (): string => window.env.REACT_APP_CONTEXT_PATH || '/',
    getLogoUrl: (): string => window.env.REACT_APP_LOGO_URL || '/logo-name.svg',
    getAppVersion: (): string => window.env.APP_VERSION || process.env.PATH || 'Default 1.0.0',
    getKeycloakConfig: (): KeycloakConfig => window.env.REACT_APP_KEYCLOAK_CONFIG || {
        url: 'https://auth.prosecurity.work',
        realm: 'prosecurity',
        clientId: 'web-client'
    }
};

switch (domain) {
    case 'projects':
        Configuration = {
            isSecureTransportEnabled: (): string => window.env.REACT_APP_SECURE_TRANSPORT_ENABLED || 'true',
            getClientUrl:():string=>window.env.REACT_APP_CLIENT_URL_PROJECT,
            getDefaultApiUrl: (): string => window.env.REACT_APP_DEFAULT_API_URL_PROJECT || 'https://api.prosecurity.work',
            getNotificationsServiceHostPort: (): string => window.env.REACT_APP_NOTIFICATIONS_SERVICE_HOST_PORT_PROJECT || 'agent-projects.prosecurity.work',
            getAgentServiceHostPort: (): number => window.env.REACT_APP_AGENT_SERVICE_HOST_PORT_PROJECT || 'agent-projects.prosecurity.work',
            getContextPath: (): string => window.env.REACT_APP_CONTEXT_PATH_PROJECT || '/',
            getLogoUrl: (): string => window.env.REACT_APP_LOGO_URL_PROJECT || '/secureit.png',
            getAppVersion: (): string => window.env.APP_VERSION || process.env.PATH || 'Default 1.0.0',
            getKeycloakConfig: (): KeycloakConfig => window.env.REACT_APP_KEYCLOAK_CONFIG_PROJECT || {
                url: 'https://auth.prosecurity.work',
                realm: 'prosecurity',
                clientId: 'web-client'
            }
        };
        break;

    case 'uk-projects':
        Configuration = {
            isSecureTransportEnabled: (): string => window.env.REACT_APP_SECURE_TRANSPORT_ENABLED || 'true',
            getClientUrl:():string=>window.env.REACT_APP_CLIENT_URL_UK,
            getDefaultApiUrl: (): string => window.env.REACT_APP_DEFAULT_API_URL_UK || 'https://api.prosecurity.work',
            getNotificationsServiceHostPort: (): string => window.env.REACT_APP_NOTIFICATIONS_SERVICE_HOST_PORT_UK || 'agent-projects.prosecurity.work',
            getAgentServiceHostPort: (): number => window.env.REACT_APP_AGENT_SERVICE_HOST_PORT_UK_PROJECT || 'agent-projects.prosecurity.work',
            getContextPath: (): string => window.env.REACT_APP_CONTEXT_PATH_UK || '/',
            getLogoUrl: (): string => window.env.REACT_APP_LOGO_URL_UK || '/logo-name.svg',
            getAppVersion: (): string => window.env.APP_VERSION || process.env.PATH || 'Default 1.0.0',
            getKeycloakConfig: (): KeycloakConfig => window.env.REACT_APP_KEYCLOAK_CONFIG_UK || {
                url: 'https://auth.prosecurity.work',
                realm: 'prosecurity',
                clientId: 'web-client'
            }
        };
        break;

    case 'us-projects':
        Configuration = {
            isSecureTransportEnabled: (): string => window.env.REACT_APP_SECURE_TRANSPORT_ENABLED || 'true',
            getClientUrl:():string=>window.env.REACT_APP_CLIENT_URL_US,
            getDefaultApiUrl: (): string => window.env.REACT_APP_DEFAULT_API_URL_US || 'https://api.prosecurity.work',
            getNotificationsServiceHostPort: (): string => window.env.REACT_APP_NOTIFICATIONS_SERVICE_HOST_PORT_US || 'agent-projects.prosecurity.work',
            getAgentServiceHostPort: (): number => window.env.REACT_APP_AGENT_SERVICE_HOST_PORT_US || 'agent-projects.prosecurity.work',
            getContextPath: (): string => window.env.REACT_APP_CONTEXT_PATH_US || '/',
            getLogoUrl: (): string => window.env.REACT_APP_LOGO_URL_US || '/logo-name.svg',
            getAppVersion: (): string => window.env.APP_VERSION || process.env.PATH || 'Default 1.0.0',
            getKeycloakConfig: (): KeycloakConfig => window.env.REACT_APP_KEYCLOAK_CONFIG_US || {
                url: 'https://auth.prosecurity.work',
                realm: 'prosecurity',
                clientId: 'web-client'
            }
        };
        break;
    case 'in-projects':
        Configuration = {
            isSecureTransportEnabled: (): string => window.env.REACT_APP_SECURE_TRANSPORT_ENABLED || 'true',
            getClientUrl:():string=>window.env.REACT_APP_CLIENT_URL_IN,
            getDefaultApiUrl: (): string => window.env.REACT_APP_DEFAULT_API_URL_IN || 'https://api.prosecurity.work',
            getNotificationsServiceHostPort: (): string => window.env.REACT_APP_NOTIFICATIONS_SERVICE_HOST_PORT_IN || 'agent-projects.prosecurity.work',
            getAgentServiceHostPort: (): number => window.env.REACT_APP_AGENT_SERVICE_HOST_PORT_IN || 'agent-projects.prosecurity.work',
            getContextPath: (): string => window.env.REACT_APP_CONTEXT_PATH_IN || '/',
            getLogoUrl: (): string => window.env.REACT_APP_LOGO_URL_IN || '/logo-name.svg',
            getAppVersion: (): string => window.env.APP_VERSION || process.env.PATH || 'Default 1.0.0',
            getKeycloakConfig: (): KeycloakConfig => window.env.REACT_APP_KEYCLOAK_CONFIG_IN || {
                url: 'https://auth.prosecurity.work',
                realm: 'prosecurity',
                clientId: 'web-client'
            }
        };
        break;

    default:
        break;
}

export default Configuration;
