import React from "react";

import PageTitle from "components/logic/PageTitle";
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';

import Breadcrumb from "../ui/Breadcrumb";
import Title from "../ui/Title";
import ExportForm from "./ExportForm";
import { Link } from "react-router-dom";

const ExportPage = () => {
    return (
        <div>
            <div className='heading'>
                <Breadcrumb>
                    <Link to='/system'> System </Link>
                    <div>Export Data</div>
                </Breadcrumb>
            </div>
            <PageTitle value="Export data" />
            <Title title="Data Export" icon={<ImportExportOutlinedIcon />} />

            <ExportForm />
        </div>
    )
};

export default ExportPage;
