import React, { useCallback, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { ButtonGroup, Center, HStack, useDisclosure } from "@chakra-ui/react";
import { actionCompletedToast, errorToast } from "components/ui/toast";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, } from '@chakra-ui/react'
import { ReactComponent as TargetIcon } from 'images/icons/target.svg';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';
import Pagination from "components/layout/Pagination";
import RestrictedComponent from "components/logic/RestrictedComponent";
import TargetBadge from "components/target/TargetBadge";
import CreateButton from "components/ui/buttons/Create";
import Tags from "components/ui/Tags";
import useQuery from "hooks/useQuery";

import secureApiFetch from "../../services/api";
import Loading from "../ui/Loading";
import NoResultsTableRow from "../ui/tables/NoResultsTableRow";
import UploadTargets from "components/target/UploadTargets";
import TargetFormModal from "components/target/TargetForm";

const ProjectTargets = ({ project }) => {
    const query = useQuery();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: targedOpen, onOpen: targertHandleOpen, onClose: targetClose } = useDisclosure()
    const urlPageNumber = query.get('page') !== null ? parseInt(query.get('page')) : 1;
    const [pageNumber, setPageNumber] = useState(urlPageNumber);

    const [numberPages, setNumberPages] = useState(1);
    const [targets, setTargets] = useState([]);

    const onDeleteButtonClick = (ev, targetId) => {
        ev.preventDefault();
        const confirmDelete = window.confirm('Are you sure you want to delete this target?');
        if (!confirmDelete) {
            return;
        }
        secureApiFetch(`/targets/${targetId}`, { method: 'DELETE' })
            .then((res) => {
                if (res.status === 200) {
                    reloadTargets();
                    actionCompletedToast(`Target ${targetId} has been successfully deleted.`);
                } else {
                    throw new Error(`Failed to delete target ${targetId}. Status: ${res.status}`);
                }
            })
            .catch((error) => {
                console.error('Error deleting target:', error);
                errorToast(`Failed to delete target ${targetId}. Please try again later.`);
            });
    }
    const onTargetFormSaved = () => {
        reloadTargets();
    }
    const reloadTargets = useCallback(() => {
        setTargets([]);

        secureApiFetch(`/targets?projectId=${project.id}`, { method: 'GET' })
            .then((resp) => {
                if (resp.headers.has('X-Page-Count')) {
                    setNumberPages(resp.headers.get('X-Page-Count'))
                }
                return resp.json()
            })
            .then(data => {
                if (Array.isArray(data)) {
                    setTargets(data);
                } else {
                    throw new Error('Invalid data format');
                }
            });
    }, [project]);
    const onPrevPageClick = () => {
        setPageNumber(pageNumber - 1);
    }
    const onNextPageClick = () => {
        setPageNumber(pageNumber + 1);
    }

    useEffect(() => {
        reloadTargets()
    }, [reloadTargets])


    return <section className="-mt-1">
        <h4 className='text-sm'>
            <AdsClickOutlinedIcon sx={{ color: '#3A3D89' }} />Targets
            <RestrictedComponent roles={['administrator', 'superuser', 'user']}>
                <ButtonGroup>
                    <CreateButton onClick={targertHandleOpen}>Add target...</CreateButton>
                    <UploadTargets reloadTargets={reloadTargets} project={project} isOpen={isOpen} onClose={onClose} />
                    <button onClick={onOpen} type="button" className='bg-[#3A3D89] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-xs'>
                        Upload Targets
                    </button>
                </ButtonGroup>
            </RestrictedComponent>
        </h4>
        {!targets ? <Loading /> :
            <>
                {numberPages > 1 && <Center>
                    <Pagination page={pageNumber - 1} total={numberPages} handlePrev={onPrevPageClick} handleNext={onNextPageClick} />
                </Center>}
                <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded mt-2">
                    <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                        <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                            <tr>
                                <th scope="col" className="px-4 py-2">Name</th>
                                <th scope="col" className="px-4 py-2 ">Sub-target</th>
                                <th scope="col" className="px-4 py-2">Kind</th>
                                <th scope="col" className="px-4 py-2">Vulnerable?</th>
                                <th scope="col" className="px-4 py-2">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {targets.length === 0 && <NoResultsTableRow numColumns={4} />}
                            {null !== targets && 0 !== targets.length && targets.map((target, index) => {
                                return (
                                    <tr key={index} className="bg-white border-b even:bg-gray-50 border-gray-300 text-black font-light">

                                        <th scope="row" className="px-4 py-1 ">
                                            {target.parent_id === null &&
                                                <HStack>
                                                    <Link to={`/targets/${target.id}`}>
                                                        <TargetBadge name={target.name} />
                                                    </Link>
                                                    <button onClick={() => { navigator.clipboard.writeText(`${target.name}`) }}>
                                                        <ContentCopyOutlinedIcon fontSize="" className="text-[#3A3D89] text-sm" />
                                                    </button>
                                                </HStack>
                                            }
                                            {target.parent_id !== null &&
                                                <>{target.parent_name ?? '-'} </>
                                            }

                                        </th>
                                        <th scope="row" className="px-4 py-1 font-medium">
                                            {target.parent_id !== null
                                                ? <>
                                                    <Link to={`/targets/${target.id}`}><TargetBadge name={target.name} /></Link>
                                                    <button onClick={() => { navigator.clipboard.writeText(`${target.name}`) }}>
                                                        <ContentCopyOutlinedIcon fontSize="" className="text-[#3A3D89] text-sm mx-1" />
                                                    </button>
                                                </>
                                                : '-'
                                            }
                                        </th>
                                        <th scope="row" className="px-4 py-1 font-medium">
                                            {target.kind} <Tags values={target.tags} />
                                        </th>
                                        <th scope="row" className="px-4 py-1 font-medium">
                                            {target.num_vulnerabilities > 0 ? `Yes (${target.num_vulnerabilities} vulnerabilities found)` : "No"}
                                        </th>
                                        <th scope="row" className="px-4 py-1 font-medium">
                                            <RestrictedComponent roles={['administrator', 'superuser', 'user']}>
                                                <button onClick={ev => onDeleteButtonClick(ev, target.id)}>
                                                    <DeleteIcon className='text-red-700' />
                                                </button>
                                            </RestrictedComponent>
                                        </th>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        }
        <>
            <Modal isOpen={targedOpen} onClose={targetClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <HStack>
                            <TargetIcon style={{ width: '24px' }} /> <h4>New target details</h4>
                        </HStack>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <TargetFormModal project={project} targets={targets} isOpen={targedOpen} onSubmit={onTargetFormSaved} onClose={targetClose} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    </section>
}

export default ProjectTargets;
