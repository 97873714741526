import React from 'react'
import NoResultsTableRow from 'components/ui/tables/NoResultsTableRow';
import LoadingTableRow from 'components/ui/tables/LoadingTableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import secureApiFetch from 'services/api';
import { actionCompletedToast } from 'components/ui/toast';

function CategoryQuestion({ questions, updateQuestions }) {
    const handleDelete = async (questionId) => {
        try {
            await secureApiFetch(`/questions/${questionId}`, {
                method: 'DELETE',
            });
            updateQuestions((prevQuestions) => prevQuestions.filter(q => q.id !== questionId));
            actionCompletedToast(`The Record has been Deleted.`);
        } catch (error) {
            console.error('Error deleting question:', error);
        }
    };

    return (
        <React.Fragment>
            <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
                <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                    <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                        <tr>
                            <th scope="col" className="px-4 py-3">ID</th>
                            <th scope="col" className="px-2 py-2">Category ID</th>
                            <th scope="col" className="px-2 py-2">Title</th>
                            <th scope="col" className="px-2 py-2">Type</th>
                            <th scope="col" className="px-2 py-2">Options</th>
                            <th scope="col" className="px-2 py-2">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {null === questions && <LoadingTableRow numColumns={6} />}
                        {null !== questions && 0 === questions.length && <NoResultsTableRow numColumns={6} />}
                        {questions && questions.map((question, index) =>
                            <tr key={index} className="bg-white border-b border-gray-300 text-black font-medium">
                                <th scope="row" className="px-4 py-1 ">
                                    <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                        {question.id}
                                    </span>
                                </th>

                                <th scope="row" className="px-4 py-1 ">
                                    {question.category_id}
                                </th>

                                <th scope="row" className="px-4 py-1 ">
                                    {question.title}
                                </th>

                                <th scope="row" className="px-4 py-1 ">
                                    <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                        {question.type}
                                    </span>
                                </th>

                                <th scope="row" className="px-4 py-1 ">
                                    {JSON.parse(question.options).map((opt, index) => index > 0 ? ` ${opt}` : opt)}
                                </th>

                                <th scope="row" className="px-4 py-1 ">
                                    <button onClick={() => handleDelete(question.id)}>
                                        <DeleteIcon className='text-red-600' fontSize='small' />
                                    </button>
                                </th>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}

export default CategoryQuestion