import { useCallback, useEffect } from "react";
import isInputElement from "utilities/domUtils";
import CloseIcon from '@mui/icons-material/Close';
import './ModalDialog.scss';

const ModalDialog = ({ title, children, visible, onModalClose, style = {} }) => {

    const closeShortcutsPopup = () => {
        onModalClose();
    }

    const onKeyDownListener = useCallback((ev) => {
        if (isInputElement(document.activeElement)) {
            return;
        }

        if (ev.key === 'Escape') {
            ev.preventDefault();

            onModalClose();
        }
    }, [onModalClose]);

    useEffect(() => {
        document.addEventListener('keydown', onKeyDownListener);
        return () => {
            document.removeEventListener('keydown', onKeyDownListener);
        };
    }, [onKeyDownListener]);

    if (!visible) return null;

    return (
        <div>
            <div style={style} className="KeyboardShortcutsPopup items-center justify-center" >
                <div className="flex text-white justify-between ">
                    <h3 className="flex text-white">{title}</h3>
                    <button onClick={closeShortcutsPopup}>
                        <CloseIcon />
                    </button>
                </div>
                <div className="dflex">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default ModalDialog;
