import { Link } from 'react-router-dom'
import GppMaybeIcon from '@mui/icons-material/GppMaybe';

export default function VulnerabilityBadge({ vulnerability }) {
    const styles = {
        badge: {
            color: `var(--primBlack-color)`,
            alignItems: 'center',
            display: `inline-flex`,
            borderRadius: 'var(--borderRadius, 3px)',
            fontWeight: 'var(--fontBold)',
        }
    }

    return (
        <Link to={`/vulnerabilities/${vulnerability.id}`} className='hover:underline' style={styles.badge}>
            <GppMaybeIcon className='text-[#3a3a3c]'/>
            {vulnerability.summary}
        </Link>
    )
}
