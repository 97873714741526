import React, { useEffect, useState } from 'react';
import { AlertIcon, Alert } from '@chakra-ui/react';

import DeleteIcon from '@mui/icons-material/Delete';
import LoadingTableRow from "components/ui/tables/LoadingTableRow";
import UserRoleBadge from "components/badges/UserRoleBadge";
import UserLink from 'components/users/Link'

import secureApiFetch from '../../services/api';
import UserAvatar from '../badges/UserAvatar';
import useFetch from '../../hooks/useFetch';

function MembersClient({ usersClient, usersUpdated, clientId }) {
  const [users] = useFetch(`/users`);
  const [availableUsers, setAvailableUsers] = useState([]);

  const handleOnClick = ev => {
    ev.preventDefault();
    let confirm = window.confirm('Are you sure you want to add this User to the Client?')
    if (confirm) {
      const userId = document.getElementById('userId').value;
      const userData = { user_id: userId, client_id: clientId, };
      secureApiFetch(`/clients/users`, {
        method: 'POST',
        body: JSON.stringify(userData)
      }).then(() => {
        usersUpdated()
      })
    }
  }
  const handleDelete = (user) => {
    secureApiFetch(`/clients/users/${user}`, {
      method: 'DELETE'
    }).then(() => {
      usersUpdated()
    })
  }

  useEffect(() => {

    if (usersClient && users && users.length > 0) {
      const userIds = usersClient.reduce((list, user) => [...list, user.user_id], []);
      setAvailableUsers(users.filter(user => !userIds.includes(user.id)));
    }
  }, [users, usersClient]);

  return (
    <React.Fragment>
      {availableUsers && availableUsers.length > 0 ? (
        <form className='flex items-center space-x-2'>
          <label>
            <span className="font-medium text-xs">Select User</span>
            <select id="userId" className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'>
              {availableUsers.map((user, index) => (
                <option key={index} value={user.id}>
                  {user.full_name}
                </option>
              ))}
            </select>
          </label>
          <div onClick={handleOnClick} className="flex justify-end max-w-32">
            <button type='submit' className='bg-[#3A3D89] px-4 py-2 rounded text-white min-w-28 text-sm'>
              Add as user
            </button>
          </div>
        </form>
      ) : (
        <Alert status="info"><AlertIcon /> All users have been added to the project. </Alert>
      )}
      <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded mt-2">
        <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
          <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
            <tr>
              <th scope="col" className="px-4 py-2"></th>
              <th scope="col" className="px-4 py-2 text-center">User Id</th>
              <th scope="col" className="px-4 py-2">User Name</th>
              <th scope="col" className="px-4 py-2">User Email</th>
              <th scope="col" className="px-4 py-2">User Role</th>
              <th scope="col" className="px-4 py-2 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {null === usersClient &&
              <LoadingTableRow numColumns={6} />
            }
            {usersClient && usersClient.length > 0 ? (
              usersClient.map((user, index) => {
                // const currentUser = users && users.find(u => u.id === user.user_id)
                return (
                  <tr key={index} className="bg-white border-b border-gray-300 text-black font-medium">
                    <td className="px-2 py-2 "><UserAvatar email={user ? user.email : "Name is not avaibale"} /></td>
                    <td className="px-2 py-2 text-center">{user.user_id} </td>
                    <td className="px-2 py-2 "><UserLink userId={user.user_id}>{user ? user.full_name : "Name is not avaibale"}</UserLink></td>
                    <td className="px-2 py-2 "> {user ? user.email : "Name is not avaibale"}</td>
                    <td className="px-2 py-2 "><UserRoleBadge role={user ? user.role : "Name is not avaibale"} /></td>
                    <td className="px-2 py-2 text-center">
                      <button onClick={() => handleDelete(user.membership_id)}>
                        <DeleteIcon className='text-[#EB1F36]' />
                      </button>
                    </td>
                  </tr>
                )
              }
              ))
              : (
                <div>
                  {null !== usersClient && 0 === usersClient.length &&
                    <tr>
                      <td colSpan={6} className="px-2 py-2 font-medium text-sm">No users available for this client.</td>
                    </tr>
                  }
                </div>
              )}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}

export default MembersClient;