import { useEffect, useCallback, useState } from "react";
import useFetch from "hooks/useFetch";
import Risks from "models/Risks";
import { useLocation, useNavigate } from "react-router-dom";
import secureApiFetch from "services/api";

const VulnerabilityFilters = ({ tableModel, tableModelSetter: setTableModel, showProjectFilter = true }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [projects] = useFetch('/projects?status=active');
    const [filterCategories,setFilterCategories] = useState([])

    const handleCategories = ()=>{
        secureApiFetch('/vulnerabilities/categories?parentsOnly=0',{
            method:'GET'
        })
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            const filteredData = data.filter((data)=>data.parent_id !== null)
            setFilterCategories(filteredData)
        })
    }

    const parseHashParams = useCallback(() => {
        const hash = location.hash.substring(1);
        const query = new URLSearchParams(hash);

        const filters = {
            projectId: query.get("projectId") || "",
            status: query.get("status") || "",
            risk: query.get("risk") || "",
            categoryId: query.get("categoryId") || "",
        };
        setTableModel((prevModel) => ({
            ...prevModel,
            filters
        }));
    }, [location.hash, setTableModel]);

    const updateHashParams = useCallback((newFilters) => {
        const query = new URLSearchParams();
        Object.entries(newFilters).forEach(([key, value]) => {
            if (value) {
                query.set(key, value);
            }
        });
        navigate({
            hash: `#${query.toString()}`,
        }, { replace: true });
    }, [navigate]);

    const onFilterChange = (ev) => {
        const { name, value } = ev.target;
        const newFilters = { ...tableModel.filters, [name]: value };
        setTableModel({ ...tableModel, filters: newFilters });
        updateHashParams(newFilters);
    };

    const clearFilters = () => {
        const defaultFilters = {
            projectId: "",
            status: "",
            risk: "",
            categoryId: "",
        };
        setTableModel((prevModel) => ({
            ...prevModel,
            filters: defaultFilters,
        }));
        updateHashParams(defaultFilters);
    };

    useEffect(() => {
        parseHashParams();
    }, [parseHashParams]);
    useEffect(() => {
        handleCategories();
    }, []);

    return (
        <div className="flex w-full space-x-3 px-3 pb-2 rounded-lg justify-end">
            {showProjectFilter &&
                <select name="projectId" onChange={onFilterChange} value={tableModel.filters.projectId || ''} className="px-2 py-1 border rounded text-sm max-w-32">
                    <option value=''>All Project</option>
                    {projects !== null && projects.map(project => <option key={project.id} value={project.id}>{project.name}</option>)}
                </select>
            }

            <select name="status" onChange={onFilterChange} value={tableModel.filters.status || ''} className="px-2 py-1 border rounded text-sm">
                <option value=''>All Status</option>
                <option value='Open'>Open</option>
                <option value='Closed'>Closed</option>
            </select>

            <select name="risk" onChange={onFilterChange} value={tableModel.filters.risk || ''} className="px-2 py-1 border rounded text-sm">
                <option value=''>All Risk</option>
                {Risks.map(risk => <option key={risk.id} value={risk.id}>{risk.name}</option>)}
            </select>

            <select name="categoryId" onChange={onFilterChange} value={tableModel.filters.categoryId || ''} className="px-2 py-1 border rounded text-sm max-w-32">
                <option value=''>All Subcategory</option>
                {filterCategories !== null && filterCategories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)}
            </select>

            <button onClick={clearFilters} className='bg-[#3A3D89] px-2 py-1 rounded text-white space-x-1 flex items-center text-sm'>
                Clear Filters
            </button>
        </div>
    );
};

export default VulnerabilityFilters;
