
const Project = {
    client_id: null,
    name: null,
    description: null,
    is_template: false,
    visibility: 'public',
    category_id: null,
};

export default Project;
