import PropTypes from 'prop-types';

const VulnerabilityStatusBadge = ({ vulnerability }) => {
    const STATUSES = {
        'Open': {
            label: 'Open',
            color: '#FFC000',
        },
        'Confirmed': {
            label: 'Confirmed',
            color: '#FF0000',
        },
        //       Resolved: #92D050
        //     - Closed: #4472C4
        //     - Open: #FFC000
        //     - Exploited: #FF0000

        'Resolved': {
            label: 'Resolved',
            color: '#92D050',
        },
        'Closed': {
            label: 'Closed',
            color: '#4472C4',
        },
    }
    const styles = {
        badge: {
            color: STATUSES[vulnerability.status].color,
            backgroundColor: `var(--${STATUSES[vulnerability.status].color}Dark)`,
            padding: `var(--paddingBadge)`,
            alignItems: 'center',
            display: `inline-flex`,
            borderRadius: 'var(--borderRadius, 3px)',
            border: `var(--borderWidth,2px) solid transparent`,
            fontSize: `var(--badgeFontSize)`,
            fontWeight: 'var(--fontBold)'
        }
    }
    return (
        <span style={styles.badge}>
            {STATUSES[vulnerability.status].label} ({vulnerability.substatus})
        </span>
    )
}

VulnerabilityStatusBadge.propTypes = {
    vulnerability: PropTypes.object.isRequired
};

export default VulnerabilityStatusBadge;
