import ReactMarkdown from 'react-markdown';
import './Preview.scss';
import styled from 'styled-components';

const StyledParagraph = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #333;
`;
const StyledListItem = styled.li`
  font-size: 16px;
  line-height: 1.6;
  color: #333;
`;
const StyledUnorderedList = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
`;
const StyledOrderedList = styled.ol`
  list-style-type: decimal;
  padding-left: 20px;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
`;
const StyledLink = styled.a`
  color: #1a0dab;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
const markdownComponents = {
    p: StyledParagraph,
    li: StyledListItem,
    ul: StyledUnorderedList,
    ol: StyledOrderedList,
    a: StyledLink,
};

const DocumentPreview = ({ content }) => {
    return (
        <div
            // className="document-preview"
        >
            <ReactMarkdown components={markdownComponents}>{content}</ReactMarkdown>
        </div>
    )
}

export default DocumentPreview;
