import { Checkbox } from "@chakra-ui/react";
import { useState } from "react";
import UserRoles from "../../models/UserRoles";

const UserForm = ({ isEdit = false, user, userSetter: setUser, onFormSubmit }) => {

    const [passwordGenerationMethod, setPasswordGenerationMethod] = useState('autogenerated');
    const [emailInput, setEmailInput] = useState(user.email || '');

    const onFormChange = ev => {
        const target = ev.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        if (name === 'email') {
            setEmailInput(value);
            setUser({ ...user, [name]: value, username: value });
        } else {
            setUser({ ...user, [name]: value });
        }
    };

    const onUsernameChange = ev => {
        setUser({ ...user, username: ev.target.value });
    };

    const onPasswordGenerationMethodChange = ev => {
        setPasswordGenerationMethod(ev.target.value);
    }

    return (
        <form onSubmit={onFormSubmit} className="crud">
            <fieldset>
                <label className="text-lg font-medium text-black">Basic information</label>
                <hr className='h-px bg-[#3A3D89]' />

                <label>Full name
                    <input type="text" name="full_name" value={user.full_name || ""} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
                </label>
                <label>Short bio
                    <input type="text" name="short_bio" value={user.short_bio || ""} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'
                        placeholder="DevSecOps, or Project Manager" />
                </label>
                <label>Email
                    <input type="email" name="email" value={emailInput} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
                </label>

                <label>Role
                    <select name="role" onChange={onFormChange} value={user.role} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required>
                        {UserRoles.map(role => <option key={`role_${role.id}`} value={role.id}>{role.name}</option>)}
                    </select>
                </label>
                <label>Properties
                    <div>
                        <Checkbox borderColor='gray.300' name="active" isChecked={user.active} onChange={onFormChange}>Active</Checkbox>
                        <Checkbox borderColor='gray.300' name="mfa_enabled" isChecked={user.mfa_enabled} onChange={onFormChange}>2FA enabled</Checkbox>
                    </div>
                </label>
            </fieldset>

            <fieldset>
                <label className="text-lg font-medium text-black">Credentials</label>
                <hr className='h-[0.5px] bg-[#3A3D89]' />
                <label>Username
                    <input type="text" name="username" value={user.username} onChange={onUsernameChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
                </label>

                {!isEdit &&
                    <>
                        <label htmlFor="passwordGenerationMethod">Password generation method
                            <select name="passwordGenerationMethod" onChange={onPasswordGenerationMethodChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'>
                                <option value="auto">Auto-generated</option>
                                <option value="manual">Manual</option>
                            </select>
                        </label>
                        {passwordGenerationMethod === 'manual' &&
                            <>
                                <label>Password
                                    <input type="password" name="unencryptedPassword" onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
                                </label>
                                <label>
                                    Send email to user
                                    <Checkbox borderColor='gray.300' name="sendEmailToUser" onChange={onFormChange} />
                                </label>
                            </>
                        }
                    </>
                }
            </fieldset>
            <button type='submit' className='bg-[#3A3D89] px-4 py-2 rounded text-white text-sm'>
                {isEdit ? "Update User" : "Create User"}
            </button>
        </form >
    )
}

export default UserForm;
