import { Link } from "react-router-dom";
// import { IconBriefcase } from "../ui/Icons";
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';

const ClientLink = ({ clientId, children }) => {
    if (!clientId) {
        return '(not set)';
    }

    const styles = {
        badge: {
            alignItems: 'center',
            display: `inline-flex`,
            borderRadius: 'var(--borderRadius)',
            fontWeight: 'var(--fontBold)',
            fontSize: 'var(--badgeFontSize)',
            backgroundColor: 'var(--bg-color-Clientbadge)',
            padding: 'var(--paddingBadge)',
            color: 'var(--color-Clientbadge)'
        }
    }

    return (
        <Link style={styles.badge} to={`/clients/${clientId}`}> <WorkOutlineOutlinedIcon fontSize='small' />
            {children}
        </Link>
    )
}

export default ClientLink;
