import useFetch from "hooks/useFetch";
import DashboardWidget from "./Widget";

const GreenYes = () => <span style={{ color: 'green' }}>Yes</span>;
const RedNo = () => <span style={{ color: 'red' }}>No</span>;

const ApiHealthWidget = () => {
    const [apiHealth] = useFetch('/system/health');

    return <DashboardWidget title="API health">
        {apiHealth && <>
            <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
                <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                    <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                        <tr>
                            <th scope="col" className="px-4 py-2">Name</th>
                            <th scope="col" className="px-2 py-2">Status</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr className="bg-white border-b border-gray-300 text-black font-light">
                            <th scope="row" className="px-2 py-2 ">
                                Response
                            </th>
                            <th scope="row" className="px-2 py-2 ">
                                Ok
                            </th>
                        </tr>

                        <tr className="bg-white border-b border-gray-300 text-black font-light">
                            <th scope="row" className="px-2 py-2 ">
                                Attachments directory is writeable
                            </th>
                            <th scope="row" className="px-2 py-2 ">
                                {apiHealth.attachmentsDirectory.writeable ? <GreenYes /> : <RedNo />}
                            </th>
                        </tr>
                        <tr className="bg-white border-b border-gray-300 text-black font-light">
                            <th scope="row" className="px-2 py-2 ">
                                DB Connection
                            </th>
                            <th scope="row" className="px-2 py-2 ">
                                {apiHealth.dbConnection.ping ? <GreenYes /> : <RedNo />}
                            </th>
                        </tr>
                        <tr className="bg-white border-b border-gray-300 text-black font-light">
                            <th scope="row" className="px-2 py-2 ">
                                Backend API Version
                            </th>
                            <th scope="row" className="px-2 py-2 text-xs">
                                {localStorage.getItem('APP_VERSION')}
                            </th>
                        </tr>

                    </tbody>
                </table>
            </div>
        </>}
    </DashboardWidget>
}

export default ApiHealthWidget;
