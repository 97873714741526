import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Auth from 'services/auth';
import PermissionsService from 'services/permissions';
import { IconChevronDown } from '../../ui/Icons';
import Links, { GroupThree } from './Links';
import { GroupOne } from './Links'
import { GroupTwo } from './Links';
import './Sidebar.scss';
import HeaderLogo from '../HeaderLogo';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import RestrictedComponent from 'components/logic/RestrictedComponent';

export default function Sidebar(props) {
    const user = Auth.getLoggedInUser();
    const location = useLocation();
    const currentPath = location.pathname;

    const isActive = (path) => {
        return location.pathname === path;
    };

    const filterByRole = (link) => {
        if (!link.hasOwnProperty('permissions')) {
            return true;
        }
        return user && PermissionsService.isAllowed(link.permissions, user.permissions);
    }
    const defaultSectionStatuses = Object.assign({}, ...Links.map(link => ({ [link.title]: false })));
    const [sectionStatuses, updateSectionStatuses] = useState(defaultSectionStatuses);

    const onParentClick = (ev, link) => {
        ev.preventDefault();
        updateSectionStatuses({ [link.title]: !sectionStatuses[link.title] })
    }

    return (
        <aside className="sidebar ">
            <HeaderLogo />
            <Link to={'/'} data-label='Dashboard' className='mt-2'>
                <ViewQuiltOutlinedIcon style={{ fontSize: '1.5rem' }} />
                <span className='font-medium ml-2'>Dashboard</span>
            </Link>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', }} className=''>
                <div>
                    {Links.filter(filterByRole).map((link, index) => {
                        const subLinks = link.sublinks.filter(filterByRole);
                        return (
                            <React.Fragment key={index}>
                                <div onClick={ev => onParentClick(ev, link)} className='mb-[2px]'>
                                    <Link to={currentPath} className={''}>
                                        {link.icon}
                                        <span className='font-medium ml-2'>{link.title}</span>
                                        {subLinks.length > 0 && <IconChevronDown styling={{ transform: sectionStatuses[link.title] && 'rotate(180deg)' }} />}
                                    </Link>
                                </div>
                                {sectionStatuses[link.title] &&
                                    <React.Fragment>
                                        {subLinks.map((sublink, index) =>
                                            sublink.hasOwnProperty('external') ?
                                                <a key={`sublink_${index}`} href={sublink.to} target="_blank" rel="noreferrer noopener" data-label={sublink.title} className='sublink'>
                                                    {sublink.icon}
                                                    <span>{sublink.title}</span>
                                                </a>
                                                :
                                                <Link key={`sublink_${index}`} to={sublink.to} data-label={sublink.title} className={`sublink ${isActive(sublink.to) ? "active" : ""}`}>
                                                    {sublink.icon}
                                                    <span >{sublink.title}</span>
                                                </Link>
                                        )}
                                    </React.Fragment>}
                            </React.Fragment>
                        )

                    })}
                </div>
                <div>
                    <RestrictedComponent roles={['administrator', 'superuser']}>
                        <div className="text-gray-500 font-medium text-xs mt-5">Superuser</div>
                        <hr className='mb-2' />
                    </RestrictedComponent>
                    {GroupOne.filter(filterByRole).map((link, index) => {
                        const subLinks = link.sublinks.filter(filterByRole);
                        return <React.Fragment key={index}>
                            <div onClick={ev => onParentClick(ev, link)} className='mb-[2px]'>
                                <NavLink to={link.to} data-label={link.title} className={data => data.isActive ? "active" : ""}>
                                    {link.icon}
                                    <span className='font-medium ml-2 '>{link.title}</span>
                                    {subLinks.length > 0 && <IconChevronDown styling={{ transform: sectionStatuses[link.title] && 'rotate(180deg)' }} />}
                                </NavLink>
                            </div>
                            {sectionStatuses[link.title] &&
                                <React.Fragment>
                                    {subLinks.map((sublink, index) =>
                                        sublink.hasOwnProperty('external') ?
                                            <a key={`sublink_${index}`} href={sublink.to} target="_blank" rel="noreferrer noopener" data-label={sublink.title} className='sublink'>
                                                {sublink.icon}
                                                <span >{sublink.title}</span>
                                            </a>
                                            :
                                            <NavLink key={`sublink_${index}`} to={sublink.to} data-label={sublink.title} className={data => data.isActive ? "sublink active" : "sublink"}>
                                                {sublink.icon}
                                                <span>{sublink.title}</span>
                                            </NavLink>
                                    )}
                                </React.Fragment>}
                        </React.Fragment>
                    })}

                    <RestrictedComponent roles={['administrator']}>
                        <div className="text-gray-500 font-medium text-xs mt-5">Administrator</div>
                        <hr className='mb-2' />
                    </RestrictedComponent>
                    {GroupTwo.filter(filterByRole).map((link, index) => {
                        const subLinks = link.sublinks.filter(filterByRole);
                        return <React.Fragment key={index}>
                            <div onClick={ev => onParentClick(ev, link)} className='mb-[2px]'>
                                <NavLink to={link.to} data-label={link.title} className={data => data.isActive ? "active" : ""}>
                                    {link.icon}
                                    <span className='font-medium ml-2 '>{link.title}</span>
                                    {subLinks.length > 0 && <IconChevronDown styling={{ transform: sectionStatuses[link.title] && 'rotate(180deg)' }} />}
                                </NavLink>
                            </div>
                            {sectionStatuses[link.title] &&
                                <React.Fragment>
                                    {subLinks.map((sublink, index) =>
                                        sublink.hasOwnProperty('external') ?
                                            <a key={`sublink_${index}`} href={sublink.to} target="_blank" rel="noreferrer noopener" data-label={sublink.title} className='sublink'>
                                                {sublink.icon}
                                                <span  >{sublink.title}</span>
                                            </a>
                                            :
                                            <NavLink key={`sublink_${index}`} to={sublink.to} data-label={sublink.title} className={data => data.isActive ? "sublink active" : "sublink"}>
                                                {sublink.icon}
                                                <span >{sublink.title}</span>
                                            </NavLink>
                                    )}
                                </React.Fragment>}
                        </React.Fragment>
                    })}
                    <div>
                        <div className="text-gray-500 font-medium text-xs mt-5">Help</div>
                        <hr className='mb-2' />
                    </div>
                    {GroupThree.filter(filterByRole).map((link, index) => {
                        const subLinks = link.sublinks.filter(filterByRole);
                        return <React.Fragment key={index}>
                            <div onClick={ev => onParentClick(ev, link)} className='mb-[2px]'>
                                <NavLink to={link.to} data-label={link.title} className={data => data.isActive ? "active" : ""}>
                                    {link.icon}
                                    <span className='font-medium ml-2'>{link.title}</span>
                                    {subLinks.length > 0 && <IconChevronDown styling={{ transform: sectionStatuses[link.title] && 'rotate(180deg)' }} />}
                                </NavLink>
                            </div>
                            {sectionStatuses[link.title] &&
                                <React.Fragment>
                                    {subLinks.map((sublink, index) =>
                                        sublink.hasOwnProperty('external') ?
                                            <a key={`sublink_${index}`} href={sublink.to} target="_blank" rel="noreferrer noopener" data-label={sublink.title} className='sublink'>
                                                {sublink.icon}
                                                <span >{sublink.title}</span>
                                            </a>
                                            :
                                            <NavLink key={`sublink_${index}`} to={sublink.to} data-label={sublink.title} className={data => data.isActive ? "sublink active" : "sublink"}>
                                                {sublink.icon}
                                                <span >{sublink.title}</span>
                                            </NavLink>
                                    )}
                                </React.Fragment>}
                        </React.Fragment>

                    })}
                </div>

            </div>
        </aside>
    )
}
