import React, { useCallback, useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';

import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import secureApiFetch from 'services/api';
import Loading from 'components/ui/Loading';
import PageTitle from 'components/logic/PageTitle';
import Breadcrumb from 'components/ui/Breadcrumb';
import RestrictedComponent from 'components/logic/RestrictedComponent';
import Title from 'components/ui/Title';

import ApprovalDetailsTab from '../ApprovalDetailsTab';
import { actionCompletedToast } from 'components/ui/toast';

const ApprovalDetails = () => {
  const { approvalId } = useParams();
  const [approval, setApprovals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await secureApiFetch(`/projectapproval/${approvalId}`);
      const data = await response.json();
      setApprovals(data || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  }, [approvalId]);


  useEffect(() => {
    fetchData(); // Call the fetchData function here
  }, [approvalId, fetchData]); // Add approvalId and fetchData as dependencies to trigger the effect on change

  if (!approval || !approval.activity || approval.activity.length === 0) {
    return <div>Error: Unable to fetch approval details.</div>;
  }

  const activityDetails = approval.activity[0];

  const project_approval_status = ['pending', 'approved', 'hold', 'declined'];

  const handleStatusUpdate = () => {
    secureApiFetch(`/projectapproval/${approvalId}`, {
      method: 'PUT',
      body: JSON.stringify({ approval_status: selectedStatus }),
    })
      .then((res) => {
        if (res.status === 200) {
          actionCompletedToast(`Status Updated Successfully.`);
        }
        fetchData()
      })
      .catch((err) => console.error(err));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="heading">
        <Breadcrumb>
          <Link to="/projects">Projects</Link>
          <Link to="/projects/approvals">Upcoming Projects</Link>
        </Breadcrumb>

        <div className='flex items-center space-x-2'>
          <RestrictedComponent roles={['administrator', 'superuser']}>
            <div className='flex items-center space-x-2'>
              <select onChange={(e) => setSelectedStatus(e.target.value)} value={selectedStatus} className='px-2 py-1.5 border rounded text-sm w-36 font-medium'>
                {project_approval_status.map((status) => (
                  <option key={status} value={status}>
                    {status.replace(/_/g, ' ').charAt(0).toUpperCase() + status.replace(/_/g, ' ').slice(1)}
                  </option>
                ))}
              </select>
              <button onClick={handleStatusUpdate} className='px-2 py-1.5 border bg-[#3A3D89] text-white rounded text-sm font-medium'>
                Update
              </button>
            </div>
            {activityDetails && (
              <>
                <Link to={`/projects/projectapproval/${activityDetails.id}/edit`}>
                  <button className='bg-[#3A3D89] px-2 py-1.5 rounded text-white text-sm'>
                    Edit
                  </button>
                </Link>
              </>
            )}

          </RestrictedComponent>
        </div>
      </div>

      <article>
        <PageTitle value={`${activityDetails.activity_name} Project Name For Approval`} />
        <Title title={activityDetails.activity_name} type='Project Name For Approval' icon={<FolderOpenOutlinedIcon />} />

        <div className='flex space-x-2 items-center mb-1'>
          <span className={`px-5 py-1 font-medium bg-blue-50 border border-blue-200 rounded text-xs approval-${activityDetails.approval_status}`}>
            {activityDetails.approval_status.replace(/_/g, ' ').charAt(0).toUpperCase() + activityDetails.approval_status.replace(/_/g, ' ').slice(1)}
          </span>

          {activityDetails.activity_type &&
            <span className={`px-5 py-1 text-blue-500 font-medium bg-blue-50 border border-blue-200 rounded text-xs `}>
              {activityDetails.activity_type}
            </span>
          }
          <span className={`px-5 py-1 font-medium bg-blue-50 border border-blue-200 rounded text-xs text-blue-500`}>
            {activityDetails.project_id === null ? 'Project Not Linked ' : <Link to={`/projects/${activityDetails.project_id}`}>
              Project Linked Id: {activityDetails.project_id}
            </Link>}

          </span>
        </div>

        <Tabs variant='enclosed'>
          <TabList>
            <Tab><span className="font-medium text-xs">Details</span></Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <ApprovalDetailsTab activity={activityDetails} comment={approval.comment} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </article>
    </>
  );
};

export default ApprovalDetails;
