const eventFilters = [
    "Created attachment",
    "Deleted attachment",
    "Updated attachment",
    "Downloaded attachment",
    "Imported data",
    "Exported data",
    "Updated organisation",
    "Updated question",
    "Deleted question",
    "Updated activity",
    "Deleted activity",
    "Updated scan schedule",
    "Deleted scan schedule",
    "Updated scan result",
    "Deleted scan result",
    "Updated project category",
    "Deleted project category",
    "Added project user",
    "Marked notifications as read",
    "Deleted notifications",
    "Created client",
    "Updated client",
    "Deleted client",
    "Command updated",
    "Contact deleted",
    "Document created",
    "Document modified",
    "Document deleted",
    "Created note",
    "Deleted note",
    "Created notification",
    "Updated notification",
    "Deleted notification",
    "Created project approval",
    "Deleted project approval",
    "Modified project approval",
    "Created project",
    "Modified project",
    "Deleted project",
    "Modified public link",
    "Added project user",
    "Removed project user",
    "Report Sent",
    "Weekly Report Sent",
    "Created target",
    "Deleted target",
    "Modified task",
    "Deleted task",
    "Failed login",
    "Logged in",
    "Logged out",
    "Created user",
    "Modified user",
    "Deleted user",
    "Changed own password",
    "Vault item deleted",
    "Vault item created",
    "Vault item updated",
    "Vault item read",
    "Created vulnerability",
    "Modified vulnerability",
    "Deleted vulnerability",
    "Updated vulnerability to public",
    "Updated vulnerability to private",
    "Updated vulnerability: closed - mitigated",
    "Updated vulnerability: closed - mitigated",
    "Updated vulnerability: closed - remediated",
    "Client updated vulnerability status",
]
export default eventFilters