import { Badge, Divider, HStack, Text } from "@chakra-ui/layout";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import UserAvatar from "components/badges/UserAvatar";
import { AuthContext } from "contexts/AuthContext";
import { useContext } from "react";
import { Link } from 'react-router-dom';
import KeyCloakService from "services/keycloak";
import Configuration from "Configuration";

const HeaderUserMenu = () => {
    const { user, logout } = useContext(AuthContext);
    const getUserId = JSON.parse(localStorage.getItem('user'))
    return (
        <div className="text-black">
            <Menu closeOnBlur closeOnSelect >
                <MenuButton>
                    <UserAvatar email={user.email} name={user.name} />
                </MenuButton>
                <MenuList borderColor='blackAlpha.500'>
                    <HStack px="3" pb="3" justifyContent="space-between" alignItems="center">
                        <strong><Text color="black">{KeyCloakService.GetUsername()}</Text></strong>
                        <Badge colorScheme="red">{user.role}</Badge>
                    </HStack>
                    <a href={KeyCloakService.GetProfileUrl()} isExternal>
                        <MenuItem>Your IAM profile</MenuItem>
                    </a>
                    {getUserId && (
                        <Link to={`/users/${getUserId.id}`}>
                            <MenuItem>Your profile</MenuItem>
                        </Link>
                    )}
                    <Link to="/users/preferences">
                        <MenuItem>Preferences</MenuItem>
                    </Link>

                    <MenuItem >
                        <a href={`${Configuration.getKeycloakConfig().url}/realms/${Configuration.getKeycloakConfig().realm}/account/#/security/signingin`} target="_blank">
                            Password Change
                        </a>
                    </MenuItem>
                    <Divider />
                    <Link to="/" onClick={logout}>
                        <MenuItem>Logout</MenuItem>
                    </Link>
                </MenuList>
            </Menu>
        </div>
    )
}

export default HeaderUserMenu;
