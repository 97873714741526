import CommandBadge from "components/commands/Badge";
import useFetch from "hooks/useFetch";
import DashboardWidget from "./Widget";

const PopularCommandsWidget = () => {
    const [commands] = useFetch('/commands?limit=5');

    return <DashboardWidget title="Popular commands (Last 5)">

        {commands && commands.length > 0 ?
            <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
                <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                    <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                        <tr>
                            <th scope="col" className="px-4 py-2">Short name</th>
                            <th scope="col" className="px-4 py-2">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {commands.map(command => (
                            <tr key={command.id} className="bg-white border-b border-gray-300 text-black font-medium">
                                <td scope="row" className="px-2 py-2 "><CommandBadge command={command}>{command.name}</CommandBadge></td>
                                <td scope="row" className="px-2 py-2 ">
                                    {command.description !== null ? `${JSON.stringify(command.description).substring(0, 250)}` : 'NA'}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div> :
            <p>No commands to show.</p>
        }
    </DashboardWidget>
}

export default PopularCommandsWidget;
