import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import VulnerabilityChat from './chat/VulnerabilityChat';
import useFetch from "../../hooks/useFetch";
import Loading from "../ui/Loading";

const VulnerabilitiesNotesTab = ({ vulnerability }) => {
    const [notes, reloadNotes] = useFetch(`/notes?parentType=vulnerability&parentId=${vulnerability.id}`)

    if (!notes) {
        return <Loading />
    }

    return (
        <section className='-mt-1'>
            <h4 className='text-sm'>
                <StickyNote2OutlinedIcon className='text-[#3A3D89]' />Vulnerability QA Discussion
            </h4>
            <div className='my-2'>
                <VulnerabilityChat vulnerability={vulnerability} parentType="vulnerability" parent={vulnerability} notes={notes} reloadNotes={reloadNotes} />
            </div>
        </section>
    )
}

export default VulnerabilitiesNotesTab;
