import { useContext, useState } from 'react';
import { useColorMode } from '@chakra-ui/react';
import { actionCompletedToast } from 'components/ui/toast';
import { initialiseUserPreferences } from 'services/userPreferences';

import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import PageTitle from 'components/logic/PageTitle';
import CountriesTimezones from 'countries-and-timezones';
import Auth from 'services/auth';

import ThemeContext from "../../contexts/ThemeContext";
import secureApiFetch from '../../services/api';
import setThemeColors from '../../utilities/setThemeColors';
import Breadcrumb from '../ui/Breadcrumb';
import Title from '../ui/Title';

const UserPreferences = () => {

    const user = Auth.getLoggedInUser();
    user.preferences = initialiseUserPreferences(user);

    const timezones = CountriesTimezones.getAllTimezones();
    const timezoneKeys = Object.keys(timezones).sort();
    console.log(timezoneKeys)
    const { setTheme } = useContext(ThemeContext);

    const { setColorMode } = useColorMode();

    const [formValues, setFormValues] = useState({
        timezone: user.timezone,
        theme: user.preferences["web-client.theme"]
    });

    const updateFormValues = ev => {
        setFormValues({ ...formValues, [ev.target.name]: ev.target.value });
    }

    const onFormSubmit = ev => {
        ev.preventDefault();

        user.timezone = formValues.timezone;
        user.preferences = { ...initialiseUserPreferences(user), "web-client.theme": formValues.theme };

        secureApiFetch(`/users/${user.id}`, {
            method: 'PATCH',
            body: JSON.stringify({ timezone: formValues.timezone, preferences: user.preferences })
        })
            .then(() => {
                setTheme(theme => {
                    setThemeColors(formValues.theme);
                    setColorMode(formValues.theme);
                    return formValues.theme;
                });

                localStorage.setItem('user', JSON.stringify(user));

                actionCompletedToast("Your preferences have been saved.");
            })
            .catch(err => console.error(err));
    }

    return (
        <div >
            <PageTitle value="Preferences" />
            <div className='heading'>
                <Breadcrumb>
                    <span>Preferences</span>
                </Breadcrumb>
            </div>
            <Title type='User' title='Preferences' icon={<TuneOutlinedIcon />} />
            <form onSubmit={onFormSubmit} className="flex justify-center items-center space-x-2 px-2">
                <label>
                    <span className="font-medium text-xs">Timezone</span>
                    <select name="timezone" onChange={updateFormValues} defaultValue={user.timezone} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'>
                        {timezoneKeys.map((key, index) =>
                            <option key={index} value={timezones[key].name}>{timezones[key].name}</option>
                        )}
                    </select>
                </label>
                <button type="submit" className='bg-[#3A3D89] w-20 px-4 py-1.5 rounded text-white text-sm'>
                    Save
                </button>
            </form>
        </div>
    )
}

export default UserPreferences;
