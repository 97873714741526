import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

import ControlPointDuplicateOutlinedIcon from '@mui/icons-material/ControlPointDuplicateOutlined';
import PageTitle from "components/logic/PageTitle";
import Breadcrumb from 'components/ui/Breadcrumb';
import Title from 'components/ui/Title';

import CategoryQuestion from './Table';
import CategoryForm from './Form';
import useQuery from 'hooks/useQuery';
import PaginationV2 from 'components/layout/PaginationV2';
import secureApiFetch from 'services/api';

function CategoryQuestionList() {
    const navigate = useNavigate();
    const [questions, updateQuestions] = useState([]);
    const query = useQuery();
    let pageNumber = query.get('page');
    pageNumber = pageNumber !== null ? parseInt(pageNumber) : 1;
    const apiPageNumber = pageNumber - 1;

    const [numberPages, setNumberPages] = useState(1);
    const [totalCount, setTotalCount] = useState('?');

    const onPageChange = pageNumber => {
        const queryParams = new URLSearchParams();
        queryParams.set('page', pageNumber + 1);
        const url = `/settings/categoryQuestion?${queryParams.toString()}`;
        navigate(url);
    }

    const reloadProjects = useCallback(() => {
        const queryParams = new URLSearchParams();
        queryParams.set('page', apiPageNumber);
        const url = `/questions?${queryParams.toString()}`;
        secureApiFetch(url)
            .then(resp => {
                if (resp.headers.has('X-Page-Count')) {
                    setNumberPages(resp.headers.get('X-Page-Count'))
                }
                if (resp.headers.has('X-Total-Count')) {
                    setTotalCount(resp.headers.get('X-Total-Count'))
                }
                return resp.json()
            })
            .then(data => {
                updateQuestions(data)
            });
    }, [apiPageNumber]);


    useEffect(() => {
        reloadProjects();
    }, [reloadProjects]);

    return (
        <React.Fragment>
            <PageTitle value="Categories Question" />
            <div className="heading">
                <Breadcrumb>
                    <Link to="/settings">Category Questions</Link>
                </Breadcrumb>
                <PaginationV2 page={apiPageNumber} total={numberPages} onPageChange={onPageChange} />
                <div></div>
            </div>
            <Title title={`Categories Question (${totalCount})`} icon={<ControlPointDuplicateOutlinedIcon />} />
            <CategoryForm updateQuestions={reloadProjects} />
            <hr />
            <CategoryQuestion questions={questions} updateQuestions={reloadProjects} />
        </React.Fragment>
    );
}

export default CategoryQuestionList;
