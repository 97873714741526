import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

const NavSearch = () => {
    const navigate = useNavigate();
    const [keywords, setKeywords] = useState('');
    const [show, setShow] = useState(false)

    const onFormSubmit = (ev) => {
        ev.preventDefault();
        navigate(`/search/${encodeURIComponent(keywords.trim())}?entities=commands,tasks,vulnerabilities,projects`);
    }
    const onKeywordsChange = ev => {
        setKeywords(ev.target.value);
    };
    return (
        <>
            {show &&

                <form onSubmit={onFormSubmit} className='flex items-center justify-between bg-white shadow rounded py-0 h-8 px-2'>
                    <input type="text" value={keywords} onChange={onKeywordsChange} placeholder="Search Keywords "
                        className='text-sm font-medium focus:outline-none py-0'
                    />
                    <button type="submit" disabled={keywords.trim().length === 0} className='border-l border-black w-8 text-[#3A3D89] font-medium py-0'>
                        <SearchIcon fontSize='small'/>
                    </button>
                </form>
            }
            <button onClick={() => setShow(!show)} className='w-8 text-[#3A3D89] font-medium py-0'>
                {show === true
                    ? <CloseIcon fontSize='small'/>
                    : <SearchIcon fontSize='small'/>
                }
            </button>
        </>
    )
}
export default NavSearch