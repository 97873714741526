import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';
import CreateButton from 'components/ui/buttons/Create';
import Pagination from 'components/layout/Pagination';
import PageTitle from 'components/logic/PageTitle';
import secureApiFetch from 'services/api';
import useQuery from 'hooks/useQuery';
import DocumentsTable from './Table';

import documentTableModel from './documentTableModel';
import useDelete from '../../hooks/useDelete';
import useFetch from '../../hooks/useFetch';
import Breadcrumb from '../ui/Breadcrumb';
import Title from '../ui/Title';

const DocumentsListPage = () => {
    const navigate = useNavigate();
    const [documents, fetchDocuments] = useFetch('/documents')
    const destroy = useDelete('/documents/', fetchDocuments);

    const query = useQuery();
    let pageNumber = query.get('page');
    pageNumber = pageNumber !== null ? parseInt(pageNumber) : 1;
    const apiPageNumber = pageNumber - 1;

    const [tableModel, setTableModel] = useState(new documentTableModel())

    const [totalCount, setTotalCount] = useState('?');
    const [numberPages, setNumberPages] = useState(1);

    const handlePrev = () => {
        const queryParams = new URLSearchParams();
        queryParams.set('isTemplate', 'false');
        queryParams.set('page', pageNumber - 1);
        queryParams.set('orderColumn', tableModel.sortBy.column);
        queryParams.set('orderDirection', tableModel.sortBy.order);
        Object.keys(tableModel.filters)
            .forEach(key => tableModel.filters[key] !== null && tableModel.filters[key].length !== 0 && queryParams.append(key, tableModel.filters[key]));
        const url = `/documents?${queryParams.toString()}`;
        navigate(url);
    }

    const handleNext = () => {
        const queryParams = new URLSearchParams();
        queryParams.set('isTemplate', 'false');
        queryParams.set('page', pageNumber + 1);
        queryParams.set('orderColumn', tableModel.sortBy.column);
        queryParams.set('orderDirection', tableModel.sortBy.order);
        Object.keys(tableModel.filters)
            .forEach(key => tableModel.filters[key] !== null && tableModel.filters[key].length !== 0 && queryParams.append(key, tableModel.filters[key]));
        const url = `/documents?${queryParams.toString()}`;
        navigate(url);
    }
    const reloadDocuments = useCallback(() => {
        setTableModel(tableModel => ({ ...tableModel, documents: null }));

        const queryParams = new URLSearchParams();
        queryParams.set('isTemplate', 'false');
        queryParams.set('page', apiPageNumber);
        queryParams.set('orderColumn', tableModel.sortBy.column);
        queryParams.set('orderDirection', tableModel.sortBy.order);
        Object.keys(tableModel.filters)
            .forEach(key => tableModel.filters[key] !== null && tableModel.filters[key].length !== 0 && queryParams.append(key, tableModel.filters[key]));
        const url = `/documents?${queryParams.toString()}`;

        secureApiFetch(url, { method: 'GET' })
            .then(resp => {
                if (resp.headers.has('X-Page-Count')) {
                    setNumberPages(resp.headers.get('X-Page-Count'))
                }
                if (resp.headers.has('X-Total-Count')) {
                    setTotalCount(resp.headers.get('X-Total-Count'))
                }
                return resp.json()
            })
            .then(data => {
                setTableModel(tableModel => ({ ...tableModel, documents: data }));
            });
    }, [setTableModel, apiPageNumber, tableModel.filters, tableModel.sortBy.column, tableModel.sortBy.order]);

    const onAddCommandClick = ev => {
        ev.preventDefault();
        navigate('/documents/add');
    }
    useEffect(() => {
        reloadDocuments()
    }, [reloadDocuments, tableModel.filters])

    return <div>
        <PageTitle value="Documents" />
        <div className='heading'>
            <Breadcrumb >
                <Link to="/documents">Documents</Link>
            </Breadcrumb>
            <Pagination page={apiPageNumber} total={numberPages} handlePrev={handlePrev} handleNext={handleNext} />
            <CreateButton onClick={onAddCommandClick}>Create document</CreateButton>
        </div>
        <Title title={`Documents (${totalCount})`} icon={<TopicOutlinedIcon />} />
        <DocumentsTable documents={documents} reloadDocuments={reloadDocuments} onDeleteButtonClick={destroy} tableModel={tableModel} tableModelSetter={setTableModel} reloadCallback={reloadDocuments} />
    </div>
}

export default DocumentsListPage;
