
interface Props {
    pageNumber: number;
    selection: string[];
    projects?: object[];
    filters: object;
    sortBy: object;
}

class ProjectTableModel implements Props {
    pageNumber: number = 0;
    selection: string[] = [];
    projects?: object[] = [];
    filters: object = {
        clientId: null,
        projectStatus: null,
        projectSubstatus: null,
        orderColumn:null,
    };
    sortBy: object = {  order: 'DESC' };
}

export default ProjectTableModel;