import { Link } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LoadingTableRow from "components/ui/tables/LoadingTableRow";
import NoResultsTableRow from "components/ui/tables/NoResultsTableRow";
import Tags from "components/ui/Tags";

import CommandBadge from "./Badge";

const CommandsTable = ({ commands, onDeleteCallback = null }) => {
    return (
        <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
            <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                    <tr>
                        <th scope="col" className="px-4 py-2">Name</th>
                        <th scope="col" className="px-4 py-2">Description</th>
                        <th scope="col" className="px-4 py-2">Execution Environment</th>
                        <th scope="col-2" className="px-4 py-2">Output Parser</th>
                        <th scope="col-2" className="px-4 py-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {null === commands && <LoadingTableRow numColumns={5} />}
                    {null !== commands && 0 === commands.length && <NoResultsTableRow numColumns={5} />}
                    {null !== commands && 0 !== commands.length && commands.map((command, index) => {
                        return (
                            <tr key={index} className="bg-white border-b border-gray-300 text-black ">

                                <th scope="row" className="px-4 py-1 text-left ">
                                    <CommandBadge command={command} />
                                </th>

                                <th scope="row" className="px-4 py-1 text-left font-normal">
                                    {command.description}  <br />
                                    <Tags values={command.tags} />
                                </th>

                                <th scope="row" className="px-4 py-1 ">
                                    {command.executable_type === 'custom' ? 'Host' : 'Container'}
                                </th>

                                <th scope="row" className="px-4 py-1 ">
                                    <span className="px-2 py-1.5 border rounded text-sm font-medium">
                                        {command.output_parser ?? 'NA'}
                                    </span>
                                </th>

                                <th scope="row" className="px-4 py-1 ">
                                    <Link to={`/commands/${command.id}/edit`} >
                                        <EditIcon className='text-[#3A3D89]' fontSize='small' />
                                    </Link>
                                    {onDeleteCallback &&
                                        <button onClick={() => onDeleteCallback(command.id)}>
                                            <DeleteIcon className='text-[#EB1F36] mx-1' fontSize='small' />
                                        </button>
                                    }
                                </th>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default CommandsTable;
