import MarkdownEditor from "components/ui/forms/MarkdownEditor";
import Loading from "components/ui/Loading";
import useFetch from "hooks/useFetch";

const CommandForm = ({ isEditForm = false, onFormSubmit, command, commandSetter: setCommand }) => {
    const onFormChange = ev => {
        const target = ev.target;
        let name = target.name;
        let value = target.value;
        if ('tags' === name) {
            value = JSON.stringify(value.split(','));
        }
        if ('output_capture' === name) {
            const outputFileName = value === 'disabled' ? null :
                value === 'stdout' ? '{{{STDOUT}}}' :
                    "";

            setCommand({ ...command, [name]: value, output_filename: outputFileName });
        } else {
            setCommand({ ...command, [name]: value });
        }
    };

    const [parsers] = useFetch('/commands/output-parsers');

    if (!parsers) return <Loading />

    return (
        <form onSubmit={onFormSubmit} className="crud">
            <fieldset>
                <label className="text-lg font-medium text-black">Basic information</label>
                <hr className='h-px bg-[#3A3D89]' />
                <label>
                    <span className="font-medium text-xs">Name</span>
                    <input type="text" name="name" onChange={onFormChange} value={command.name || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
                </label>

                <label>
                    <span className="font-medium text-xs">Description</span>
                    <MarkdownEditor name="description" onChange={onFormChange} value={command.description || ""} required />
                </label>

                <label>
                    <span className="font-medium text-xs">Tags</span>
                    <input type="text" name="tags" onChange={onFormChange} value={command.tags ? JSON.parse(command.tags).join(',') : ''} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                </label>

                <label>
                    <span className="font-medium text-xs">More information URL</span>
                    <input type="url" name="more_info_url" onChange={onFormChange} value={command.more_info_url || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                </label>
            </fieldset>

            <fieldset>
                <label className="text-lg font-medium text-black">Automation details</label>
                <hr className='h-[0.5px] bg-[#3A3D89]' />
                <label>
                    <span className="font-medium text-xs">Execution environment</span>
                    <select name="executable_type" onChange={onFormChange} value={command.executable_type} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required>
                        <option value="custom">Host</option>
                        <option value="rmap">Container</option>
                    </select>
                </label>
                {command.executable_type === 'custom' && <>
                    <label>
                        <span className="font-medium text-xs">Executable path</span>
                        <input type="text" name="executable_path" onChange={onFormChange} value={command.executable_path || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                    </label>
                </>
                }
                {command.executable_type === 'rmap' && <>
                    <label>
                        <span className="font-medium text-xs">Docker image</span>
                        <input type="text" name="docker_image" onChange={onFormChange} value={command.docker_image || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                    </label>
                </>}

                <label>
                    <span className="font-medium text-xs">Command line arguments</span>
                    <input type="text" name="arguments" onChange={onFormChange} value={command.arguments || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                </label>

                <label>
                    <span className="font-medium text-xs">Output capture</span>
                    <select name="output_capture" onChange={onFormChange} value={command.output_capture || "disabled"} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required>
                        <option value="disabled">Disabled</option>
                        <option value="stdout">Standard output</option>
                        <option value="path">File path</option>
                    </select>
                </label>

                {command.output_capture === 'path' &&
                    <label>
                        <span className="font-medium text-xs">Output filename</span>
                        <input type="text" name="output_filename" onChange={onFormChange} value={command.output_filename || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                    </label>}

                {command.output_capture !== 'disabled' &&
                    <label>
                        <span className="font-medium text-xs">Output parser</span>
                        <select name="output_parser" onChange={onFormChange} value={command.output_parser || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'>
                            <option value="">(none)</option>
                            {parsers.map(parser => <option key={`parser_${parser.code}`} value={parser.code}>{parser.name}</option>)}
                        </select>
                    </label>}
            </fieldset>

            <div className="fixed bottom-4 right-4">
                <button type='submit' className='bg-[#3A3D89] px-4 py-2 rounded text-white text-sm'>
                    {isEditForm ? "Update Command" : "Create Command"}
                </button>
            </div>
        </form>
    )
}

export default CommandForm;
