import Risks from "./Risks";

const VulnerabilityModel = {
    is_template: false,

    external_id: null,
    project_id: null,
    target_id: 0,
    category_id: 0,

    summary: "",
    description: "",
    external_refs: "",

    visibility: 'private',

    risk: Risks[0].id,
    proof_of_concept: null,
    impact: null,

    remediation: null,
    remediation_complexity: null,
    remediation_priority: null,

    cvss_score: null,
    cvss_vector: null,

    status: null,
    substatus: null,

    tags: null
};

export default VulnerabilityModel;
