import secureApiFetch from './api'

const DownloadFromS3 = (id) => {
    secureApiFetch(`/attachments/${id}`, { method: 'GET', headers: {} }, { method: 'GET' })
        .then(resp => {
            if (resp.ok) {
                
                return resp.json()
            } else {
                throw new Error('response not present')
            }
        })
        .then(values => {
            const downloadUrl = values.downloadUrl
            window.open(downloadUrl, '_blank')
        })
        .catch(error => {
            console.error('Error occurred:', error);

            alert('Failed to download. Please try again later.'); 

        });
}

export default DownloadFromS3;