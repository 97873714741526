import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { actionCompletedToast, errorToast } from 'components/ui/toast';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import VulnerabilityModel from 'models/Vulnerability';
import PageTitle from 'components/logic/PageTitle';
import useQuery from 'hooks/useQuery';

import secureApiFetch from "../../services/api";
import Breadcrumb from '../ui/Breadcrumb';
import VulnerabilityForm from "./Form";
import Title from '../ui/Title';

const VulnerabilityCreate = () => {

    const navigate = useNavigate();
    const query = useQuery();
    const urlProjectId = useRef(query.get('projectId') || 0);
    const isTemplate = 'true' === query.get('isTemplate');

    const [vulnerability, setVulnerability] = useState({ ...VulnerabilityModel, is_template: isTemplate, project_id: urlProjectId.current })

    const onFormSubmit = ev => {
        ev.preventDefault();

        secureApiFetch(`/vulnerabilities`, { method: 'POST', body: JSON.stringify(vulnerability) })
            .then((res) => {
                localStorage.removeItem('cvss_data')
                if (res.status === 201) {
                    actionCompletedToast(`Created Vulnerability `);
                } else {
                    errorToast('Vulnerability Not Created')
                }
                return res.json()
            })
            .then((data) => {
                if (vulnerability.is_template) {
                    navigate('/vulnerabilities/templates');
                } else {
                    navigate(`/vulnerabilities`);
                }
            })
            .catch((error) => {
                console.error(error)
            })
    };

    return (
        <div>
            <PageTitle value="Add vulnerability" />
            <div className='heading'>
                <Breadcrumb>
                    <Link to="/vulnerabilities">Vulnerabilities</Link>
                    <Link to="/vulnerabilities">Create Vulnerability</Link>
                </Breadcrumb>
            </div>
            <Title title="New vulnerability details" icon={<AddOutlinedIcon />} />

            <VulnerabilityForm type="project" vulnerability={vulnerability} vulnerabilitySetter={setVulnerability}
                onFormSubmit={onFormSubmit} />
        </div>
    )
};

export default VulnerabilityCreate;
