import ClientLink from 'components/clients/Link';
import LoadingTableRow from 'components/ui/tables/LoadingTableRow';
import NoResultsTableRow from 'components/ui/tables/NoResultsTableRow';
import useFetch from 'hooks/useFetch';
import React from 'react'

const OrganizationTable = () => {
    const [clients] = useFetch('/clients');
    return (
        <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
            <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                    <tr>
                        <th scope="col" className="px-4 py-2">Organisation Name</th>
                        <th scope="col" className="px-2 py-2">Address</th>
                        <th scope="col" className="px-2 py-2">URL</th>
                        <th scope="col" className="px-2 py-2">Created</th>
                    </tr>
                </thead>
                <tbody>
                    {null === clients && <LoadingTableRow numColumns={4} />}
                    {null !== clients && 0 === clients.length && <NoResultsTableRow numColumns={4} />}
                    {null !== clients && 0 !== clients.length && clients.map((client, index) => {
                        return (
                            <tr key={index} className="bg-white border-b border-gray-300 text-black font-light">
                                <th scope="row" className="px-2 py-2 ">
                                    <ClientLink clientId={client.id}>{client.name}</ClientLink>
                                </th>
                                <th scope="row" className="px-2 py-1 font-medium">
                                {client.address}
                                </th>
                                <th scope="row" className="px-2 py-1">
                                    {client.url}
                                </th>
                                <th scope="row" className="px-2 py-1 ">
                                    {client.insert_ts}
                                </th>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default OrganizationTable