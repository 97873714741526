// import { SimpleGrid } from '@chakra-ui/react'
// import ClientLink from 'components/clients/Link'
// import React, { useState } from 'react'
import EmptyField from 'components/ui/EmptyField'
// import RelativeDateFormatter from 'components/ui/RelativeDateFormatter'
// import TimestampsSection from 'components/ui/TimestampsSection'
// import VisibilityLegend from 'components/ui/VisibilityLegend'
import UserLink from 'components/users/Link'
import ReactMarkdown from 'react-markdown'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';

const ApprovalDetailsTab = ({ activity, comment }) => {

    return (
        <section className="grid grid-two">
            <div>
                <h4 className='text-sm'>
                    <DescriptionOutlinedIcon className='text-[#3A3D89]' /> Activity Details
                </h4>
                <dl>
                    {activity.assessment_type && <>
                        <dt className='text-xs'>Assessment Type</dt>
                        <dd> className='text-xs'{activity.assessment_type}</dd>
                    </>}

                    {activity.hosting && <>
                        <dt className='text-xs'>Hosting</dt>
                        <dd className='text-xs'>{activity.hosting}</dd>
                    </>}

                    {activity.vulnerability_metrics && <>
                        <dt className='text-xs'>Vulnerability metrics</dt>
                        <dd className='text-xs'>{activity.vulnerability_metrics}</dd>
                    </>}

                    <dt className='text-xs'>Note</dt>
                    <dd className='text-xs'>{activity.note_to_assessor ? <ReactMarkdown>{activity.note_to_assessor}</ReactMarkdown> : <EmptyField />}</dd>

                    <dt className='text-xs'>Exception & Limitations</dt>
                    <dd className='text-xs'>{activity.exception_or_limitations ? <ReactMarkdown>{activity.exception_or_limitations}</ReactMarkdown> : "Not Present"}</dd>

                    <dt className='text-xs'>Questions</dt>
                    <dt className='text-xs'>
                        {activity.questions ? (
                            <table className="min-w-full table-auto border-collapse border border-gray-200">
                                <thead>
                                    <tr className="bg-gray-100">
                                        <th className="px-4 py-2 border border-gray-200">Question</th>
                                        <th className="px-4 py-2 border border-gray-200">Answer</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(JSON.parse(activity.questions)).map(([key, value], index) => (
                                        <tr key={index} className="bg-white odd:bg-gray-50">
                                            <td className="px-4 py-2 border border-gray-200 font-semibold">{key}</td>
                                            <td className="px-4 py-2 border border-gray-200">
                                                {value.answer &&
                                                    <button onClick={() => { navigator.clipboard.writeText(value.answer) }}>
                                                        <ContentCopyIcon sx={{ fontSize: '1rem', marginRight: '5px' }} />
                                                    </button>
                                                }
                                                {Array.isArray(value.answer) ? value.answer.join(", ") : value.answer}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (<EmptyField />)}
                    </dt>

                    <dt className='text-xs'>Internal Team Comment</dt>
                    <dd className='text-xs'>{comment ? <ReactMarkdown>{comment}</ReactMarkdown> : <EmptyField />}</dd>
                </dl>
            </div>

            <div>
                <h4 className='text-sm'> <InsertLinkOutlinedIcon className='text-[#3A3D89]' />  Relations</h4>
                <dl>
                    <dt className='text-xs'>Start Date</dt>
                    <dd className='text-xs'>{activity.start_date}</dd>

                    <dt className='text-xs'>End Date</dt>
                    <dd className='text-xs'>{activity.end_date}</dd>

                    <dt className='text-xs'>Created by</dt>
                    <dd className='text-xs'><UserLink userId={activity.user_id}>{activity.user_id}</UserLink></dd>
                </dl>
                {/* <TimestampsSection entity={activity} /> */}
            </div>
        </section>
    )
}

export default ApprovalDetailsTab;
