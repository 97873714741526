import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import Loading from "../ui/Loading";
// import useDelete from "../../hooks/useDelete";
import ProjectDiscussion from './chat/ProjectDiscussion';
import secureApiFetch from 'services/api';
import { useCallback, useEffect, useState } from 'react';

const ProjectNotesTab = ({ project }) => {
    const [notesData, setNotesData] = useState()

    const getNotes = useCallback(() => {
        secureApiFetch(`/notes?parentType=project&parentId=${project.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((res) => {
                return res.json()
            })
            .then((data) => {
                setNotesData(data)
            })
    }, [project.id])

    useEffect(() => {
        getNotes()
    }, [getNotes])

    // const deleteNoteById = useDelete('/notes/', reloadNotes)
    // const onDeleteButtonClick = (ev, note) => {
    //     ev.preventDefault();
    //     deleteNoteById(note.id);
    // }

    if (!Array.isArray(notesData)) {
        return <h1 className='text-gray-600  font-medium text-center w-full'>You don't have access.</h1>;
    }
    if (!notesData) {
        return <Loading />
    }
    return (
        <section className='-mt-1'>
            <h4 className='text-sm'>
                <MessageOutlinedIcon sx={{ color: '#3A3D89' }} />Project Discussion
            </h4>
            <div className='my-2'>
                <ProjectDiscussion
                    notes={notesData}
                    reloadNotes={getNotes}
                    parentType="project"
                    parent={project}
                />
            </div>
        </section>
    )
}

export default ProjectNotesTab;
