import React from 'react';

const TargetVlnSummary = ({ tableModel }) => {

    const riskCategories = {
        None: { label: 'None' },
        Low: { label: 'Low' },
        Medium: { label: 'Medium' },
        High: { label: 'High' },
        Critical: { label: 'Critical' }
    };

    const riskCounts = {
        None: 0,
        Low: 0,
        Medium: 0,
        High: 0,
        Critical: 0
    };

    tableModel.forEach(item => {
        const risk = item.risk;
        if (riskCounts[risk] !== undefined) {
            riskCounts[risk] += 1;
        }
    });

    return (
        <div className="flex space-x-4 justify-center my-4">
            {Object.keys(riskCounts).map((risk, index) => (
                <div
                    key={index}
                    className="bg-white border border-gray-300 rounded-lg px-3 py-1 "
                >
                    <span className="font-semibold text-sm text-gray-800">
                        {riskCategories[risk].label}:
                    </span>
                    <span className="ml-2 font-semibold text-gray-700">
                        {riskCounts[risk]}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default TargetVlnSummary;
