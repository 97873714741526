import { useCallback, useEffect } from 'react';
import isInputElement from "../../utilities/domUtils";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './Pagination.scss';

const Pagination = ({ page, total, handleNext, handlePrev }) => {
    const previousEnabled = page + 1 > 1;
    const nextEnabled = page + 1 < total;

    const onKeyDownListener = useCallback((ev) => {
        if (isInputElement(document.activeElement)) {
            return;
        }

        if (previousEnabled && ev.key === 'p') {
            ev.preventDefault();
            handlePrev();
        } else if (nextEnabled && ev.key === 'n') {
            ev.preventDefault();
            handleNext();
        }
    }, [previousEnabled, nextEnabled, handlePrev, handleNext]);

    useEffect(() => {
        document.addEventListener('keydown', onKeyDownListener);
        return () => {
            document.removeEventListener('keydown', onKeyDownListener);
        };
    }, [onKeyDownListener]);

    return (
        <div className='pagination'>
            <button className='text-gray-500' disabled={!previousEnabled} onClick={handlePrev}>
                <KeyboardArrowLeftIcon />
            </button>
            <label>{page + 1} <span className='opacity-25'>|</span> {total} </label>
            <button className='text-gray-500' disabled={!nextEnabled} onClick={handleNext}>
                <KeyboardArrowRightIcon />
            </button>
        </div>
    )
}

export default Pagination
