import { IconExclamation, IconInformation, IconShieldExclamation } from '../ui/Icons'

const RiskBadge = ({ risk, fontSize = 'fontSizeXsmall',score }) => {
    const RISKS = {
        None: { color: '#0070C0', icon: <IconInformation size='4' /> },
        Low: { color: '#48A14D', icon: <IconInformation size='4' /> },
        Medium: { color: '#F4BC1C', icon: <IconExclamation size='4' /> },
        High: { color: '#FF0000', icon: <IconShieldExclamation size='4' /> },
        Critical: { color: '#C00000', icon: <IconShieldExclamation size='4' /> }
    }
    
    const styles = {
        badge: {
            color: RISKS[risk].color,
            padding: `var(--paddingBadge)`,
            alignItems: 'center',
            display: `inline-flex`,
            borderRadius: 'var(--borderRadius, 3px)',
            border: `var(--borderWidth,2px) solid var(--${RISKS[risk].color}Dark)`,
            fontSize: `var(--badgeFontSize)`,
            fontWeight: 'var(--fontBold)'
        }
    }

    return (
        <span style={styles.badge}>
            {RISKS[risk].icon}
            {risk} ({score})
        </span>
    )
}

export default RiskBadge;
