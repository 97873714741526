import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { actionCompletedToast } from "../ui/toast";

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import ProjectApprovalForm from "./ProjectApprovalForm";
import secureApiFetch from "../../services/api";
import useFetch from "../../hooks/useFetch";
import Breadcrumb from '../ui/Breadcrumb';
import Loading from "../ui/Loading";
import Title from '../ui/Title';

const ProjectApprovalEdit = () => {
    const navigate = useNavigate();
    const { activityId } = useParams();
    const [serverActivity] = useFetch(`/projectapproval/${activityId}`);
    const [clientActivity, setClientActivity] = useState(null);

    const onFormSubmit = async (ev) => {
        ev.preventDefault();

        if (clientActivity && clientActivity.activity && clientActivity.activity[0]) {
            const updatedActivity = { ...clientActivity.activity[0] };
            updatedActivity.questions = JSON.parse(updatedActivity.questions);
            // Filter out properties with null values
            Object.keys(updatedActivity).forEach((key) => {
                if (updatedActivity[key] === null) {
                    delete updatedActivity[key];
                }
            });

            await secureApiFetch(`/projectapproval/${activityId}`, {
                method: 'PUT',
                body: JSON.stringify(updatedActivity)
            });

            actionCompletedToast(`The Activity "${updatedActivity.activity_name}" has been updated.`);
            navigate(`/projects/approvals/${activityId}`);
        } else {
            // Handle cases where clientActivity or its properties are not available
            console.error('Error: Invalid clientActivity or activity structure');
        }
    };

    useEffect(() => {
        if (serverActivity) {
            setClientActivity(serverActivity);
        }
    }, [serverActivity]);

    if (!serverActivity || !clientActivity || !clientActivity.activity || !clientActivity.activity[0]) {
        return <Loading />;
    }

    // const { activity } = clientActivity;

    return (
        <div>
            <div className="heading">
                <Breadcrumb>
                    <Link to="/projects">Projects</Link>
                    <Link to={`/projects/approvals/${serverActivity.activity[0].id}`}>{serverActivity.activity[0].activity_name}</Link>
                </Breadcrumb>
            </div>
            <Title title="Activity Details" icon={<AddOutlinedIcon />} />

            <ProjectApprovalForm
                isEdit={true}
                activity={clientActivity}
                activitySetter={setClientActivity}
                onFormSubmit={onFormSubmit}
            />
        </div>
    );
};

export default ProjectApprovalEdit;
