import { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from "react-dom";
import { Checkbox, useDisclosure } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, } from '@chakra-ui/react'

// import ProjectVulnerabilityMetrics from 'models/ProjectVulnerabilityMetrics';
import RestrictedComponent from "components/logic/RestrictedComponent";
import RemediationComplexity from 'models/RemediationComplexity';
import MarkdownEditor from 'components/ui/forms/MarkdownEditor';
import ImageMarkdown from 'components/ui/forms/ImageMarkdown';
import RemediationPriority from 'models/RemediationPriority';
import 'react-mde/lib/styles/css/react-mde-all.css';

import secureApiFetch from 'services/api';
import Risks from 'models/Risks';
import CvssAbbr from '../CvssAbbr';
import OwaspRR from '../OwaspRR';


const CloneForm = ({ isEditForm = false, vulnerability, vulnerabilitySetter: setVulnerability, onFormSubmit, type, isClone }) => {
    const [initialised, setInitialised] = useState(false);
    const [projects, setProjects] = useState(null);
    const [categories, setCategories] = useState(null);
    const [subCategories, setSubCategories] = useState(null);
    const [targets, setTargets] = useState(null);
    const [useOWASP, setMetrics] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        if (initialised) return;

        Promise.all([
            secureApiFetch(`/projects`, { method: 'GET' }),
            secureApiFetch(`/vulnerabilities/categories`, { method: 'GET' }),
        ])
            .then((resp) => Promise.all([resp[0].json(), resp[1].json()]))
            .then(([projects, categories]) => {
                const defaultProjectId = projects.length ? projects[0].id : 0;
                const projectId = isEditForm ? vulnerability.project_id : defaultProjectId;

                // Additional logic for subcategories
                let subcategoriesPromise = null;
                if (vulnerability.parent_category_id) {
                    subcategoriesPromise = secureApiFetch(`/vulnerabilities/categories/${vulnerability.parent_category_id}`, { method: 'GET' })
                        .then((response) => response.json());
                }

                return Promise.all([
                    Promise.resolve(projects),
                    Promise.resolve(categories),
                    subcategoriesPromise,
                    secureApiFetch(`/targets?projectId=${projectId}`, { method: 'GET' }).then((resp) => resp.json()),
                ]);
            })
            .then(([projects, categories, subcategories, targets]) => {
                unstable_batchedUpdates(() => {
                    setProjects(projects);
                    setCategories(categories);
                    setTargets(targets);
                    setSubCategories(subcategories || null); // Set subcategories or null if not loaded

                    setVulnerability((prevVulnerability) => {
                        let updatedVulnerability = { ...prevVulnerability };
                        const defaultProjectId = projects.length ? projects[0].id : 0;

                        if (!idExists(projects, prevVulnerability.project_id)) {
                            updatedVulnerability.project_id = defaultProjectId;
                        }

                        if ((!idExists(categories, prevVulnerability.category_id)) && (!idExists(subcategories, prevVulnerability.category_id))) {
                            updatedVulnerability.category_id = categories[0].id;
                        }

                        if (!idExists(targets, vulnerability.target_id)) {
                            updatedVulnerability.target_id = null;
                        }

                        return updatedVulnerability;
                    });

                    setInitialised(true);
                });
            });
    }, [initialised, isEditForm, setProjects, setCategories, setTargets, setMetrics, setVulnerability, vulnerability.target_id, vulnerability.project_id, vulnerability.parent_category_id, subCategories, setSubCategories]);

    useEffect(() => {
        if (!initialised) return;

        if (vulnerability.parent_category_id) {
            secureApiFetch(`/vulnerabilities/categories/${vulnerability.parent_category_id}`, { method: 'GET' })
                .then(response => response.json())
                .then(json => {
                    setSubCategories(json);
                })
        }

        const projectId = vulnerability.project_id;
        secureApiFetch(`/targets?projectId=${projectId}`, { method: 'GET' })
            .then(resp => resp.json())
            .then(targets => {
                unstable_batchedUpdates(() => {
                    setTargets(targets);
                    if (isEditForm) { // Edit
                        if (!idExists(targets, vulnerability.target_id)) {
                            setVulnerability(prevVulnerability => {
                                return { ...prevVulnerability, target_id: 0 }
                            });
                        }
                    }
                });
            })
    }, [initialised, isEditForm, setTargets, setVulnerability, vulnerability.target_id, vulnerability.project_id, vulnerability.parent_category_id]);

    const idExists = (elements, id) => {
        if (!Array.isArray(elements)) return false;
        for (const el of elements) {
            if (el.id === parseInt(id)) return true;
        }
        return false;
    };


    // const isOwaspProject = (elements, id) => {
    //     let metrics = ProjectVulnerabilityMetrics[0].id;
    //     for (const el of elements) {
    //         if (el.id === parseInt(id)) {
    //             metrics = el.vulnerability_metrics;
    //         }
    //     }
    //     return (ProjectVulnerabilityMetrics[1].id === metrics);
    // }

    const cvssData = JSON.parse(localStorage.getItem('cvss_data'));
    useEffect(() => {
        if (cvssData) {
            setVulnerability(prevVulnerability => ({
                ...prevVulnerability,
                cvss_score: cvssData.score,
                cvss_vector: cvssData.vector,
                risk: cvssData.severity
            }));
        }
    }, [setVulnerability, cvssData]);

    const onFormChange = (ev) => {
        const target = ev.target;
        const name = target.name;
        let value = target.type === 'checkbox' ? target.checked : target.value;

        if ('tags' === name) {
            value = JSON.stringify(value.split(','));
        }

        if ('parent_category_id' === name) {
            if (value !== '(none)') {
                secureApiFetch(`/vulnerabilities/categories/${value}`, { method: 'GET' })
                    .then((response) => response.json())
                    .then((json) => {
                        setSubCategories(json);
                    });
                setVulnerability({
                    ...vulnerability,
                    parent_category_id: value,
                    category_id: value === '(none)' ? null : value,
                });
            } else {
                setSubCategories(null);
                setVulnerability({
                    ...vulnerability,
                    parent_category_id: null,
                    category_id: null,
                });
            }
        } else if ('category_id' === name) {
            setVulnerability({ ...vulnerability, category_id: value === '(none)' ? null : value });
        } else {
            setVulnerability({ ...vulnerability, [name]: value });
        }
    };

    return (
        <form onSubmit={onFormSubmit} className="crud">
            {
                !vulnerability.is_template &&
                <div>
                    <label className="text-xl font-medium text-black">Relations</label>
                    <hr className='h-px bg-[#3A3D89]' />
                    <label>
                        <span className="font-medium text-xs">Project</span>
                        <select name="project_id" value={vulnerability.project_id || ""} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required>
                            {projects && projects.map((project, index) =>
                                <option key={index} value={project.id}>{project.name}</option>
                            )}
                        </select>
                    </label>
                </div>

            }
            <label className="text-xl font-medium text-black">Basic information</label>
            <hr className='h-[0.5px] bg-[#3A3D89]' />
            <RestrictedComponent roles={['administrator', 'superuser', 'user']}>
                <label>
                    <span className="font-medium text-xs">Properties</span>
                    <div>
                        <Checkbox borderColor='gray.300' name="is_template" onChange={onFormChange} isChecked={vulnerability.is_template}>Is template</Checkbox>
                    </div>
                </label>
            </RestrictedComponent>
            <label>
                <span className="font-medium text-xs">External ID</span>
                <input type="text" name="external_id" value={vulnerability.external_id || ""} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
            </label>
            <label>
                <span className="font-medium text-xs text-red-600">Title*</span>
                <input type="text" name="summary" value={vulnerability.summary || ""} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
            </label>

            <label>
                <span className="font-medium text-xs">Description</span>
                <MarkdownEditor color='white' name="description" value={vulnerability.description || ""} onChange={onFormChange} />
            </label>
            {!vulnerability.is_template &&
                <label>
                    <span className="font-medium text-xs text-red-600">Affected target*</span>
                    <select name="target_id" value={vulnerability.target_id || ""} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required>
                        <option value="0">(none)</option>
                        {Array.isArray(targets) && targets.map((target, index) => {
                            if (target.success === false && target.msg === "Access Denied : to Target") {
                                return null;
                            }
                            return <option key={index} value={target.id}>{target.name}</option>;
                        })}

                    </select>
                </label>
            }
            {!vulnerability.is_template && (
                <label>
                    <span className="font-medium text-xs text-red-600">Affected Resource*</span>
                    <input type="text" name="affected_resource" value={vulnerability.affected_resource || ""} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                </label>
            )}
            <label>
                <span className="font-medium text-xs">External references</span>
                <MarkdownEditor name="external_refs" value={vulnerability.external_refs || ""} onChange={onFormChange} />
            </label>
            <label>
                <span className="font-medium text-xs">Category</span>
                <select name="parent_category_id" value={vulnerability.parent_category_id || ""} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required>
                    <option>(none)</option>
                    {categories && categories.map(cat =>
                        <option key={cat.id} value={cat.id}>{cat.name}</option>
                    )}
                </select>
            </label>
            <label>
                <span className="font-medium text-xs">Subcategory</span>
                <select name="category_id" value={vulnerability.category_id || ""}
                    onChange={onFormChange}
                    className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'
                    required
                >
                    {vulnerability.parent_category_id ?
                        <option value="">(none)</option>
                        : <option>(none)</option>
                    }
                    {subCategories && subCategories.map(subcat =>
                        <option key={subcat.id} value={subcat.id}>{subcat.name}</option>
                    )}
                </select>
            </label>

            <label>
                <span className="font-medium text-xs">Risk</span>
                <select name="risk" value={vulnerability.risk || ""} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required>
                    {Risks.map(risk =>
                        <option key={risk.id} value={risk.id}>{risk.name}</option>
                    )}
                </select>
            </label>
            <label>
                <span className="font-medium text-xs">Tags</span>
                <input type="text" name="tags" onChange={onFormChange} value={vulnerability.tags ? JSON.parse(vulnerability.tags).join(',') : ''} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
            </label>
            {isEditForm &&
                <label>
                    <span className="font-medium text-xs">Proof of concept</span>
                    <ImageMarkdown parentType={type} parentNameId={vulnerability.project_id} name="proof_of_concept" value={vulnerability.proof_of_concept || ""} onChange={onFormChange} />
                </label>
            }
            <label>
                <span className="font-medium text-xs">Impact</span>
                <MarkdownEditor name="impact" value={vulnerability.impact || ""} onChange={onFormChange} />
            </label>
            {
                !useOWASP && <>
                    <label>
                        <span className="font-medium bg-[#3A3D89] px-2 py-1 rounded text-white text-xs w-36 text-center cursor-pointer" onClick={onOpen}>
                            Open CVSS Calculator
                        </span>
                    </label>
                    <label>
                        <span className="font-medium text-xs"> CVSS Score</span>
                        <input type="number" step="0.1" min="0" max="10" name="cvss_score" value={vulnerability.cvss_score || ""} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                    </label>

                    <label>
                        <span className="font-medium text-xs"><CvssAbbr /> vector</span>
                        <input type="text" name="cvss_vector" value={vulnerability.cvss_vector || ""} onChange={onFormChange} placeholder="eg: AV:N/AC:L/Au:S/C:P/I:P/A:N" className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                    </label>
                </>
            }
            {useOWASP &&
                <div>
                    <h2>Owasp Risk Rating calculator </h2>
                    <label>Owasp Risk Rating</label>
                    <OwaspRR vulnerability={vulnerability} vulnerabilitySetter={setVulnerability} />
                </div>
            }
            <label className="text-xl font-medium text-black">Remediation</label>
            <hr className='h-px bg-[#3A3D89]' />
            <label>
                <span className="font-medium text-xs">Remediation instructions</span>
                <MarkdownEditor name="remediation" value={vulnerability.remediation || ""} onChange={onFormChange} />
            </label>
            <label>
                <span className="font-medium text-xs text-red-600">Remediation complexity*</span>
                <select name="remediation_complexity" value={vulnerability.remediation_complexity || ""} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required>
                    {!isEditForm && (
                        <option value="">Please Select Complexity</option>
                    )}
                    {RemediationComplexity.map(complexity =>
                        <option key={complexity.id} value={complexity.id}>{complexity.name}</option>
                    )}
                </select>
            </label>
            <label>
                <span className="font-medium text-xs text-red-600">Remediation priority*</span>
                <select name="remediation_priority" value={vulnerability.remediation_priority || ""} onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required>
                    {!isEditForm && (
                        <option value="">Please Select Priority</option>
                    )}
                    {RemediationPriority.map(priority =>
                        <option key={priority.id} value={priority.id}>{priority.name}</option>
                    )}
                </select>
            </label>
            <div className="fixed bottom-10 right-4">
                <button type='submit' className='bg-[#3A3D89] px-4 py-2 rounded text-white text-sm'>
                    {isEditForm ? "Update Vulnerability" : "Create Vulnerability"}
                </button>
            </div>
            <>


                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent minW="50vw" minH='70vh'>
                        <ModalHeader>CVSS v3 Base Score Calculator</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <div>
                                <iframe title='CVSS Calculator' src="/cvssCal/cvssCalculator.html" frameborder="0" width="100%" height="450px"></iframe>
                            </div>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </>
        </form >
    )
}

export default CloneForm;
