import React from "react";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PageTitle from "components/logic/PageTitle";
import Breadcrumb from "../ui/Breadcrumb";
import Title from "../ui/Title";

const SettingsIndexPage = () => {
    return <div>
        <PageTitle value="Settings" />
        <div className='heading'>
            <Breadcrumb>
                <span>Settings</span>
            </Breadcrumb>
        </div>
        <Title title="Settings" icon={<ManageAccountsIcon />} />

        <p>Click on one of the items on the left.</p>
    </div>
};

export default SettingsIndexPage;
