import { Link } from "react-router-dom";
import { Checkbox, Switch, Tooltip } from "@chakra-ui/react";

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestrictedComponent from "components/logic/RestrictedComponent";
import ProjectBadge from "components/projects/ProjectBadge";
import LoadingTableRow from "components/ui/tables/LoadingTableRow";
import NoResultsTableRow from "components/ui/tables/NoResultsTableRow";
import Tags from "components/ui/Tags";
import secureApiFetch from "services/api";

import RiskBadge from '../badges/RiskBadge';
import VulnerabilityBadge from '../badges/VulnerabilityBadge';
import VulnerabilityStatusBadge from "./StatusBadge";
import { actionCompletedToast, errorToast } from "components/ui/toast";
import { useState } from "react";

const VulnerabilitiesTable = ({ tableModel, tableModelSetter: setTableModel, reloadCallback, showSelection = true, showProjectColumn = true }) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const onSelectionChange = ev => {
        const target = ev.target;
        const selectionId = parseInt(target.value);
        if (target.checked) {
            setTableModel({ ...tableModel, selection: [...tableModel.selection, selectionId] })
        } else {
            setTableModel({ ...tableModel, selection: tableModel.selection.filter(value => value !== selectionId) })
        }
    };

    const onHeaderCheckboxClick = ev => {
        if (ev.target.checked) {
            setTableModel({ ...tableModel, selection: tableModel.vulnerabilities.map(vulnerability => vulnerability.id) })
        } else {
            setTableModel({ ...tableModel, selection: [] })
        }
    }

    const onVisibilityToggle = (id, isChecked) => {
        const newVisibility = isChecked ? 'public' : 'private';
        secureApiFetch(`/vulnerabilities/${id}`, {
            method: 'PUT',
            body: JSON.stringify({ visibility: newVisibility })
        })
            .then(response => {
                if (response.ok) {
                    setTableModel(prevTable => {
                        return {
                            ...prevTable,
                            vulnerabilities: prevTable.vulnerabilities.map(vulnerability => {
                                if (vulnerability.id === id) {
                                    return { ...vulnerability, visibility: newVisibility };
                                }
                                return vulnerability;
                            })
                        };
                    });
                } else {
                    console.error('Error updating visibility:', response.status);
                }
            })
            .catch(error => {
                console.error('Error updating visibility:', error);
            });
    };
    const numColumns = 6 + (showSelection ? 1 : 0) + (showProjectColumn ? 1 : 0);
    const vulnerabilitiesLength = null !== tableModel.vulnerabilities ? tableModel.vulnerabilities.length : 0;

    const handleDelete = (id) => {
        let confirmDeletion = window.confirm('Do you really want to delete this vulnerability?');
        if (!confirmDeletion) {
            return;
        }
        setIsDeleting(true);
        secureApiFetch(`/vulnerabilities/${id}`, {
            method: 'DELETE',
        })
            .then(response => {
                if (response.status === 403) {
                    console.error('Error 403: Forbidden');
                    actionCompletedToast('You do not have permission to delete this item.');
                    setIsDeleting(false);
                    return false;
                }
                if (response.status === 200) {
                    actionCompletedToast('The vulnerability has been deleted successfully.');
                    reloadCallback();
                    setIsDeleting(false);
                    return true;
                } else {
                    console.error(`Error ${response.status}: ${response.statusText}`);
                    errorToast('There was an issue deleting the item. Please try again.');
                    setIsDeleting(false);
                    return false;
                }
            })
            .catch(error => {
                console.error('Error during deletion:', error);
                errorToast('An unexpected error occurred. Please try again.');
                setIsDeleting(false);
            });
    }

    return (
        <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
            <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                    <tr>
                        <th scope="col" className="px-1 py-2">{showSelection && <Checkbox borderColor='gray.300' onChange={onHeaderCheckboxClick} isChecked={tableModel.selection.length && tableModel.selection.length === vulnerabilitiesLength} isDisabled={tableModel.vulnerabilitiesLength === 0} />}</th>
                        <th scope="col" className="px-2 py-2">Summary</th>
                        {showProjectColumn && <th scope="col" className="px-2 py-2">Project</th>}
                        <th scope="col" className="px-1 py-2">Status</th>
                        <th scope="col" className="px-1 py-2">Risk</th>
                        <th scope="col" className="px-2 py-2">SubCategory</th>
                        <th scope="col" className="px-1 py-2">Visibility</th>
                        <th scope="col" className="px-1 py-2 text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {null === tableModel.vulnerabilities &&
                        <LoadingTableRow numColumns={numColumns} />}
                    {null !== tableModel.vulnerabilities && 0 === tableModel.vulnerabilities.length &&
                        <NoResultsTableRow numColumns={9} />}
                    {null !== tableModel.vulnerabilities && tableModel.vulnerabilities.length > 0 &&
                        tableModel.vulnerabilities.map((vulnerability, index) => {
                            return (
                                <tr key={index} className="bg-white border-b border-gray-300 text-black font-light">

                                    <th scope="row" className="px-1 py-2 ">
                                        {showSelection &&
                                            <Checkbox borderColor='gray.300' value={vulnerability.id} onChange={onSelectionChange} isChecked={tableModel.selection.includes(vulnerability.id)} />
                                        }
                                    </th>

                                    <th scope="row" className="px-1 py-1 font-medium">
                                        <VulnerabilityBadge vulnerability={vulnerability} />
                                        <div><Tags values={vulnerability.tags} /></div>
                                    </th>

                                    {showProjectColumn &&
                                        <th scope="row" className="px-1 py-2">
                                            {vulnerability.is_template
                                                ? <span title="Not applicable">(n/a)</span>
                                                : <ProjectBadge project={{ id: vulnerability.project_id, name: vulnerability.project_name }} />}
                                        </th>
                                    }

                                    <th scope="row" className="px-1 py-1"><VulnerabilityStatusBadge vulnerability={vulnerability} /></th>

                                    <th scope="row" className="px-1 py-1">
                                        <RiskBadge risk={vulnerability.risk} score={vulnerability.cvss_score} />
                                    </th>


                                    <th scope="row" className="px-2 py-1">
                                        <Tooltip label= {vulnerability.parent_category_name || '-'} aria-label='A tooltip'>
                                            {vulnerability.category_name || '-'}
                                        </Tooltip>
                                    </th>

                                    <th scope="row" className="px-1 py-1">
                                        {vulnerability.visibility === 'private' ? <span className="bg-red-500 px-1.5 py-1 rounded text-white text-xs">
                                            Private
                                        </span> : <span className="bg-[#48A14D] px-1.5 py-1 rounded text-white text-xs">
                                            Published
                                        </span>}
                                    </th>

                                    <th scope="row" className="px-1 py-1 text-center">
                                        <RestrictedComponent roles={['administrator', 'superuser', 'user']}>
                                            <span className="flex items-center space-x-1">
                                                <Switch isChecked={vulnerability.visibility === 'public'} onChange={(event) => onVisibilityToggle(vulnerability.id, event.target.checked)} size='sm' />
                                                <Link to={`/vulnerabilities/${vulnerability.id}/edit`} >
                                                    <EditIcon className="text-[#3A3D89]" fontSize="small" />
                                                </Link>
                                                {reloadCallback &&
                                                    <button onClick={() => handleDelete(vulnerability.id)} disabled={isDeleting}>
                                                        <DeleteIcon className="text-[#EB1F36]" fontSize="small" />
                                                    </button>
                                                }
                                            </span>
                                        </RestrictedComponent>
                                    </th>
                                </tr>
                            )
                        })}
                </tbody>
            </table>
        </div>

    )
}

export default VulnerabilitiesTable;
