import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, TabPanels, Tabs, } from "@chakra-ui/react";
import PageTitle from 'components/logic/PageTitle';
import RestrictedComponent from 'components/logic/RestrictedComponent';
import TimestampsSection from 'components/ui/TimestampsSection';
import secureApiFetch from 'services/api';
import useDelete from '../../hooks/useDelete';
import useFetch from '../../hooks/useFetch';
import Badge from "../badges/Badge";
import Breadcrumb from "../ui/Breadcrumb";
import DeleteButton from '../ui/buttons/Delete';
import Loading from '../ui/Loading';
import Title from '../ui/Title';
import VulnerabilitiesTable from "../vulnerabilities/VulnerabilitiesTable";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import VulnerabilityTableModel from 'components/vulnerabilities/VulnerabilityTableModel';
import TargetVlnChart from './TargetVlnChart';


const TargetView = () => {
    const navigate = useNavigate();
    const { targetId } = useParams();
    const [target] = useFetch(`/targets/${targetId}`)
    const destroy = useDelete(`/targets/`);
    const [savedProject, setSavedProject] = useState(null);

    const [tableModel, setTableModel] = useState(new VulnerabilityTableModel())

    const fetchVulnerabilities = useCallback(() => {
        const queryParams = new URLSearchParams();
        queryParams.set('targetId', targetId);
        queryParams.set('isTemplate', false);
        queryParams.set('orderColumn', tableModel.sortBy.column);
        queryParams.set('orderDirection', tableModel.sortBy.order);
        Object.keys(tableModel.filters)
            .forEach(key => tableModel.filters[key] !== null && tableModel.filters[key].length !== 0 && queryParams.append(key, tableModel.filters[key]));
        const url = `/vulnerabilities?${queryParams.toString()}`;
        secureApiFetch(url, { method: 'GET' })
            .then(resp => resp.json())
            .then(data => {
                setTableModel(tableModel => ({ ...tableModel, vulnerabilities: data }));
            })
    }, [tableModel.filters, tableModel.sortBy, targetId])


    useEffect(() => {
        if (target) {
            secureApiFetch(`/projects/${target.project_id}`, { method: 'GET', headers: {} })
                .then(resp => resp.json())
                .then(json => {
                    setSavedProject(json);
                })
        }
    }, [target])

    const handleDelete = () => {
        destroy(targetId)
            .then(success => {
                if (success)
                    navigate('/projects');
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        fetchVulnerabilities();
    }, [fetchVulnerabilities, tableModel.filters]);

    if (!target) return <Loading />

    return (
        <div>
            <PageTitle value={`Target ${target.name}`} />
            <div className='heading'>
                <Breadcrumb>
                    <Link to="/projects">Projects</Link>
                    {savedProject &&
                        <Link to={`/projects/${savedProject.id}`}>{savedProject.name}</Link>}
                </Breadcrumb>
                <RestrictedComponent roles={['administrator', 'superuser', 'user']}>
                    <DeleteButton onClick={handleDelete} />
                </RestrictedComponent>
            </div>
            <div className='w-full'>
                <div className='flex items-baseline justify-between w-full'>
                <Title type='Target' title={target.name} icon={<FolderOpenIcon />} target={true} />
                <TargetVlnChart tableModel={tableModel.vulnerabilities} />
                </div>
                <div>
                    <Tabs variant='enclosed'>
                        <TabList>
                            <Tab> <span className='text-xs font-medium'>Details</span> </Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <section className="grid grid-two -mt-1 text-xs">
                                    <div>
                                        <h4 className='text-sm'><InsertDriveFileOutlinedIcon sx={{ color: '#3A3D89' }} />Details</h4>
                                        <dl>
                                            <dt>Kind</dt>
                                            <dd>
                                                <Badge color={target.kind === 'hostname' ? 'green' : 'blue'}>{target.kind}</Badge>
                                            </dd>
                                            <dt>Tags</dt>
                                            <dd>
                                                {target.tags && (
                                                    JSON.parse(target.tags).map((item) => (
                                                        <button className='bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded'>
                                                            {item}
                                                        </button>
                                                    ))
                                                )}
                                            </dd>

                                        </dl>
                                    </div>

                                    <div>
                                        <h4 className='text-sm font-medium'> <InsertLinkOutlinedIcon sx={{ color: '#3A3D89' }} />Additional Details</h4>
                                        <dl>
                                            <dt>Number Of Vulnerability</dt>
                                            <dd>
                                                {target.num_vulnerabilities || '-'}
                                            </dd>
                                            <dt><TimestampsSection entity={target} /></dt>
                                        </dl>
                                    </div>
                                </section>

                                <h4 className='-mt-10'>Vulnerabilities</h4>
                                <VulnerabilitiesTable
                                    tableModel={tableModel}
                                    tableModelSetter={setTableModel}
                                    reloadCallback={fetchVulnerabilities}
                                    showProjectColumn={false}
                                    showSelection={false}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default TargetView;
