import Configuration from "Configuration";
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import UpcomingOutlinedIcon from '@mui/icons-material/UpcomingOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CategorySharpIcon from '@mui/icons-material/CategorySharp';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Diversity2OutlinedIcon from '@mui/icons-material/Diversity2Outlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import PreviewIcon from '@mui/icons-material/Preview';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
// import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
// import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';

const Links = [
    {
        title: 'Projects',
        icon: <AccountTreeOutlinedIcon size={5} />,
        to: '/',
        sublinks: [
            {
                title: 'Ongoing Projects',
                icon: <AccountTreeOutlinedIcon size={5} />,
                to: '/projects',
                permissions: 'projects.list'
            },
            {
                title: 'Upcoming Projects',
                icon: <UpcomingOutlinedIcon size={5} />,
                to: '/projects/approvals',
                permissions: 'projects.approvals'
            },
            {
                title: 'Task',
                icon: <ListAltIcon size={5} />,
                to: '/tasks',
                permissions: 'tasks.list'
            },
            {
                title: 'Commands',
                icon: <ForumOutlinedIcon size={5} />,
                to: '/commands',
                permissions: 'commands.*'
            },
        ],
        permissions: 'projects.list'
    },
    {
        title: 'Vulnerabilities', icon: <BugReportOutlinedIcon size={5} />, to: '/vulnerabilities', sublinks: [
            {
                title: 'List of Vulnerabilities',
                icon: <BugReportOutlinedIcon size={5} />,
                to: '/vulnerabilities',
                permissions: 'vulnerabilities.templates'
            },
            {
                title: 'Templates',
                icon: <DescriptionOutlinedIcon size={5} />,
                to: '/vulnerabilities/templates',
                permissions: 'vulnerabilities.templates'
            },
            {
                title: 'Categories',
                icon: <CategorySharpIcon size={5} />,
                to: '/vulnerabilities/categories',
                permissions: 'vulnerabilities.categories'
            }
        ],
        permissions: 'vulnerabilities.*'
    },
    {
        title: 'Reports', icon: <SummarizeOutlinedIcon size={5} />, to: '/reports', sublinks: [
            {
                title: 'List of Reports',
                icon: <SummarizeOutlinedIcon size={5} />,
                to: '/reports',
                permissions: 'reports.templates'
            },
            {
                title: 'Templates',
                icon: <DescriptionOutlinedIcon size={5} />,
                to: '/reports/templates',
                permissions: 'reports.templates'
            }
        ],
        permissions: 'reports.*'
    },
]

export const GroupOne = [
    {
        title: 'Documents',
        icon: <ArticleOutlinedIcon size={5} />,
        to: '/documents',
        sublinks: [],
        permissions: 'documents.*'
    },
    {
        title: 'Clients',
        icon: <PortraitOutlinedIcon size={5} />,
        to: '/clients',
        sublinks: [],
        permissions: 'clients.*'
    },
]

export const GroupTwo = [
    {
        title: 'Users',
        icon: <PeopleAltOutlinedIcon size={5} />,
        to: '/users',
        sublinks: [],
        permissions: 'users.*'
    },
    {
        title: 'Settings',
        icon: <SettingsOutlinedIcon />,
        to: '/settings',
        sublinks: [
            {
                title: 'Organisation',
                icon: <Diversity2OutlinedIcon />,
                to: '/settings/organisation'
            },
            {
                title: 'Project Category',
                icon: <CategorySharpIcon />,
                to: '/settings/projectCategory'
            },
            {
                title: 'Category Question',
                icon: <LiveHelpOutlinedIcon />,
                to: '/settings/categoryQuestion'
            },
        ],
        permissions: 'settings.*'
    },
    {
        title: 'System',
        icon: <BuildOutlinedIcon />,
        to: '/system',
        permissions: 'system.*',
        sublinks: [
            {
                title: 'Audit log',
                icon: <PreviewIcon size={5} />,
                to: '/auditlog'
            },
            {
                title: 'Import data',
                icon: <FileDownloadOutlinedIcon size={5} />,
                to: '/system/import-data',
            },
            {
                title: 'Export data',
                icon: <FileUploadOutlinedIcon size={5} />,
                to: '/system/export-data',
            },
            {
                title: 'Usage',
                icon: <ViewInArOutlinedIcon size={5} />,
                to: '/system/usage',
            },
            {
                title: 'Health',
                icon: <HealthAndSafetyOutlinedIcon size={5} />,
                to: '/system/health',
            },
        ]
    }
]
export const GroupThree = [
    {
        title: 'Help and support',
        icon: <HelpOutlinedIcon size={5} />,
        to: '/help',
        sublinks: [
            {
                title: 'User manual',
                icon: <MenuBookOutlinedIcon size={5} />,
                to: '/userManual'
            },
            {
                title: 'API docs',
                icon: <CodeOutlinedIcon size={5} />,
                to: `${Configuration.getDefaultApiUrl()}/docs/`,
                external: true,
                permissions: 'help.api',
            },
            {
                title: 'Support',
                icon: <SupportAgentOutlinedIcon size={5} />,
                to: 'https://support.prosecurity.work/',
                external: true,
                permissions: 'help.support',
            },
        ]
    }
]

export default Links;
