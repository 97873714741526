import React from 'react'

import useFetch from 'hooks/useFetch';

const ClientBoxData = (clientId) => {
    const [clientsProjects] = useFetch(`/projects?clientId=${clientId.clientId}`);
    const countProjectStatus = (status) => {
        return clientsProjects?.filter(project => project.project_status === status).length || 0;
    };
    const totalProjects = clientsProjects?.length || 0;

    return (
        <div className="flex space-x-4 text-xs">

            <div className="flex py-1.5 px-3 items-center rounded text-[#0070C0] bg-gray-100 font-medium text-center">
                <p>Completed: &nbsp;</p>
                <p>{countProjectStatus('Completed')}</p>
            </div>
            <div className="flex py-1.5 px-3 items-center rounded text-[#FF0000] bg-gray-100 font-medium text-center">
                <p>On Hold: &nbsp;</p>
                <p>{countProjectStatus('On Hold')}</p>
            </div>
            <div className="flex py-1.5 px-3 items-center rounded text-[#00B050] bg-gray-100 font-medium text-center">
                <p>In Progress: &nbsp;</p>
                <p>{countProjectStatus('In Progress')}</p>
            </div>
            <div className="flex py-1.5 px-3 items-center rounded text-[#ffc000] bg-gray-100 font-medium text-center">
                <p>To Start: &nbsp;</p>
                <p>{countProjectStatus('To Start')}</p>
            </div>
            <div className="flex py-1.5 px-3 items-center rounded text-blue-700 bg-gray-100 font-medium text-center">
                <p>Total: &nbsp;</p>
                <p>{totalProjects}</p>
            </div>
        </div>
    )
}

export default ClientBoxData