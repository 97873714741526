import useFetch from "hooks/useFetch";
import { TaskPriorityList } from "models/TaskPriority";
import TaskStatuses from "models/TaskStatuses";
import Auth from "services/auth";

const TaskFilters = ({ tableModel, tableModelSetter: setTableModel }) => {
    const loggedInUser = Auth.getLoggedInUser();
    const [projects] = useFetch('/projects');
    const [users] = useFetch('/users');

    const onFilterChange = ev => {
        setTableModel({ ...tableModel, filters: { ...tableModel.filters, [ev.target.name]: ev.target.value } });
    }

    return (
        <div className="flex w-full space-x-3 px-3 pb-2 rounded-lg justify-end">
            <div>
                <select name="projectId" onChange={onFilterChange} className="px-2 py-1 border max-w-44 rounded text-sm">
                    <option value="">Project</option>
                    {null !== projects && projects.map(project => <option key={project.id} value={project.id}>{project.name}</option>)}
                </select>
            </div>
            <div>
                <select name="priority" onChange={onFilterChange} className="px-2 py-1 border rounded text-sm">
                    <option value="">Priority</option>
                    {TaskPriorityList.map(priority => <option key={`priority_${priority.value}`} value={priority.value}>{priority.name}</option>)}
                </select>
            </div>
            <div>
                <select name="assigneeUid" onChange={onFilterChange} className="px-2 py-1 border rounded text-sm">
                    <option value="">Assignee</option>
                    {null !== users && users.map(user => <option key={user.id} value={user.id}>{user.full_name}{user.id === loggedInUser.id ? " (You)" : ""}</option>)}
                </select>
            </div>
            <div>
                <select name="status" onChange={onFilterChange} className="px-2 py-1 border rounded text-sm">
                    <option value="">Status</option>
                    {TaskStatuses.map(status => <option key={`taskstatus_${status.id}`} value={status.id}>{status.name}</option>)}
                </select>
            </div>
        </div>
    )
}

export default TaskFilters;
