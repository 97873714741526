import React from "react";

import PageTitle from "components/logic/PageTitle";
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import Breadcrumb from "../ui/Breadcrumb";
import Title from "../ui/Title";
import { Link } from "react-router-dom";

const SystemIndexPage = () => {
    return <div>
        <PageTitle value="System" />
        <div className='heading'>
            <Breadcrumb>
                <Link to='/system'> System </Link>
            </Breadcrumb>
        </div>
        <Title title="System" icon={<SettingsSuggestOutlinedIcon />} />

        <p>Click on one of the items on the left.</p>

    </div>
};

export default SystemIndexPage;
