// import { Button } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

interface SecondaryButtonProps {
    onClick: any;
    children: any;
    to?: string;
    disabled?: boolean;
    external?: boolean;
    tooltip?: string;
    leftIcon?: ReactElement
}

const SecondaryButton = ({ onClick, children, to = "", disabled = false, external = false, tooltip, leftIcon = undefined, ...props }: SecondaryButtonProps) => {
    const navigate = useNavigate();
    const handleOpen = () => {
        external ? window.open(to, '_blank') : navigate(to)
    }

    return (
        <div>
            {/* <Button {...props} leftIcon={leftIcon} colorScheme='blackAlpha'
                title={tooltip} onClick={onClick || handleOpen} isDisabled={disabled}>
                {children}
            </Button> */}
            <button {...props} onClick={onClick || handleOpen} disabled={disabled} className='inline-flex border border-gray-200 items-center px-3 py-1 text-gray-800 bg-gray-100 rounded-md '>
            {children}
            </button>
        </div>
    )
}

export default SecondaryButton;