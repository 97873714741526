

const Organisation = {
    name: "",
    url: "",
    contactName: null,
    contactEmail: null,
    contactPhone: null,
    logo_attachment_id: null,
    small_logo_attachment_id: null
};

export default Organisation;
