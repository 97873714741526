// src/VulnerabilitiesByRiskStatsWidget.js

import React, { useEffect, useMemo, useState } from 'react';
import useFetch from 'hooks/useFetch';
import Chart from 'react-apexcharts';
import DashboardWidget from './Widget';

const VulnerabilitiesByRiskStatsWidget = ({ projectId = null }) => {
    const url = '/vulnerabilities/stats?groupBy=risk' + (projectId ? `&projectId=${encodeURIComponent(projectId)}` : '');

    const [vulnerabilitiesByRiskStats] = useFetch(url);
    const [series, setSeries] = useState([]);
    const [labels, setLabels] = useState([]);
    const [colors, setColors] = useState([]);

    const RISKS = useMemo(() => ({
        'None': { label: 'None', color: '#0070C0' },
        'Low': { label: 'Low', color: '#92D050' },
        'Medium': { label: 'Medium', color: '#FFC000' },
        'High': { label: 'High', color: '#FF0000' },
        'Critical': { label: 'Critical', color: '#C00000' }
    }), []);

    useEffect(() => {
        if (vulnerabilitiesByRiskStats) {
            const dataSeries = vulnerabilitiesByRiskStats.map(item => item.total);
            const dataLabels = vulnerabilitiesByRiskStats.map(item => item.risk);
            const dataColors = vulnerabilitiesByRiskStats.map(item => RISKS[item.risk]?.color || '#000000');

            setSeries(dataSeries);
            setLabels(dataLabels);
            setColors(dataColors);
        }
    }, [vulnerabilitiesByRiskStats, RISKS]);

    const options = {
        chart: {
            type: 'pie',
        },
        labels: labels,
        colors: colors,
        legend: {
            position: 'bottom'
        },
        responsive: [{
            breakpoint: 300,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    return (
        <DashboardWidget title="Vulnerabilities by risk">
            <div>
                {series.length > 0
                    ? <Chart options={options} series={series} type="pie" height={300} />
                    : <p className='m-2 font-medium'>No enough data to generate the chart.</p>
                }
            </div>
        </DashboardWidget>
    );
};

export default VulnerabilitiesByRiskStatsWidget;
