import { Route } from "react-router-dom";
import SettingsIndexPage from ".";
import OrganisationForm from "./Organisation/Form";
import ProjectCategoryList from "./Project Category/List";
import CategoryQuestionList from "./Category Question/List";

const SettingsRoutes = [
    <Route path={`/settings`} element={<SettingsIndexPage />} />,
    <Route path={`/settings/organisation`} element={<OrganisationForm/>} />,
    <Route path={`/settings/categoryQuestion`} element={<CategoryQuestionList/>} />,
    <Route path={`/settings/projectCategory`} element={<ProjectCategoryList/>} />,
]

export default SettingsRoutes;
