import { useState } from "react";
import { actionCompletedToast } from "../ui/toast";
import { Textarea } from "@chakra-ui/react";

import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachmentsDropzone from "components/attachments/Dropzone";
import RestrictedComponent from "components/logic/RestrictedComponent";
import FileSizeSpan from "components/ui/FileSizeSpan";
import ModalDialog from "components/ui/ModalDIalog";
import RelativeDateFormatter from "components/ui/RelativeDateFormatter";
import LoadingTableRow from "components/ui/tables/LoadingTableRow";
import NoResultsTableRow from "components/ui/tables/NoResultsTableRow";
import UserLink from "components/users/Link";
import useFetch from "hooks/useFetch";
import secureApiFetch from "services/api";
import DownloadFromS3 from "services/DownloadFromS3";

const CommandOutputs = ({ command }) => {
    const [commandOutputs, updateCommandOutputs] = useFetch(`/attachments?parentType=command&parentId=${command.id}`)
    const [modalVisible, setModalVisible] = useState(false);
    const [content, setContent] = useState(null);

    const onDeleteOutputClick = (ev, attachmentId) => {
        ev.preventDefault();

        secureApiFetch(`/attachments/${attachmentId}`, { method: 'DELETE' })
            .then(() => {
                actionCompletedToast("The output has been deleted.");
                updateCommandOutputs();
            })
            .catch(err => console.error(err))
    }

    const onDownloadClick = (ev, attachmentId) => {
        DownloadFromS3(attachmentId)
    }

    const onViewClick = (ev, attachmentId) => {
        secureApiFetch(`/attachments/${attachmentId}`, { method: 'GET', headers: {} })
            .then(resp => {
                const contentDispositionHeader = resp.headers.get('Content-Disposition');
                const filenameRe = new RegExp(/filename="(.*)";/)
                const filename = filenameRe.exec(contentDispositionHeader)[1]
                return Promise.all([resp.blob(), filename]);
            })
            .then(async (values) => {
                const blob = values[0];
                setContent(await blob.text());
                setModalVisible(true);
            })
    }

    const onModalClose = () => {
        setModalVisible(false);
    }

    return <>
        <ModalDialog visible={modalVisible} title="Preview output" onModalClose={onModalClose} style={{ width: '80%', height: '80%', maxHeight: '80%' }}>
            <Textarea style={{ width: '100%', height: '90%' }} defaultValue={content} readOnly>
            </Textarea>
        </ModalDialog>

        <RestrictedComponent roles={['administrator', 'superuser', 'user']}>
            <AttachmentsDropzone parentType={"command"} parentId={command.id} onUploadFinished={updateCommandOutputs} />
        </RestrictedComponent>

        <h4>
            Command output list
        </h4>

        <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
            <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                    <tr>
                        <th scope="col" className="px-4 py-2">Filename</th>
                        <th scope="col" className="px-2 py-2">Mimetype</th>
                        <th scope="col" className="px-2 py-2">File size</th>
                        <th scope="col" className="px-2 py-2">Upload date</th>
                        <th scope="col" className="px-2 py-2">Uploaded by</th>
                        <th scope="col" className="px-2 py-2">View</th>
                        <th scope="col" className="px-2 py-2">Download</th>
                        <th scope="col" className="px-2 py-2">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {null === commandOutputs && <LoadingTableRow numColumns={6} />}
                    {null !== commandOutputs && commandOutputs.length === 0 && <NoResultsTableRow numColumns={6} />}
                    {null !== commandOutputs && commandOutputs.length !== 0 && commandOutputs.map((commandOutput, index) => {
                        return (
                            <tr key={index} className="bg-white border-b even:bg-gray-50 border-gray-300 text-black font-light">

                                <th scope="row" className="px-2 py-1 font-medium">
                                    {commandOutput.client_file_name}
                                </th>

                                <th scope="row" className="px-2 py-1">
                                    {commandOutput.file_mimetype}
                                </th>

                                <th scope="row" className="px-2 py-1">
                                    <FileSizeSpan fileSize={commandOutput.file_size} />
                                </th>

                                <th scope="row" className="px-2 py-1">
                                    <RelativeDateFormatter date={commandOutput.insert_ts} />
                                </th>

                                <th scope="row" className="px-2 py-1">
                                    <UserLink userId={commandOutput.submitter_uid}>{commandOutput.submitter_name}</UserLink>
                                </th>

                                <th scope="row" className="px-2 py-1">
                                    <button onClick={ev => onViewClick(ev, commandOutput.id)}>
                                        <OpenInNewOutlinedIcon className='text-[#3A3D89]' />
                                    </button>
                                </th>

                                <th scope="row" className="px-2 py-1">
                                    <button onClick={ev => onDownloadClick(ev, commandOutput.id)}>
                                        <DownloadOutlinedIcon className='text-[#3A3D89]' />
                                    </button>
                                </th>

                                <th scope="row" className="px-2 py-1">
                                    <button onClick={ev => onDeleteOutputClick(ev, commandOutput.id)}>
                                        <DeleteIcon className='text-[#EB1F36]' />
                                    </button>
                                </th>

                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    </>
}

export default CommandOutputs;
