import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './Pagination.scss';

const ProjectPagination =  ({ page, total, handleNext, handlePrev }) => {
  return (
      <>
          <div className="pagination">
              <button onClick={handlePrev} disabled={page <= 1}><KeyboardArrowLeftIcon /></button>
              <span>{page} <span className='opacity-25'>|</span> {total} </span>
              <button onClick={handleNext} disabled={page >= total}> <KeyboardArrowRightIcon /></button>
          </div>
      </>
  )
}

export default ProjectPagination