const taskData = {
    "Tasks": [
        {
            "name": null,
            "description": "",
            "parser": "",
            "summary": "",
            "due_date": "",
            "subtasks": [
                {
                    "name": null,
                    "description": "",
                    "parser": "",
                    "summary": "",
                    "due_date": ""
                },
                {
                    "name": null,
                    "description": "",
                    "parser": "",
                    "summary": "",
                    "due_date": ""
                }
            ]
        },
        {
            "name": null,
            "description": "",
            "parser": "",
            "summary": "",
            "due_date": ""
        },
        {
            "name": null,
            "description": "",
            "parser": "",
            "summary": "",
            "due_date": ""
        }
    ]
}

export default taskData