import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Alert, AlertIcon } from "@chakra-ui/react";

import LoadingTableRow from "components/ui/tables/LoadingTableRow";
import UserRoleBadge from "components/badges/UserRoleBadge";
import DeleteIcon from '@mui/icons-material/Delete';
import PageTitle from "components/logic/PageTitle";

import NoResultsTableRow from '../ui/tables/NoResultsTableRow';
import secureApiFetch from '../../services/api';
import UserAvatar from '../badges/UserAvatar';
import useFetch from '../../hooks/useFetch';
import Breadcrumb from '../ui/Breadcrumb';
import UserLink from "../users/Link";
import Title from '../ui/Title';

const ProjectMembership = () => {
    const { projectId } = useParams();
    const { client_id } = useParams();
    const [users] = useFetch(`/clients/${client_id}/users`)
    const [members, updateMembers] = useFetch(`/projects/${projectId}/users`)
    const [savedProject] = useFetch(`/projects/${projectId}`);
    const [availableUsers, setAvailableUsers] = useState([]);

    const handleOnClick = ev => {
        ev.preventDefault();
        let confirm = window.confirm('Are you sure you want to add this user to the project?')
        if (confirm) {
            const userId = document.getElementById('userId').value;
            const userData = { userId: userId };
            secureApiFetch(`/projects/${projectId}/users`, {
                method: 'POST',
                body: JSON.stringify(userData)
            }).then(() => {
                updateMembers()
            })
        }
    }

    const handleDelete = (member) => {
        secureApiFetch(`/projects/${projectId}/users/${member.membership_id}`, {
            method: 'DELETE'
        }).then(() => {
            updateMembers()
        })
    }

    useEffect(() => {
        if (members && users && users.length > 0) {
            const memberIds = members.reduce((list, user) => [...list, user.id], []);
            setAvailableUsers(users.filter(user => !memberIds.includes(user.user_id)));
        }
    }, [members, users]);

    return <div>
        <PageTitle value="Project membership" />
        <div className="heading">
            <Breadcrumb>
                <Link to="/projects">Projects</Link>
                {savedProject && <Link to={`/projects/${savedProject.id}`}>{savedProject.name}</Link>}
            </Breadcrumb>
        </div>

        <Title title='Members' />

        {availableUsers.length > 0 ?
            <form className="flex justify-center items-center space-x-2 px-2">
                <label>
                    <span className="font-medium text-xs">Select user</span>
                    <select className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' id="userId">
                        {availableUsers && availableUsers.map((user, index) =>
                            <option key={index} value={user.user_id}>{user.full_name}</option>
                        )}
                    </select>
                </label>
                <button onClick={handleOnClick} className='bg-[#3A3D89] w-44 px-4 py-1.5 rounded text-white text-sm'>
                    Add as member
                </button>
            </form> :
            <Alert status="info">
                <AlertIcon />
                All users have been added to the project.
            </Alert>
        }

        <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
            <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                    <tr>
                        <th scope="col" className="px-4 py-2"></th>
                        <th scope="col" className="px-4 py-2">Name</th>
                        <th scope="col" className="px-4 py-2">Role</th>
                        <th scope="col" className="px-4 py-2"></th>
                    </tr>
                </thead>
                <tbody>
                    {null === members &&
                        <LoadingTableRow numColumns={4} />}
                    {null !== members && 0 === members.length &&
                        <NoResultsTableRow numColumns={4} />}
                    {members && members.map((member, index) =>
                        <tr key={index} className="bg-white border-b border-gray-300 text-black font-light">
                            <td className='px-4 py-2'><UserAvatar email={member.email} /></td>
                            <td className='px-4 py-2'><UserLink userId={member.id}>{member.full_name}</UserLink></td>
                            <td className='px-4 py-2'><UserRoleBadge role={member.role} /></td>
                            <td className='px-4 py-2'>
                                <button onClick={() => handleDelete(member)}>
                                    <DeleteIcon className='text-[#EB1F36]' fontSize='small' />
                                </button>
                            </td>
                        </tr>
                    )
                    }
                </tbody>
            </table>
        </div>
    </div>
}

export default ProjectMembership;
