import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { actionCompletedToast, errorToast } from "../ui/toast";

import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import PageTitle from 'components/logic/PageTitle';

import secureApiFetch from '../../services/api';
import Breadcrumb from '../ui/Breadcrumb';
import useFetch from '../../hooks/useFetch';
import Loading from '../ui/Loading';
import Title from '../ui/Title';
import VulnerabilityForm from "./Form";

const VulnerabilityEdit = () => {
    const { vulnerabilityId } = useParams();

    const navigate = useNavigate();

    const [serverVulnerability] = useFetch(`/vulnerabilities/${vulnerabilityId}`);
    const [clientVulnerability, setClientVulnerability] = useState(null);

    const onFormSubmit = async ev => {
        ev.preventDefault();

        await secureApiFetch(`/vulnerabilities/${vulnerabilityId}`, {
            method: 'PUT',
            body: JSON.stringify(clientVulnerability)
        }).then(resp => {
            localStorage.removeItem('cvss_data')
            if (resp.status === 200) {
                actionCompletedToast(`The vulnerability "${clientVulnerability.summary}" has been updated.`);
                if (clientVulnerability.is_template) {
                    navigate(`/vulnerabilities/templates/${vulnerabilityId}`);
                } else {
                    navigate(`/vulnerabilities/${vulnerabilityId}`)
                }
            } else {
                throw new Error("Unable to save. Check form");
            }
        }).catch(err => {
            errorToast(err.message);
        })
    };

    useEffect(() => {
        if (serverVulnerability)
            setClientVulnerability(serverVulnerability);
    }, [serverVulnerability]);

    return (
        <div>
            <PageTitle value="Edit Vulnerability" />
            <div className='heading'>
                <Breadcrumb>
                    <Link to="/vulnerabilities">Vulnerabilities</Link>
                    <Link to="/vulnerabilities">Edit Vulnerability</Link>
                </Breadcrumb>
            </div>
            <Title title="Vulnerability details" icon={<BorderColorOutlinedIcon />} />
            {!clientVulnerability ? <Loading /> :
                <VulnerabilityForm type="vulnerability" isEditForm={true} vulnerability={clientVulnerability}
                    vulnerabilitySetter={setClientVulnerability}
                    onFormSubmit={onFormSubmit} />
            }
        </div>
    )
}

export default VulnerabilityEdit;
