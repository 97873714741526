import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, } from '@chakra-ui/react'
import { Checkbox, SimpleGrid, Stack, useDisclosure } from '@chakra-ui/react';
import { initialiseUserPreferences } from 'services/userPreferences';
import { actionCompletedToast } from 'components/ui/toast';

import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import PermissionsService from 'services/permissions';
import widgetIsVisible from 'services/widgets';
import Loading from 'components/ui/Loading';
import secureApiFetch from 'services/api';
import Widgets from 'models/Widgets';
import Auth from 'services/auth';

// import VulnerabilityRiskStat from './details/VulnerabilityRiskStat';
import WelcomeWidget from './widgets/WelcomeWidget';
import Title from "../../ui/Title";

const initialiseWidgetConfig = () => {
    return Object.keys(Widgets).reduce((acc, key) => {
        acc[key] = { visible: true };
        return acc;
    }, {});
};

const filterWidgets = (user, dashboardConfig) => {
    return Object.keys(Widgets).map(widgetKey => {
        const widget = Widgets[widgetKey];
        if ((!widget.hasOwnProperty("permissions") || PermissionsService.isAllowed(widget.permissions, user.permissions)) && widgetIsVisible(dashboardConfig, widgetKey)) {
            return React.cloneElement(widget.component, { key: widgetKey });
        }
        return null;
    }).filter(widget => widget !== null);
};

const useFetchUserData = (setUserId, setDashboardConfig) => {
    useEffect(() => {
        secureApiFetch('/users/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        })
            .then(resp => resp.json())
            .then(data => {
                localStorage.setItem('user', JSON.stringify(data));
                setUserId(data.id);
                const userPreferences = initialiseUserPreferences(data);
                setDashboardConfig(userPreferences['web-client.widgets'] || initialiseWidgetConfig());
            })
            .catch(err => {
                console.error('Error fetching user data:', err);
            });
    }, [setUserId, setDashboardConfig]);
};

const DashboardPanels = () => {
    const user = Auth.getLoggedInUser();
    const [userId, setUserId] = useState();
    const [dashboardConfig, setDashboardConfig] = useState({});
    const [visibleWidgets, setVisibleWidgets] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const updateVisibleWidgets = useCallback(() => {
        const user = Auth.getLoggedInUser();
        setVisibleWidgets(filterWidgets(user, dashboardConfig));
    }, [dashboardConfig]);

    useFetchUserData(setUserId, setDashboardConfig);

    useEffect(() => {
        if (userId) {
            updateVisibleWidgets();
        }
    }, [userId, dashboardConfig, updateVisibleWidgets]);

    const onWidgetChange = (ev) => {
        setDashboardConfig(prev => ({
            ...prev,
            [ev.target.name]: { ...prev[ev.target.name], visible: ev.target.checked }
        }));
    };

    const onSave = () => {
        user.preferences = initialiseUserPreferences(user);
        user.preferences['web-client.widgets'] = dashboardConfig;
        secureApiFetch(`/users/${userId}`, {
            method: 'PATCH',
            body: JSON.stringify({ preferences: user.preferences })
        })
            .then((res) => {
                localStorage.setItem('user', JSON.stringify(user));
                if (res.status === 200) {
                    actionCompletedToast("Your preferences have been saved.");
                    onClose()
                }
                setVisibleWidgets(filterWidgets(user));
            })
            .catch(err => {
                console.error(err)
            });
    }

    if (dashboardConfig === null) return <Loading />

    return (
        <section className='-mt-3'>
            <div className='flex justify-between items-center'>
                <Title type="Home" title="Dashboard" icon={<SignalCellularAltOutlinedIcon />} />
                <button onClick={onOpen} className='bg-[#3A3D89] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                    Config
                </button>
            </div>

            {/* <VulnerabilityRiskStat /> */}

            <SimpleGrid gap="2" columns={{ base: "1", md: "2", xl: "3" }}>
                {visibleWidgets.length === 0 ? <WelcomeWidget /> : visibleWidgets}

            </SimpleGrid>
            <>
                <Modal isOpen={isOpen} size={'lg'} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Configuration</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <h4 className="font-medium text-gray-700 text-base">Select which widgets to present in your dashboard</h4>
                            <br />
                            <Stack>
                                {Object.keys(Widgets).map(widgetKey => {
                                    const widget = Widgets[widgetKey];
                                    if (!widget.hasOwnProperty("permissions") || PermissionsService.isAllowed(widget.permissions, user.permissions)) {
                                        return <Checkbox key={widgetKey} name={widgetKey} isChecked={dashboardConfig.hasOwnProperty(widgetKey) && dashboardConfig[widgetKey].visible} onChange={onWidgetChange}>{Widgets[widgetKey].title}. <em>{Widgets[widgetKey].description}</em></Checkbox>
                                    } else {
                                        return <></>
                                    }
                                })}
                            </Stack>
                            <div className='flex justify-end'>
                                <button onClick={onSave} className='bg-[#3A3D89] px-4 py-2 rounded text-white text-sm'>
                                    Save
                                </button>
                            </div>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </>
        </section>
    )
}

export default DashboardPanels;
