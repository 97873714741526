import { useCallback, useEffect, useState } from 'react';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PageTitle from 'components/logic/PageTitle';

import secureApiFetch from '../../services/api';
import Breadcrumb from "../ui/Breadcrumb";
import Loading from '../ui/Loading';
import Title from '../ui/Title';
import ReportsTable from './Table';
import { Link } from 'react-router-dom';

const ReportsList = () => {
    const [reports, setReports] = useState([]);

    const reloadReports = useCallback(() => {
        setReports([]);

        secureApiFetch(`/reports?isTemplate=false`, { method: 'GET' })
            .then(resp => resp.json())
            .then(data => {
                setReports(data);
            });
    }, []);

    useEffect(() => {
        reloadReports()
    }, [reloadReports])

    return <>
        <PageTitle value={`Reports`} />
        <div className='heading'>
            <Breadcrumb>
                <Link to='/reports'> Report </Link>
            </Breadcrumb>
        </div>
        <Title title='Reports' icon={<DescriptionOutlinedIcon />} />
        {!reports ? <Loading /> :
            <ReportsTable reports={reports} updateReports={reloadReports} includeProjectColumn={true} />
        }
    </>
}

export default ReportsList;
