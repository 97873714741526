
const Command = {
    name: null,
    description: null,
    tags: null,
    more_info_url: null,
    output_parser: null,
    docker_image: null,
    output_capture: 'disabled',
    executable_type: 'custom',
    executable_path: null,
    arguments: null,
    configuration: null,
};

export default Command;
