import React from "react";

import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import PageTitle from "components/logic/PageTitle";

import Breadcrumb from "../ui/Breadcrumb";
import Title from "../ui/Title";
import ImportForm from "./ImportForm";
import { Link } from "react-router-dom";

const ImportPage = () => {
    return (
        <div>
            <div className='heading'>
                <Breadcrumb>
                    <Link to='/system'> System </Link>
                    <div>Import Data</div>
                </Breadcrumb>
            </div>
            <PageTitle value="Import data" />
            <Title title="Data Import" icon={<ImportExportOutlinedIcon />} />

            <ImportForm />
        </div>
    )
};

export default ImportPage;
