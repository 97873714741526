import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import secureApiFetch from '../../../services/api';
import { actionCompletedToast } from 'components/ui/toast';
import RestrictedComponent from "components/logic/RestrictedComponent";
import DeleteButton from "components/ui/buttons/Delete";

export default function VulnerabilityModalDialog({ isOpen, onCancel, tableModel, reloadVulnerabilities, setTableModel }) {
    const disableBtn = !tableModel.selection.length
    const handleAction = (selectedActionType) => {
        secureApiFetch('/vulnerabilities', {
            method: 'PATCH',
            headers: {
                'Bulk-Operation': selectedActionType,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(tableModel.selection),
        })
            .then(reloadVulnerabilities)
            .then(() => {
                setTableModel({ ...tableModel, selection: [] })
                actionCompletedToast(`All selected vulnerabilities updated to ${selectedActionType}.`);
            })
            .catch(err => console.error(err));
            onCancel()
    };

    return (
        <Modal size="xl" isOpen={isOpen} onClose={onCancel}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Action</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <div className="grid grid-cols-1 ">
                        <div>
                            <label className="font-semibold text-base">Visibility</label>
                            <div className="flex space-x-2 mt-4">
                                <button onClick={() => handleAction('PUBLIC')} disabled={disableBtn} className="bg-[#48A14D] border border-gray-300 text-white text-sm rounded-lg px-4 py-2">
                                    Public
                                </button>
                                <button onClick={() => handleAction('PRIVATE')} disabled={disableBtn} className="bg-red-500 border border-gray-300 text-white text-sm rounded-lg px-4 py-2">
                                    Private
                                </button>
                            </div>
                        </div>
                        <div className="mt-1">
                            <label className="font-semibold text-base">Vulnerability Status</label>
                            <div className="flex space-x-2 mt-4">
                                <button onClick={() => handleAction('UNEXPLOITED')} disabled={disableBtn} className="bg-gray-50 border border-gray-300 text-sm rounded-lg px-4 py-2">
                                    UNEXPLOITED
                                </button>
                                <button onClick={() => handleAction('MITIGATED')} disabled={disableBtn} className="bg-[#92D050] text-white border border-gray-300 text-sm rounded-lg px-4 py-2">
                                    MITIGATED
                                </button>
                                <button onClick={() => handleAction('REMEDIATED')} disabled={disableBtn} className="bg-[#92D050] text-white border border-gray-300 text-sm rounded-lg px-4 py-2">
                                    REMEDIATED
                                </button>
                            </div>
                        </div>
                        <div className="mt-1">
                            <label className="font-semibold text-base">Delete</label>
                            <div className="mt-2">
                                <RestrictedComponent roles={['administrator', 'superuser', 'user']}>
                                    <DeleteButton onClick={() => handleAction('DELETE')} disabled={disableBtn}>
                                        Delete selected
                                    </DeleteButton>
                                </RestrictedComponent>
                            </div>
                        </div>
                    </div>

                </ModalBody>
            </ModalContent>
        </Modal>
    );
};