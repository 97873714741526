import React from 'react'
import { Checkbox, Input, Select, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { actionCompletedToast, errorToast } from "components/ui/toast";
import Vault from 'models/Vault';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import secureApiFetch from "services/api";

function VaultModal({ isEdit, onClose, isOpen, vaultItemId, projectId, refreshVault }) {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false)
    const [item, setVaultItem] = useState(new Vault());
    const [password, setPassword] = useState(null);

    const onVaultItemFormChange = ev => {
        const value = ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value;
        if (isEdit) {
            setVaultItem({ ...item, [ev.target.name]: value });
        }
    }

    const onFormSubmit = ev => {
        ev.preventDefault();
        item.password = password;
        secureApiFetch(`/vault/${projectId}/${vaultItemId}`, { method: 'PUT', body: JSON.stringify(item) })
            .then(resp => {
                refreshVault()
                onClose()
                if (resp.status === 201) {
                    setVaultItem(new Vault());
                    setPassword(null);
                    actionCompletedToast(`The vault item has been modified.`);
                    navigate(`/projects/${projectId}`);
                } else {
                    errorToast("The vault item could not be saved. Review the form data or check the application logs.")
                }
            })
    }

    const onPasswordProvided = ev => {
        ev.preventDefault();

        secureApiFetch(`/vault/${projectId}/${vaultItemId}`, { method: 'POST', body: JSON.stringify({ 'password': password }) })
            .then(response => {
                return response.json()
            })
            .then(json => {
                if (json['success'] === false) {
                    setVisible(false)
                } else {
                    setVisible(true)
                }
                if (json['success'] === false) {
                    errorToast("Seems like a wrong password.");
                    setPassword(null);
                }
                else {
                    var newItem = new Vault();
                    newItem.name = json['name'];
                    newItem.note = json['note'];
                    newItem.value = json['value'];
                    newItem.type = json['type'];
                    newItem.reportable = json['reportable'];
                    setVaultItem(newItem);
                    actionCompletedToast(`The vault item "${newItem.name}" has been loaded.`);
                }
                setPassword('');
            })
            .catch(err => {
                errorToast(err);
                setPassword(null);
            })
    }
    const handleClose = () => {
        setVisible(false)
        onClose()
    }

    const onPasswordFormChanged = ev => {
        setPassword(ev.target.value);
    }
    const modalContentProps = {
        maxWidth: "35rem",
    };

    return (
        <div className='w-22'>
            <Modal isOpen={isOpen} onClose={handleClose}  >
                <ModalOverlay />
                <ModalContent {...modalContentProps}>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <div>
                            {visible ? (
                                <form onSubmit={onFormSubmit}>
                                    <h3>Vault item</h3>
                                    <label> Type
                                        <Select name="type" onChange={onVaultItemFormChange} value={item.type || ""} isRequired>
                                            <option value="password">Password</option>
                                            <option value="note">Comment</option>
                                            <option value="token">Token</option>
                                            <option value="key">Key</option>
                                        </Select>
                                    </label>
                                    <label> Title
                                        <Input type="text" name="name" onChange={onVaultItemFormChange} value={item.name || ""} isRequired />
                                    </label>
                                    <label> Comment
                                        <Input type="text" name="note" onChange={onVaultItemFormChange} value={item.note || ""} />
                                    </label>
                                    <label> Secret
                                        <Input type="text" name="value" onChange={onVaultItemFormChange} value={item.value || ""} isRequired />
                                    </label>
                                    <label> Reportable
                                        <Checkbox name="reportable" onChange={onVaultItemFormChange} isChecked={item.reportable} />
                                    </label>
                                    {isEdit && (
                                        <button type="submit" className='bg-[#3A3D89] px-4 py-2 rounded text-white text-sm'>
                                            Update
                                        </button>
                                    )}
                                </form>
                            ) : (
                                <>
                                    <h3>Please Provide Encryption Key</h3>
                                    <form onSubmit={onPasswordProvided} className="flex justify-center items-center space-x-2 px-2 mt-10">
                                        <input type="password" name="password" onChange={onPasswordFormChanged} value={password} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
                                        <button type='submit' className='bg-[#3A3D89] w-40 px-4 py-2 rounded text-white text-sm'>
                                            Send
                                        </button>
                                    </form>
                                </>
                            )}
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default VaultModal
