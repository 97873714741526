import { useColorMode } from "@chakra-ui/react";
import { createContext, useCallback, useEffect, useState } from "react";
import Auth from "services/auth";
import KeyCloakService from "services/keycloak";
import { initialiseUserPreferences } from "services/userPreferences";
import setThemeColors from "utilities/setThemeColors";
import secureApiFetch from '../services/api';
import Cookies from 'js-cookie';

const AuthContext = createContext();

function useAuth() {
    const { setColorMode } = useColorMode();

    const [isAuth, setIsAuth] = useState(KeyCloakService.IsAuthenticated);
    const [user, setUser] = useState(Auth.getLoggedInUser());

    const login = useCallback(() => {
        return secureApiFetch(`/users/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
            .then(resp => {
                const cookieHeader = resp.headers.get('Set-Cookie');
                if (cookieHeader) {
                    const cookies = cookieHeader.split(';');
                    cookies.forEach(cookie => {
                        const [name, value] = cookie.split('=');
                        Cookies.set(name, value.trim(), {
                            path: '/',
                            samesite: "None",
                            secure: true
                        });
                    });
                }
                return resp.json()
            })
            .then(data => {
                if (data.role === "client") {
                    window.location.href = 'https://clients.prosecurity.work/';
                    return;
                }

                data.preferences = initialiseUserPreferences(data);
                localStorage.setItem('isAuth', true);
                localStorage.setItem('user', JSON.stringify(data));
                localStorage.setItem('APP_VERSION', data.APP_VERSION);

                setUser(Auth.getLoggedInUser())
                //                setUser(data);

                setThemeColors(data.preferences['web-client.theme']);
                setColorMode(data.preferences['web-client.theme']);

                setIsAuth(KeyCloakService.GetInstance().authenticated)
            })
            .catch(err => {
                throw err;
            });
    }, [setColorMode])

    const logout = () => {
        setIsAuth(false);

        secureApiFetch(`/users/logout`, {
            method: 'POST',
        })
            .finally(() => {
                Auth.removeSession();
                setThemeColors('dark');
                setColorMode('dark');

                KeyCloakService.Logout();
            });
    }

    useEffect(() => {
        login();
    }, [login])

    return { user, isAuth, login, logout };
}

const AuthProvider = ({ children }) => {
    const auth = useAuth();

    return <AuthContext.Provider value={auth}>
        {children}
    </AuthContext.Provider>
}

const AuthConsumer = AuthContext.Consumer;

export { useAuth, AuthContext, AuthProvider, AuthConsumer };

