import { DeleteIcon } from "@chakra-ui/icons";
// import { IconButton } from "@chakra-ui/react";

const DeleteIconButton = (props) => {
    return (
        <div className="">
            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-3 rounded" onClick={props.onClick} {...props}><DeleteIcon /></button>
            {/* <IconButton colorScheme="red" variant='outline' onClick={props.onClick} {...props} icon={<DeleteIcon />} /> */}
        </div>
    )
}

export default DeleteIconButton;
