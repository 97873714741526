import MarkdownEditor from "components/ui/forms/MarkdownEditor";

const DocumentForm = ({ document, onFormSubmit, documentSetter: setNote, isEditForm = false }) => {
    const onFormInputChange = ev => {
        const target = ev.target;
        const name = target.name;
        const value = target.value;

        setNote({
            ...document, [name]: value
        });
    };

    return (
        <form onSubmit={onFormSubmit}>
            <hr className='h-px bg-[#3A3D89]' />
            <label>
                <span className="font-medium text-xs">Title</span>
                <input type="text" name="title" id="title" value={document.title || ""} onChange={onFormInputChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
            </label>

            <label>
                <span className="font-medium text-xs">Content (markdown supported)</span>
                <MarkdownEditor name="content" style={{ width: '100%' }} required value={document.content || ""}
                    onChange={onFormInputChange} /><br />
            </label>
            <label>
                <span className="font-medium text-xs">Visibility</span>
                <select name="visibility" id="visibility" value={document.visibility} onChange={onFormInputChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required>
                    <option value="private">Private</option>
                    <option value="public">Public</option>
                </select>
            </label>

            <div className="fixed bottom-4 right-4">
                <button type='submit' className='bg-[#3A3D89] px-4 py-2 rounded text-white text-sm'>
                    {isEditForm ? "Update Document" : "Create Document"}
                </button>
            </div>
        </form>
    )
}

export default DocumentForm;
