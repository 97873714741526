import { Link } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RelativeDateFormatter from "components/ui/RelativeDateFormatter";
import LoadingTableRow from "components/ui/tables/LoadingTableRow";
import VisibilityLegend from "components/ui/VisibilityLegend";
import UserLink from "components/users/Link";

import NoResultsTableRow from "../ui/tables/NoResultsTableRow";
import DocumentBadge from "./Badge";

const DocumentsTable = ({ onDeleteButtonClick, tableModel, reloadDocuments }) => {

    const handleDelete = (ev, documentid) => {
        ev.preventDefault()
        onDeleteButtonClick(documentid)
        setTimeout(() => {
            reloadDocuments()
        }, 1100);
    }

    return (
        <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
            <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                    <tr>
                        <th scope="col" className="px-4 py-2">Title</th>
                        <th scope="col" className="px-4 py-2 text-center">Creation Time</th>
                        <th scope="col" className="px-4 py-2 text-center">Author</th>
                        <th scope="col" className="px-4 py-2 text-center">Visibility</th>
                        <th scope="col" className="px-4 py-2 text-center">Actions</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 ">
                    {null === tableModel.documents && <LoadingTableRow numColumns={6} />}
                    {null !== tableModel.documents && tableModel.documents.length === 0 && <NoResultsTableRow numColumns={6} />}
                    {null !== tableModel.documents && tableModel.documents.map((document, index) =>
                        <tr key={`doc_${index}`} className="bg-white border-b border-gray-300 text-black font-light">
                            <td className="px-2 py-1"><DocumentBadge document={document} /></td>
                            <td className="px-2 py-1 text-center font-medium"><RelativeDateFormatter date={document.insert_ts} /></td>
                            <td className="px-2 py-1 text-center font-medium text-gray-700">
                                <UserLink userId={document.user_id}>{document.user_name}</UserLink>
                            </td>
                            <td className="px-2 py-1 text-center">
                                <span type="button" className="py-1.5 px-2 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-100 text-blue-800 hover:bg-blue-200 ">
                                    <VisibilityLegend visibility={document.visibility} />
                                </span>
                            </td>
                            <td className="px-2 py-1 text-center">
                                <span className=''>
                                    <Link to={`/documents/${document.id}/edit`}>
                                        <EditIcon className="text-[#3A3D89]" fontSize="small" />
                                    </Link>
                                    <button className='mx-1' onClick={(ev) => handleDelete(ev, document.id)}>
                                        <DeleteIcon className="text-[#EB1F36]" fontSize="small" />
                                    </button>
                                </span>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default DocumentsTable;
