import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { actionCompletedToast, errorToast } from 'components/ui/toast';
import { ButtonGroup, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { IconBriefcase } from '../ui/Icons';

import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import ProjectTableModel from 'components/projects/ProjectTableModel';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PageTitle from 'components/logic/PageTitle';
import RestrictedComponent from 'components/logic/RestrictedComponent';
import ProjectsTable from 'components/projects/Table';
import EmptyField from 'components/ui/EmptyField';
import TimestampsSection from 'components/ui/TimestampsSection';
import UserLink from 'components/users/Link';
import Contact from 'models/Contact';
import secureApiFetch from 'services/api';

import Breadcrumb from "../ui/Breadcrumb";
import ExternalLink from "../ui/ExternalLink";
import Title from '../ui/Title';
import useDelete from './../../hooks/useDelete';
import useFetch from './../../hooks/useFetch';
import Loading from './../ui/Loading';
import MembersClient from './MembersClient';
import ContactsClient from './ContactsClient';
import ClientBoxData from './ClientBoxData';
import Configuration from 'Configuration';

const ClientProjectsTab = ({ clientId }) => {
    const [tableModel, setTableModel] = useState(new ProjectTableModel());
    useEffect(() => {
        const reloadData = () => {
            secureApiFetch(`/projects?clientId=${clientId}`, { method: 'GET' })
                .then(resp => resp.json())
                .then(projects => {
                    setTableModel(tableModel => ({ ...tableModel, projects: projects }));
                })
        }
        reloadData()
    }, [clientId, setTableModel,])

    if (!tableModel) return <Loading />

    if (tableModel.length === 0) {
        return <div>
            This client has no projects. You can see all projects and their clients <Link to="/projects">here</Link>.
        </div>
    }

    return <div>
        <ProjectsTable tableModel={tableModel} tableModelSetter={setTableModel} showClientColumn={false} />
    </div>
}

const ClientDetails = () => {
    const { clientId } = useParams();
    const navigate = useNavigate();

    const [client] = useFetch(`/clients/${clientId}`);
    const [users, userUpdate] = useFetch(`/clients/${clientId}/users`);
    const [contacts, fetchContacts] = useFetch(`/clients/${clientId}/contacts`);

    const [contact, setContact] = useState(new Contact());

    const onContactFormChange = ev => {
        setContact({ ...contact, [ev.target.name]: ev.target.value });
    }
    const deleteClient = useDelete(`/clients/`)


    const handleDelete = async () => {
        const confirmed = await deleteClient(clientId);
        if (confirmed)
            navigate('/clients');
    }

    const onFormSubmit = ev => {
        ev.preventDefault();
        secureApiFetch(`/clients/${clientId}/contacts`, { method: 'POST', body: JSON.stringify(contact) })
            .then(resp => {
                fetchContacts();
                if (resp.status === 201) {
                    setContact(new Contact());
                    actionCompletedToast(`The contact has been added.`);
                } else {
                    errorToast("The contact could not be saved. Review the form data or check the application logs.")
                }
            })
            .catch(err => console.error(err))
    }


    if (!client) {
        return <Loading />
    }

    return <div>
        <div className='heading'>
            <Breadcrumb>
                <Link to="/clients">Clients</Link>
            </Breadcrumb>
            <ButtonGroup>
                <RestrictedComponent roles={['administrator', 'superuser', 'user']}>

                    <button onClick={(ev) => {
                        ev.preventDefault();
                        navigate(`/clients/${client.id}/edit`)
                    }} className='bg-[#3A3D89] px-3 py-1 rounded text-white text-xs'>
                        Edit
                    </button>

                    <button onClick={handleDelete} className='bg-[#EB1F36] px-2 py-1 rounded text-white space-x-1 flex items-center text-xs'>
                        <DeleteIcon /> Delete
                    </button>
                </RestrictedComponent>
            </ButtonGroup>
        </div>
        <article>
            <div>
                <PageTitle value={`${client.name} client`} />
                <Title type='Client' title={client.name} icon={<IconBriefcase />} />
            </div>
            <div className='flex items-center justify-between w-full pr-4'>
                <div className='ml-4'>
                    <span class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded ">
                        {client.code === null ? 'Not Set' : client.code}
                    </span>
                </div>
                <div className='-mt-2'>
                    <ClientBoxData clientId={clientId} />
                </div>
            </div>

            <Tabs varient='enclosed' isLazy>
                <TabList>
                    <Tab><span className="font-medium text-xs">Details</span></Tab>
                    <Tab><span className="font-medium text-xs">Contacts</span></Tab>
                    <Tab><span className="font-medium text-xs">Projects</span></Tab>
                    <Tab><span className="font-medium text-xs">Members</span></Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <div className="grid grid-two">
                            <div>
                                <h4 className='text-sm font-medium'>
                                    <ArticleOutlinedIcon className='text-[#3A3D89]' /> Properties
                                </h4>

                                <dl>
                                    <dt>Address</dt>
                                    <dd>{client.address ?? '-'}</dd>

                                    <dt>URL</dt>
                                    <dd><ExternalLink href={client.url}>{client.url}</ExternalLink></dd>
                                </dl>

                                <h4>Branding</h4>

                                <dl>
                                    <dt>Main logo</dt>
                                    <dd>{client.logo_attachment_id ? <img className='max-h-72' src={`${Configuration.getDefaultApiUrl()}/image/${client.logo_attachment_id}`} alt="The main logo" /> : <EmptyField />}</dd>

                                    <dt>Small logo</dt>
                                    <dd>{client.small_logo_attachment_id ? <img className='max-h-52' src={`${Configuration.getDefaultApiUrl()}/image/${client.small_logo_attachment_id}`} alt="The smaller version of the logo" /> : <EmptyField />}</dd>
                                </dl>
                            </div>

                            <div>
                                <h4 className='text-sm font-medium'>
                                    <LinkOutlinedIcon className='text-[#3A3D89]' /> Relations
                                </h4>
                                <dl className='mb-3'>
                                    <dt>Created by</dt>
                                    <dd><UserLink userId={client.creator_uid}>{client.creator_full_name}</UserLink></dd>
                                </dl>

                                <TimestampsSection entity={client} />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <h4> <PermContactCalendarOutlinedIcon className='text-[#3A3D89]' />  Contacts</h4>
                        <ContactsClient contacts={contacts} fetchContacts={fetchContacts} onFormSubmit={onFormSubmit} contact={contact} onContactFormChange={onContactFormChange} />
                    </TabPanel>

                    <TabPanel>
                        <ClientProjectsTab clientId={clientId} />
                    </TabPanel>

                    <TabPanel>
                        <MembersClient clientId={clientId} usersClient={users} usersUpdated={userUpdate} />
                    </TabPanel>

                </TabPanels>
            </Tabs>

        </article>
    </div>
}

export default ClientDetails;
