import { SimpleGrid } from '@chakra-ui/react'

import VulnerabilitiesByCategoryStatsWidget from 'components/layout/dashboard/widgets/VulnerabilitiesByCategoryStatsWidget'
import VulnerabilitiesByRiskStatsWidget from 'components/layout/dashboard/widgets/VulnerabilitiesByRiskStatsWidget'
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import RelativeDateFormatter from 'components/ui/RelativeDateFormatter'
// import VisibilityLegend from 'components/ui/VisibilityLegend'
import TimestampsSection from 'components/ui/TimestampsSection'
import ReactMdeMark from 'components/ui/forms/ReactMdeMark';
import EmptyField from 'components/ui/EmptyField'
import ClientLink from 'components/clients/Link'
import ReactApexChart from 'react-apexcharts';
import UserLink from 'components/users/Link'

const ProjectDetailsTab = ({ project }) => {
    const isTemplate = project.is_template === 1;
    const completionPercentage = project.num_tasks > 0 ? (project.completed_tasks / project.num_tasks) * 100 : 0;

    const chartOptions = {
        chart: {
            height: 200,
            type: 'radialBar',
            offsetY: -20,
        },
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 135,
                hollow: {
                    margin: 0,
                    size: '70%',
                    background: '#fff',
                    image: undefined,
                    position: 'front',
                },
                track: {
                    background: '#e7e7e7',
                    strokeWidth: '97%',
                    margin: 0,
                },
                dataLabels: {
                    showOn: 'always',
                    name: {
                        offsetY: -10,
                        show: true,
                        color: '#3A3D89',
                        fontSize: '14px',
                    },
                    value: {
                        color: '#3A3D89',
                        fontSize: '25px',
                        show: true,
                        formatter: function (val) {
                            return parseInt(val) + '%';
                        },
                    },
                },
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
            },
        },
        stroke: {
            lineCap: 'round',
        },
        labels: ['Completion'],
    };

    const chartData = {
        series: [completionPercentage],
    };

    return (
        <section className="grid grid-two -mt-1 text-xs">
            <div>
                <h4 className='text-sm'>
                    <InsertDriveFileOutlinedIcon sx={{ color: '#3A3D89' }} /> Project Details
                </h4>
                <dl>
                    <dt>Description</dt>
                    <dd>{project.description
                        ? <ReactMdeMark>{project.description}</ReactMdeMark>
                        : <EmptyField />}
                    </dd>

                </dl>

                <h4 className='text-sm'>
                    Vulnerabilities summary
                </h4>
                <dl>
                    <dt>Management Summary</dt>
                    <dd>{project.management_summary
                        ? <ReactMdeMark>{project.management_summary}</ReactMdeMark>
                        : <EmptyField />}
                    </dd>

                    <dt>Conclusion</dt>
                    <dd>{project.management_conclusion
                        ? <ReactMdeMark>{project.management_conclusion}</ReactMdeMark>
                        : <EmptyField />}
                    </dd>
                </dl>

                <h4 style={{ marginTop: 20 }}>
                    <SignalCellularAltOutlinedIcon className='text-[#3A3D89]' /> Stats
                </h4>

                <SimpleGrid spacingX='40px' columns={2}>
                    <VulnerabilitiesByRiskStatsWidget projectId={project.id} />
                    <VulnerabilitiesByCategoryStatsWidget projectId={project.id} />
                </SimpleGrid>
            </div>

            <div>
                <h4 className='text-sm font-medium'> <InsertLinkOutlinedIcon sx={{ color: '#3A3D89' }} /> Relations</h4>
                <dl>
                    <dt>Task Data</dt>
                    <div className=''>
                        <ReactApexChart
                            options={chartOptions}
                            series={chartData.series}
                            type="radialBar"
                            height={200}
                        />
                    </div>
                    {!isTemplate && <>
                        <dt>Client</dt>
                        <dd><ClientLink clientId={project.client_id}>{project.client_name}</ClientLink></dd>
                    </>}

                    <dt className='text-xs pb-3 mb-3'>Created by</dt>
                    <dd><UserLink userId={project.creator_uid}>{project.creator_full_name}</UserLink></dd>
                </dl>

                <TimestampsSection entity={project} />

                {project.archived === 1 &&
                    <dl>
                        <dt>Archived</dt>
                        <dd><RelativeDateFormatter date={project.archive_ts} /></dd>
                    </dl>
                }

            </div>
        </section>
    )
}

export default ProjectDetailsTab;
