import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { actionCompletedToast, errorToast } from "../ui/toast";
import { ButtonGroup, Menu, MenuList } from "@chakra-ui/react";

import EllipsisMenuButton from "components/ui/buttons/EllipsisMenuButton";
import RestrictedComponent from "components/logic/RestrictedComponent";
import NoResultsTableRow from "components/ui/tables/NoResultsTableRow";
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ExportMenuItem from "components/ui/menuitems/ExportMenuItem";
import LoadingTableRow from "components/ui/tables/LoadingTableRow";
import BooleanText from "components/ui/BooleanText";
import DeleteIcon from '@mui/icons-material/Delete';
import PageTitle from "components/logic/PageTitle";
import EditIcon from '@mui/icons-material/Edit';
import Auth from "services/auth";

import CreateButton from "../../components/ui/buttons/Create";
import UserRoleBadge from "../badges/UserRoleBadge";
import secureApiFetch from "../../services/api";
import UserAvatar from "../badges/UserAvatar";
import useFetch from "../../hooks/useFetch";
import Breadcrumb from "../ui/Breadcrumb";
import Title from "../ui/Title";
import UserLink from "./Link";

const UsersList = () => {
    const navigate = useNavigate();
    const loggedInUser = Auth.getLoggedInUser();
    const [users, updateUsers] = useFetch("/users");
    const [selectedUsers, setSelectedUsers] = useState([]);

    const handleCreate = () => {
        navigate("/users/create");
    };

    const onTaskCheckboxChange = (ev) => {
        const target = ev.target;
        const targetUserId = parseInt(target.value);
        if (target.checked) {
            setSelectedUsers([...selectedUsers, targetUserId]);
        } else {
            setSelectedUsers(
                selectedUsers.filter(value => value !== targetUserId)
            );
        }
    };

    const handleBulkDelete = () => {
        secureApiFetch(`/users`, {
            method: "PATCH",
            headers: {
                "Bulk-Operation": "DELETE",
            },
            body: JSON.stringify(selectedUsers),
        })
            .then(updateUsers)
            .then(() => {
                setSelectedUsers([]);
                actionCompletedToast("All selected users were deleted.");
            })
            .catch(err => console.error(err));
    };

    const handleDelete = (id) => {
        secureApiFetch(`/users/${id}`, {
            method: "DELETE",
        })
            .then((res) => {
                if (res.status === 204) {
                    actionCompletedToast("User Deleted successfully.");
                    updateUsers();
                } else {
                    errorToast('Error deleting user. Please try again later.');
                }
            })
            .catch(err => {
                console.error(err);
            });
    };


    return <>
        <PageTitle value="Users" />
        <div className="heading">
            <Breadcrumb>
                <Link to="/users">Users</Link>
            </Breadcrumb>
            <ButtonGroup >
                <CreateButton onClick={handleCreate}>
                    Create user
                </CreateButton>
                <RestrictedComponent roles={['administrator']}>
                    <button onClick={handleBulkDelete} disabled={selectedUsers.length === 0} className='bg-[#EB1F36] px-2 py-1 rounded text-white space-x-1 flex items-center text-xs'>
                        <DeleteIcon fontSize="small" />  Delete selected
                    </button>
                </RestrictedComponent>
                <RestrictedComponent roles={['administrator', 'superuser']}>
                    <Menu>
                        <EllipsisMenuButton />
                        <MenuList>
                            <ExportMenuItem entity="users" />
                        </MenuList>
                    </Menu>
                </RestrictedComponent>
            </ButtonGroup>
        </div>
        <Title title="Users and roles" icon={<GroupsOutlinedIcon />} />
        <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
            <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                    <tr>
                        <th scope="col" className="px-4 py-2"></th>
                        <th scope="col" className="px-4 py-2"></th>
                        <th scope="col" className="px-4 py-2">Full name</th>
                        <th scope="col" className="px-4 py-2">Username</th>
                        <th scope="col" className="px-4 py-2">Role</th>
                        <th scope="col" className="px-4 py-2 text-center">Active?</th>
                        <th scope="col" className="px-4 py-2 text-center">2FA enabled?</th>
                        <th scope="col" className="px-4 py-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {null === users && <LoadingTableRow numColumns={8} />}
                    {null !== users && 0 === users.length && <NoResultsTableRow numColumns={8} />}
                    {null !== users && 0 !== users.length && users.map((user, index) => (
                        <tr key={index} className="bg-white border-b border-gray-300 text-black font-medium">
                            <td className="px-2 py-2">
                                <input
                                    className="bg-white "
                                    type="checkbox"
                                    value={user.id}
                                    onChange={onTaskCheckboxChange}
                                    checked={selectedUsers.includes(user.id)}
                                />
                            </td>
                            <td className="px-2 py-2">
                                <UserAvatar email={user.email} />
                            </td>
                            <td className="px-2 py-2">
                                <Link to={`/users/${user.id}`}>
                                    {user.full_name}
                                </Link>
                            </td>
                            <td className="px-2 py-2">
                                <UserLink userId={user.id}>
                                    {user.username}
                                </UserLink>
                            </td>
                            <td className="px-2 py-2">
                                <UserRoleBadge role={user.role} />
                            </td>
                            <td className="px-2 py-2 text-center"><BooleanText value={user.active} /></td>
                            <td className="px-2 py-2 text-center"><BooleanText value={user.mfa_enabled} /></td>
                            <td className="px-2 py-2">
                                <span className="flex space-x-2 items-center">
                                    <Link to={`/users/${user.id}/edit`}>
                                        <EditIcon className='text-[#3A3D89]' fontSize='small' />
                                    </Link>
                                    <button onClick={() => handleDelete(user.id)} disabled={parseInt(user.id) === loggedInUser.id ? "disabled" : ""}>
                                        <DeleteIcon className='text-[#EB1F36]' fontSize='small' />
                                    </button>
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </>
};

export default UsersList;
