import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import PageTitle from 'components/logic/PageTitle';
import ExternalLink from 'components/ui/ExternalLink';
import LoadingTableRow from 'components/ui/tables/LoadingTableRow';
import NoResultsTableRow from 'components/ui/tables/NoResultsTableRow';

import useFetch from '../../hooks/useFetch';
import Breadcrumb from '../ui/Breadcrumb';
import Title from "../ui/Title";
import { Link } from 'react-router-dom';

const SystemIntegrationsPage = () => {
    const [integrations] = useFetch('/system/integrations')

    return (
        <div>
            <PageTitle value="Integrations" />
            <div className='heading'>
                <Breadcrumb>
                    <Link to='/system'> System </Link>
                    <div>integration</div>
                </Breadcrumb>
            </div>
            <Title title="Integrations" icon={<ExtensionOutlinedIcon />} />

            <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
                <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                    <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                        <tr>
                            <th scope="col" className="px-4 py-2">Name</th>
                            <th scope="col" className="px-4 py-2">Description</th>
                            <th scope="col" className="px-4 py-2 ">External URL</th>
                            <th scope="col" className="px-4 py-2 text-center">Configured?</th>
                        </tr>
                    </thead>
                    <tbody>
                        {null === integrations && <LoadingTableRow numColumns={5} />}
                        {null !== integrations && 0 === integrations.length && <NoResultsTableRow numColumns={5} />}
                        {null !== integrations && 0 !== integrations.length && integrations.map((integration, index) =>
                            <tr key={index} className="bg-white border-b border-gray-300 text-black font-medium">
                                <td scope="row" className="px-2 py-2 ">{integration.name}</td>
                                <td scope="row" className="px-2 py-2 ">{integration.description}</td>
                                <td scope="row" className="px-2 py-2 "><ExternalLink href={integration.externalUrl}>{integration.externalUrl}</ExternalLink></td>
                                <td scope="row" className="px-2 py-2 text-center">{integration.configured ? 'Yes' : 'No'}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default SystemIntegrationsPage;
