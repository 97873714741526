
const PrimaryButton = ({
    type,
    onClick,
    children,
    disabled = false,
    external = false,
    ...props
}) => {

    return (
        <button {...props} type={type ? type : "button"} onClick={onClick} isDisabled={disabled} className='inline-flex border justify-center border-gray-200 items-center px-5 py-2 text-white bg-blue-600  rounded-md hover:bg-blue-600 ' >
            {children}
        </button>
    )
}

export default PrimaryButton;
