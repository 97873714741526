import NotificationsIcon from '@mui/icons-material/Notifications';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import PageTitle from 'components/logic/PageTitle';
import RelativeDateFormatter from 'components/ui/RelativeDateFormatter';
import LoadingTableRow from 'components/ui/tables/LoadingTableRow';
import NoResultsTableRow from 'components/ui/tables/NoResultsTableRow';
import secureApiFetch from 'services/api';

import useDelete from '../../hooks/useDelete';
import useFetch from '../../hooks/useFetch';
import Breadcrumb from '../ui/Breadcrumb';
import Title from '../ui/Title';

const NotificationsList = () => {
    const [notifications, fetchNotifications] = useFetch('/notifications')

    const markNotificationAsRead = notification => {
        secureApiFetch(`/notifications/${notification.id}`, {
            method: 'PUT',
            body: JSON.stringify({ status: 'read' })
        }).then(() => {
            fetchNotifications();
            window.location.reload()
        })
    }

    const deleteNotification = useDelete('/notifications/', fetchNotifications);

    return <>
        <PageTitle value="Notifications" />
        <div className='heading'>
            <Breadcrumb>
                <div>Notifications</div>
            </Breadcrumb>
        </div>
        <Title title='Notifications' icon={<NotificationsIcon />} />

        <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
            <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                    <tr>
                        <th scope="col" className="px-4 py-2 text-center">Read</th>
                        <th scope="col" className="px-4 py-2">Date/time</th>
                        <th scope="col" className="px-4 py-2">Content</th>
                        <th scope="col" className="px-4 py-2 text-center">Mark as Read</th>
                        <th scope="col" className="px-4 py-2 text-center">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {null === notifications && <LoadingTableRow numColumns={3} />}
                    {null !== notifications && notifications.length === 0 && <NoResultsTableRow numColumns={3} />}
                    {null !== notifications && notifications.length > 0 &&
                        notifications.map(notification =>
                            <tr key={notification.id} className="bg-white border-b border-gray-300 text-black font-medium">
                                <td scope="row" className="px-2 py-2 text-center">
                                    {notification.status === 'read' ? <CheckOutlinedIcon fontSize='small' /> : <>&nbsp;</>}
                                </td>
                                <td scope="row" className="px-2 py-2 ">
                                    <RelativeDateFormatter date={notification.insert_ts} />
                                </td>
                                <td scope="row" className="px-2 py-2 ">
                                    <strong>{notification.title}</strong>
                                    <div>{notification.content}</div>
                                </td>
                                <td scope="row" className="px-2 py-2 text-center">
                                    {notification.status === 'unread' ?
                                        <button className='bg-[#3A3D89] px-2 py-1.5 rounded text-white space-x-1 text-xs' onClick={() => markNotificationAsRead(notification)}>
                                            Mark as Read
                                        </button>
                                        : '-'
                                    }
                                </td>
                                <td scope="row" className="px-2 py-2 text-center">
                                    <button onClick={() => deleteNotification(notification.id)}>
                                        <DeleteIcon className='text-red-600' fontSize='small' />
                                    </button>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    </>
}

export default NotificationsList;
