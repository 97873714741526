import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import AttachmentsTable from 'components/attachments/AttachmentsTable';
import AttachmentsDropzone from 'components/attachments/Dropzone';
import RestrictedComponent from 'components/logic/RestrictedComponent';
import useFetch from 'hooks/useFetch';

const ProjectAttachmentsTab = ({ project }) => {
    const parentType = 'project';
    const parentId = project.id;
    const [attachments, reloadAttachments] = useFetch(`/attachments?parentType=${parentType}&parentId=${parentId}`)
    
    if (!Array.isArray(attachments)) {
        return <h1 className='text-gray-600  font-medium text-center w-full'>You don't have access.</h1>;
    }
    return (
        <section className='-mt-1'>
            {attachments && (
                <RestrictedComponent roles={['administrator', 'superuser', 'user']} message="(access restricted)">
                    <div>
                        <AttachmentsDropzone parentType={parentType} parentId={parentId} onUploadFinished={reloadAttachments} />
                    </div>
                    <div className='mt-3'>
                        <h4 className='text-sm'><AttachmentOutlinedIcon sx={{ color: '#3A3D89' }} />Attachment list</h4>
                        <AttachmentsTable attachments={attachments} reloadAttachments={reloadAttachments} />
                    </div>
                </RestrictedComponent>
            )}
        </section>
    )
}

export default ProjectAttachmentsTab;
