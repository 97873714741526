import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import secureApiFetch, { downloadFromApi } from '../../services/api';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PaginationV2 from 'components/layout/PaginationV2';
import PageTitle from 'components/logic/PageTitle';
import useQuery from 'hooks/useQuery';

import ExportButton from '../ui/buttons/Export';
import AuditLogsTable from "./AuditLogsTable";
import Breadcrumb from '../ui/Breadcrumb';
import Title from '../ui/Title';
import AuditFilter from './fillter';

const AuditLogList = () => {
    const [filters, setFilters] = useState({});
    const navigate = useNavigate();
    const query = useQuery();
    let pageNumber = query.get('page');
    pageNumber = pageNumber !== null ? parseInt(pageNumber) : 1;
    const apiPageNumber = pageNumber - 1;

    const [auditLog, setAuditLog] = useState([]);
    const [numberPages, setNumberPages] = useState(1);

    const onPageChange = pageNumber => {
        navigate(`/auditlog?page=${pageNumber + 1}`);
    }

    const reloadData = useCallback(() => {
        const queryString = new URLSearchParams({
            ...filters,
        }).toString();

        secureApiFetch(`/auditlog?page=${apiPageNumber}&${queryString}`, { method: 'GET' })
            .then(resp => {
                if (resp.headers.has('X-Page-Count')) {
                    setNumberPages(resp.headers.get('X-Page-Count'));
                }
                return resp.json();
            })
            .then((data) => {
                setAuditLog(data);
            })
    }, [apiPageNumber, filters]);

    useEffect(() => {
        reloadData()
    }, [reloadData, filters])

    const onExportClick = () => {
        downloadFromApi('/system/data?entities=auditlog');
    }

    const handleFilterChange = useCallback((newFilters) => {
        setFilters(newFilters);
        navigate(`/auditlog?page=1`)
    }, [navigate]);

    return (
        <>
            <PageTitle value={`Audit log - Page ${pageNumber}`} />
            <div className='heading'>
                <Breadcrumb>
                    <div>System</div>
                    <div>Audit</div>
                </Breadcrumb>
                <PaginationV2 page={apiPageNumber} total={numberPages} onPageChange={onPageChange} />
                <ExportButton onClick={onExportClick} />
            </div>
            <Title type="System" title='Audit Log' icon={<VisibilityOutlinedIcon />} />
            <AuditFilter onFilterChange={handleFilterChange} />
            <AuditLogsTable auditLog={auditLog} />
        </>
    );
}

export default AuditLogList;
