import { List, ListItem, Text } from "@chakra-ui/react";
import useFetch from "hooks/useFetch";
import { useEffect, useRef, useState } from 'react';

const AuditUserFilter = ({ onFilterChange }) => {
    const [users] = useFetch('/users');

    const dropdownRef = useRef(null);
    const [userQuery, setUserQuery] = useState("");
    const [filteredUsers, setFilteredUsers] = useState([]);

    const handleUserInputChange = (event) => {
        const value = event.target.value;
        setUserQuery(value);
        const filtered = users.filter(user =>
            user.username.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredUsers(filtered);
    };

    const handleUserSelect = (user) => {
        setUserQuery(user.username);
        onFilterChange((prevFilters) => {
            const newFilters = { ...prevFilters };
            newFilters['user_id'] = user.id;
            return newFilters;
        });
        setFilteredUsers([]);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setFilteredUsers([]);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside); // Cleanup
        };
    }, []);


    return (
        <div className="flex w-full space-x-3 px-3 pb-2 rounded-lg justify-end">
            <div className='relative min-w-60' ref={dropdownRef}>
                <input
                    type="text"
                    className="px-2 py-1 border rounded text-sm w-full"
                    placeholder="Search Username"
                    value={userQuery}
                    onChange={handleUserInputChange}
                />
                {filteredUsers.length > 0 && (
                    <List
                        position="absolute"
                        top="40px"
                        zIndex="1000"
                        bg="white"
                        border="1px solid #e2e8f0"
                        width="100%"
                        borderRadius="md"
                        maxHeight="150px"
                        overflowY="auto"
                        boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
                    >
                        {filteredUsers.map((user) => (
                            <ListItem
                                key={user.id}
                                padding="8px"
                                cursor="pointer"
                                borderBottom="1px solid #e2e8f0"
                                _hover={{ backgroundColor: "#edf2f7" }}
                                onClick={() => handleUserSelect(user)}
                            >
                                <Text fontSize="sm" paddingLeft="4px">
                                    {user.username}
                                </Text>
                            </ListItem>
                        ))}
                    </List>
                )}
            </div>
        </div>
    );
}


export default AuditUserFilter