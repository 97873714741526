import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './Link.css';

const LinkButton = ({ children, external = false, ...props }) => {
    const navigate = useNavigate();

    const onAnchorClick = ev => {
        ev.stopPropagation();

        if (!external) {
            ev.preventDefault();

            navigate({
                pathname: ev.target.pathname,
                search: ev.target.search
            });
        }
    }

    return (
        <a onClick={onAnchorClick} target={external ? "_blank" : ""} className="inline-flex border border-gray-200 items-center px-3 py-1 text-gray-800 bg-gray-100 rounded-md hover:bg-gray-200 hover:text-gray-600" {...props}>
            {children}
        </a>
    )
}

LinkButton.propTypes = {
    href: PropTypes.string.isRequired
};

export default LinkButton;
