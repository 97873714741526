import { ButtonGroup, IconButton, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, Link } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import NoResultsTableRow from 'components/ui/tables/NoResultsTableRow';
import ExportMenuItem from 'components/ui/menuitems/ExportMenuItem';
import LoadingTableRow from 'components/ui/tables/LoadingTableRow';
import PageTitle from 'components/logic/PageTitle';
import RestrictedComponent from 'components/logic/RestrictedComponent';

import ExternalLink from "../ui/ExternalLink";
import useDelete from '../../hooks/useDelete';
import useFetch from '../../hooks/useFetch';
import Breadcrumb from '../ui/Breadcrumb';
import ClientLink from "./Link";
import Title from '../ui/Title';

const ClientsList = () => {
    const navigate = useNavigate();
    const [clients, updateTasks] = useFetch('/clients')

    const destroy = useDelete('/clients/', updateTasks);

    const handleCreateClient = () => {
        navigate(`/clients/create`)
    }

    return <>
        <PageTitle value="Clients" />
        <div className='heading'>
            <Breadcrumb>
                <Link to="/clients">Clients</Link>
            </Breadcrumb>

            <ButtonGroup gap='1'>
                <button onClick={handleCreateClient} className='bg-[#3A3D89] px-2 py-1 rounded text-white space-x-1 flex items-center text-xs'>
                    <AddOutlinedIcon fontSize='small' /> Add Client
                </button>
                <RestrictedComponent roles={['administrator']}>
                    <Menu>
                        <MenuButton as={IconButton} aria-label='Options' icon={<FontAwesomeIcon icon={faEllipsis} />} variant='outline' />
                        <MenuList>
                            <ExportMenuItem entity="clients" />
                        </MenuList>
                    </Menu>
                </RestrictedComponent>
            </ButtonGroup>
        </div>
        <Title title='Clients' icon={<BusinessCenterOutlinedIcon />} />

        <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
            <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                    <tr>
                        <th scope="col" className="px-4 py-2 ">Name</th>
                        <th scope="col" className="px-4 py-2 ">Client Code</th>
                        <th scope="col" className="px-4 py-2 ">Address</th>
                        <th scope="col" className="px-4 py-2 ">URL</th>
                        <th scope="col" className="px-4 py-2 text-center" >Number of contacts</th>
                        <th scope="col" className="px-4 py-2 text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {null === clients && <LoadingTableRow numColumns={5} />}
                    {null !== clients && 0 === clients.length && <NoResultsTableRow numColumns={5} />}
                    {null !== clients && 0 < clients.length && clients.map(client =>
                        <tr key={client.id} className="bg-white border-b border-gray-300 text-black font-medium">
                            <td className='px-2 py-1'><ClientLink clientId={client.id}>{client.name}</ClientLink></td>
                            <td className='px-2 py-1'>
                                <span className='bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded'>
                                    {client.code || '-'}
                                </span>
                            </td>
                            <td className='px-2 py-1'>{client.address || '-'}</td>
                            <td className='px-2 py-1'>{client.url ? <ExternalLink href={client.url}>{client.url}</ExternalLink> : '-'}</td>
                            <td className='px-2 py-1 text-center'>{client.num_contacts}</td>
                            <td className='px-2 py-1 text-center'>
                                <Link to={`/clients/${client.id}/edit`} >
                                    <EditIcon className='text-[#3A3D89]' fontSize='small' />
                                </Link>
                                <button className='mx-2' onClick={() => destroy(client.id)}>
                                    <DeleteIcon className='text-[#EB1F36]' fontSize='small' />
                                </button>
                            </td>
                        </tr>
                    )
                    }
                </tbody>
            </table>
        </div>
    </>
}

export default ClientsList
