import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import PaginationV2 from 'components/layout/PaginationV2';
import PageTitle from "components/logic/PageTitle";
import Breadcrumb from 'components/ui/Breadcrumb';
import secureApiFetch from 'services/api';
import Title from 'components/ui/Title';
import useFetch from 'hooks/useFetch';

import ProjectCategoryForm from './Form';
import ProjectCategoryTable from './Table';

function ProjectCategoryList() {
    const [categories, setCategories] = useFetch('/project/categories?all=true');
    const [totalCount, setTotalCount] = useState('?'); 

    useEffect(() => {
        const fetchUpdatedData = async () => {
            try {
                const updatedCategories = await secureApiFetch('/project/categories?all=true');
                setCategories(updatedCategories);
                if (updatedCategories.headers.has('X-Total-Count')) {
                    setTotalCount(updatedCategories.headers.get('X-Total-Count'))
                }
            } catch (error) {
                console.error('Error fetching updated data:', error);
            }
        };

        fetchUpdatedData();

    }, [setCategories]);

    return (
        <React.Fragment>
            <PageTitle value="Project Category" />
            <div className="heading">
                <Breadcrumb>
                    <Link to="/settings">Settings</Link>
                    <span>Project Category</span>
                </Breadcrumb>
                <PaginationV2 />
            </div>

            <Title title={`Project Category(${totalCount})`} icon={<AutoAwesomeMosaicOutlinedIcon />} />

            <ProjectCategoryForm setCategories={setCategories} />

            <ProjectCategoryTable categories={categories} setCategories={setCategories} />

        </React.Fragment>
    );
}

export default ProjectCategoryList;
