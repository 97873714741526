import React from 'react'
import { Switch } from "@chakra-ui/react";

import NoResultsTableRow from 'components/ui/tables/NoResultsTableRow';
import LoadingTableRow from 'components/ui/tables/LoadingTableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import secureApiFetch from 'services/api';
import { actionCompletedToast } from 'components/ui/toast';

function ProjectCategoryTable({ categories, setCategories }) {

    const handleClick = (id) => {
        const newStatus = categories.find(category => category.id === id).status === 1 ? false : true;
        secureApiFetch(`/project/categories/${id}`, {
            method: 'PUT',
            body: JSON.stringify({ status: newStatus }),
        })
            .then(() => {
                setCategories(prevCategories => {
                    return prevCategories.map(category => {
                        if (category.id === id) {
                            return { ...category, status: newStatus ? true : false };
                        }
                        return category;
                    });
                });
            })
            .catch(error => {
                console.error('Error updating status:', error);
            });
    };

    const handleDelete = (id) => {
        secureApiFetch(`/project/categories/${id}`, {
            method: 'DELETE',
        }).then(() => {
            return secureApiFetch('/project/categories?all=true')
        }).then((res) => {
            setCategories(res)
            actionCompletedToast(`Record has been Deleted.`);
        }).catch((error) => {
            console.log('Error Deleting Categories', error)
        })
    }
    return (
        <div>
            <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
                <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                    <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                        <tr>
                            <th scope="col" className="px-4 py-2">ID</th>
                            <th scope="col" className="px-2 py-2">Category ID</th>
                            <th scope="col" className="px-2 py-2">Title</th>
                            <th scope="col" className="px-2 py-2">Type</th>
                            <th scope="col" className="px-2 py-2">Options</th>
                            <th scope="col" className="px-2 py-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {null === categories && <LoadingTableRow numColumns={4} />}
                        {null !== categories && 0 === categories.length && <NoResultsTableRow numColumns={4} />}
                        {categories && categories.sort((a, b) => b.id - a.id).map((category, index) =>
                            <tr key={index} className="bg-white border-b border-gray-300 text-black font-medium">

                                <th scope="row" className="px-2 py-2 ">
                                    <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{category.id}</span>
                                </th>

                                <th scope="row" className="px-2 py-2 "> {category.name} </th>

                                <th scope="row" className="px-2 py-2 "> {category.description} </th>

                                <th scope="row" className="px-2 py-2 ">
                                    <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                        {category.product_code}
                                    </span>
                                </th>

                                <th scope="row" className="px-2 py-2 ">
                                    {category.status === 1 ?
                                        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Active</span> :
                                        <span className="inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10">InActive</span>
                                    }
                                </th>

                                <th scope="row" className="px-2 py-2 ">
                                    <span className='flex space-x-2'>
                                        <Switch isChecked={category.status === 1} onChange={() => handleClick(category.id)} size={'sm'} />
                                        <button onClick={() => handleDelete(category.id)}>
                                            <DeleteIcon className='text-red-600' fontSize='small' />
                                        </button>
                                    </span>
                                </th>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ProjectCategoryTable;