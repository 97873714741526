import React, { useEffect, useState } from "react";

import MarkdownEditor from "components/ui/forms/MarkdownEditor";
import ProjectVulnerabilityMetrics from "models/ProjectVulnerabilityMetrics";

import useFetch from "../../hooks/useFetch";
// import PrimaryButton from "../ui/buttons/Primary";
import Loading from "../ui/Loading";

const toHex = (number) => {
    return number.toString(16).toUpperCase();
};

const ProjectForm = ({ isEdit = false, project, projectSetter: setProject, onFormSubmit }) => {
    const [clients] = useFetch('/clients');
    const [categories] = useFetch('/project/categories');
    const [hexTime, setHexTime] = useState('');
    const [timestamp, setTimestamp] = useState('');

    useEffect(() => {
        if (clients && clients.length > 0 && project.client_id) {
            const now = new Date();
            const day = now.getUTCDate().toString().padStart(2, '0');
            const hours = now.getUTCHours().toString().padStart(2, '0');
            const minutes = now.getUTCMinutes().toString().padStart(2, '0');
            const month = (now.getUTCMonth() + 1).toString().padStart(2, '0');
            const year = now.getUTCFullYear().toString().slice(-2);
            const combinedTime = `${day}${hours}${minutes}`;
            const timeAsInt = parseInt(combinedTime, 10);
            const hexTimeString = toHex(timeAsInt);

            const matchedClient = clients.find(client => client.id === project.client_id);
            if (matchedClient) {
                const clientCode = matchedClient.code;
                const allValues = `${clientCode}/${year}${month}/${hexTimeString}`;
                setTimestamp(`${year}${month}/${hexTimeString}`);
                setHexTime(allValues);
            }
        }
    }, [clients, project.client_id]);

    const handleExternalId = (e) => {
        let values = e.target.value
        setHexTime(values)
        setProject({ ...project, external_id: values });
    }


const handleFormChange = ev => {
    const value = ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value;
    setProject(prevProject => {
        const updatedProject = { ...prevProject, [ev.target.name]: value };

        const currentClient = clients.find(client => client.id === parseInt(updatedProject.client_id));
        if (currentClient) {
            const newHexTime = `${currentClient.code}/${timestamp}`;
            setHexTime(newHexTime);
            updatedProject.external_id = newHexTime;
        }
        return updatedProject;
    });
};

    useEffect(() => {
        if (clients && clients.length && (project.client_id === null || project.client_id === 0)) {
            setProject({ ...project, client_id: clients[0].id });
        }
    }, [project, clients, setProject]);

    if (!project && !clients) return <Loading />

    return (
        <form onSubmit={onFormSubmit} className="crud">

            <fieldset>
                <div className='flex justify-between'>
                    <label className="text-lg font-medium text-black">Basic information</label>
                </div>
                <hr className='h-px bg-[#3A3D89]' />

                {/* <label>Is template?
                    <Checkbox borderColor='gray.400' name="is_template" onChange={handleFormChange} isChecked={project.is_template} />
                </label> */}

                <label>
                    <span className="font-medium text-xs text-red-600">Category*</span>
                    <select name="category_id" value={project.category_id || ""} onChange={handleFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required>
                        {categories && <>
                            <option value="">Please Select the Category</option>
                            {categories.map(category => <option key={`category_${category.id}`} value={category.id}>{category.name}</option>)}
                        </>}
                    </select>
                </label>

                {!project.is_template && <>
                    <label>
                        <span className="font-medium text-xs">Client</span>
                        <select name="client_id" onChange={handleFormChange} value={project.client_id || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'>
                            {clients && clients.map((client, index) =>
                                <option key={index} value={client.id}>{client.name}</option>
                            )}
                        </select>
                    </label>
                    <label>
                        <span className="font-medium text-xs">External ID</span>
                        <input type="text" name="external_id" placeholder="Add External ID" value={project.external_id || hexTime || ""} onChange={handleExternalId} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                    </label>
                </>}

                <label>
                    <span className="font-medium text-xs text-red-600">Name*</span>
                    <input borderColor="gray.500" type="text" name="name" onChange={handleFormChange} value={project.name || ""} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
                </label>
                <label>
                    <span className="font-medium text-xs">Description</span>
                    <MarkdownEditor name="description" onChange={handleFormChange} value={project.description || ""} required />
                </label>
            </fieldset>

            <fieldset className='mt-2'>
                <label className="text-lg font-medium text-black">Rules of engagement</label>
                <hr className='h-[0.5px] bg-[#3A3D89]' />
                <label>
                    <span className="font-medium text-xs">Vulnerability metrics</span>
                    <select name="vulnerability_metrics" value={project.vulnerability_metrics || ""} onChange={handleFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'>
                        <option value="">(undefined)</option>
                        {ProjectVulnerabilityMetrics.map(type => <option key={`metrics_${type.id}`} value={type.id}>{type.name}</option>)}
                    </select>
                </label>

                {!project.is_template && <>
                    <div className="flex space-x-6">
                        <label>
                            <span className="font-medium text-xs">Start date</span>
                            <input type="date" name="engagement_start_date"
                                className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'
                                value={project.engagement_start_date}
                                onChange={handleFormChange} />
                        </label>
                        <label>
                            <span className="font-medium text-xs">End date</span>
                            <input type="date" name="engagement_end_date"
                                className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'
                                value={project.engagement_end_date}
                                onChange={handleFormChange} />
                        </label>
                    </div>
                </>}
            </fieldset>

            <fieldset className='mt-2'>
                <label className="text-lg font-medium text-black">Vulnerabilities summary</label>
                <hr className='h-[0.5px] bg-[#3A3D89]' />
                <label>
                    <span className="font-medium text-xs">Management summary</span>
                    <MarkdownEditor name="management_summary" onChange={handleFormChange} value={project.management_summary || ""} required />
                </label>

                <label>
                    <span className="font-medium text-xs">Conclusion</span>
                    <MarkdownEditor name="management_conclusion" onChange={handleFormChange} value={project.management_conclusion || ""} required />
                </label>
            </fieldset>

            <div className="fixed bottom-4 right-4">
                <button type='submit' className='bg-[#3A3D89] px-4 py-2 rounded text-white text-sm'>
                    {isEdit ? "Update Project" : "Create Project"}
                </button>
            </div>
        </form>
    )
}

export default ProjectForm;
