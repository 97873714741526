import React from 'react'

const DateManager = ({ date }) => {
    const formattedDate = new Date(date);

    // Get day, month, and time values
    const day = formattedDate.getDate();
    const monthIndex = formattedDate.getMonth();
    const hours = formattedDate.getHours();
    const minutes = formattedDate.getMinutes();

    // Define suffix for day (e.g., "st", "nd", "rd", "th")
    let daySuffix = "th";
    if (day === 1 || day === 21 || day === 31) {
        daySuffix = "st";
    } else if (day === 2 || day === 22) {
        daySuffix = "nd";
    } else if (day === 3 || day === 23) {
        daySuffix = "rd";
    }
    const monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
    ];

    // Format the date
    const formattedDateString = `${day}${daySuffix} ${monthNames[monthIndex]}, ${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

    return (
        <span className={`text-gray-600`}>
            {formattedDateString}
        </span>
    );
};

export default DateManager