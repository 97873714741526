// import ExternalLink from "components/ui/ExternalLink";
import DashboardWidget from "./Widget";

const WelcomeWidget = () => {
    return (
        <DashboardWidget title="">
            <h1 className="text-black font-medium text-lg" >Welcome to Securityium Project Management Portal!</h1>
            <div>
                <p>To configure this dashboard click on config button and select the widgets you want to see every time you log in.</p>
                <p>Each user will have a different set of widgets so pick the ones you find most useful.</p>
            </div>
        </DashboardWidget>
    )
}

export default WelcomeWidget;
