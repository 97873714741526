import { ButtonGroup, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import PageTitle from 'components/logic/PageTitle';
import RestrictedComponent from 'components/logic/RestrictedComponent';
import CommandTerminal from 'components/ui/CommandTerminal';
import EmptyField from 'components/ui/EmptyField';
import ExternalLink from 'components/ui/ExternalLink';
import ShellCommand from 'components/ui/ShellCommand';
import Tags from 'components/ui/Tags';
import TimestampsSection from 'components/ui/TimestampsSection';
import UserLink from 'components/users/Link';
import ReactMarkdown from 'react-markdown';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CommandService, { HostCommandLineGenerator } from 'services/command';
import useDelete from '../../hooks/useDelete';
import useFetch from '../../hooks/useFetch';
import Breadcrumb from "../ui/Breadcrumb";
import { IconBriefcase } from '../ui/Icons';
import Loading from '../ui/Loading';
import Title from '../ui/Title';
import CommandInstructions from './Instructions';
import CommandOutputs from './Outputs';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

const CommandDetails = () => {
    const { commandId } = useParams();
    const navigate = useNavigate();

    const [command] = useFetch(`/commands/${commandId}`)
    const deleteClient = useDelete(`/commands/`)

    const handleDelete = async () => {
        const confirmed = await deleteClient(commandId);
        if (confirmed)
            navigate('/commands');
    }

    if (!command) {
        return <Loading />
    }

    return <div>
        <div className='heading'>
            <Breadcrumb>
                <Link to="/commands">Commands</Link>
            </Breadcrumb>
            <ButtonGroup>
                <RestrictedComponent roles={['administrator', 'superuser', 'user']}>
                    <button onClick={(ev) => {
                        ev.preventDefault();
                        navigate(`/commands/${command.id}/edit`)
                    }} className='bg-[#3A3D89] px-3 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                        Edit
                    </button>

                    <button onClick={handleDelete} className='bg-[#EB1F36] px-3 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                        <DeleteIcon /> Delete
                    </button>

                </RestrictedComponent>
            </ButtonGroup>
        </div>
        <article>
            <div>
                <PageTitle value={`${command.name} command`} />

                <Title type='Command' title={command.name} icon={<IconBriefcase />} />
                <Tags values={command.tags} />
            </div>

            <Tabs variant='enclosed'>
                <TabList>
                    <Tab><span className='text-xs font-medium'>Details</span></Tab>
                    <Tab><span className='text-xs font-medium'>Run instructions</span></Tab>
                    <Tab><span className='text-xs font-medium'>Command outputs</span></Tab>
                    <Tab><span className='text-xs font-medium'>Terminal</span></Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <div className="grid grid-two">
                            <div>
                                <h4 className='text-sm'> <LinkOutlinedIcon className='text-[#3A3D89]' /> Command Details</h4>
                                <dl>
                                    <dt className='text-xs '>Description</dt>
                                    <dd className='text-xs '>{command.description ? <ReactMarkdown>{command.description}</ReactMarkdown> : <EmptyField />}</dd>

                                    {command.output_parser && <>
                                        <dt className='text-xs '>Output parser support</dt>
                                        <dl className='text-xs '>Yes ({command.output_parser})</dl>
                                    </>}
                                    {command.more_info_url && <>
                                        <dt className='text-xs '>More information URL</dt>
                                        <dl className='text-xs '>{command.more_info_url ? <ExternalLink href={command.more_info_url}>{command.more_info_url}</ExternalLink> : <EmptyField />}</dl>
                                    </>}

                                    {CommandService.hasCommand(command) && <>
                                        {CommandService.isHost(command) && <>
                                            <dt className='text-xs '>Command line example</dt>
                                            <dd className='text-xs '>
                                                <ShellCommand>{HostCommandLineGenerator.generateEntryPoint(command)} {HostCommandLineGenerator.renderArguments(command)}</ShellCommand>
                                            </dd>
                                        </>}
                                        <dt className='text-xs '>Command line example using rmap CLI</dt>
                                        <dd className='text-xs '>
                                            <ShellCommand>{CommandService.generateEntryPoint(command)} {CommandService.renderArguments(command)}</ShellCommand>
                                        </dd>
                                    </>}
                                </dl>
                            </div>

                            <div>
                                <h4 className='text-sm'> <InfoOutlinedIcon className='text-[#3A3D89]' /> Relations</h4>
                                <dl>
                                    <dt className='text-xs '>Created by</dt>
                                    <dd className='text-xs '><UserLink userId={command.creator_uid}>{command.creator_full_name}</UserLink></dd>
                                </dl>

                                <TimestampsSection entity={command} />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <CommandInstructions command={command} />
                    </TabPanel>
                    <TabPanel>
                        <CommandOutputs command={command} />
                    </TabPanel>
                    <TabPanel>
                        <CommandTerminal commands={[]} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </article>
    </div >
}

export default CommandDetails;
