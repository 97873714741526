import { FormControl } from "@chakra-ui/react";
import useFetch from "hooks/useFetch";

const VulnerabilityCategoryForm = ({ category, onFormSubmit, categorySetter: setCategory }) => {
    const [categories] = useFetch('/vulnerabilities/categories?parentsOnly=true');

    const onFormInputChange = ev => {
        const target = ev.target;
        const name = target.name;
        const value = target.value === "" ? null : target.value;

        setCategory({ ...category, [name]: value });
    };

    return (
        <form id="vulnerability_category_form" onSubmit={onFormSubmit}>
            <FormControl id="parent_id" isRequired>
                <span className="font-medium text-xs">Parent category</span>
                {categories &&
                    <select name="parent_id" value={category.parent_id} onChange={onFormInputChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'>
                        <option>(none)</option>
                        {categories.filter(category => category.parent_id === null).map(category => <option key={category.id} value={category.id}>{category.name}</option>)}
                    </select>
                }
            </FormControl>
            <FormControl id="name" isRequired>
                <span className="font-medium text-xs">Name</span>
                <input name="name" value={category.name} onChange={onFormInputChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
            </FormControl>
            <FormControl id="description">
                <span className="font-medium text-xs">Description</span>
                <input name="description" value={category.description} onChange={onFormInputChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
            </FormControl>
        </form>
    )
}

export default VulnerabilityCategoryForm;
