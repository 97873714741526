
const TaskModel = {
    project_id: null,
    name: null,
    description: null,
    parser: "",
    command_id: null,
};

export default TaskModel;
